import * as constants from '../constants/Constants';
import configuration from '../configuration';

export function groupBy(array, key) {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export function convertApiObjectFormat(items) {
  if (items.data.return[constants.BcIntertie] !== undefined) {
    var dataLenght = items.data.return[constants.BcIntertie][constants.Allocations].length;
    var bcInertieData = items.data.return[constants.BcIntertie] !== undefined ? items.data.return[constants.BcIntertie][constants.Allocations] : [];
    var matlInertieData = items.data.return[constants.MatlIntertie] !== undefined ? items.data.return[constants.MatlIntertie][constants.Allocations] : [];
    var skInertieData = items.data.return[constants.SkIntertie] !== undefined ? items.data.return[constants.SkIntertie][constants.Allocations] : [];
    var bcmatlFlowgateData = items.data.return[constants.BcMatlFlowgate] !== undefined ? items.data.return[constants.BcMatlFlowgate][constants.Allocations] : [];
    var systemFlowgateData = items.data.return[constants.SystemlFlowgate] !== undefined ? items.data.return[constants.SystemlFlowgate][constants.Allocations] : [];
    var convertedData = [];
    for (var i = 0; i < dataLenght; i++) {
      convertedData.push({
        "hourEnding": bcInertieData[i]["he"],
        "date": bcInertieData[i]["date"],
        "bcIntertie": bcInertieData[i],
        "matlInertie": matlInertieData[i],
        "skInertie": skInertieData[i],
        "bcmatlFlowgate": bcmatlFlowgateData[i],
        "systemFlowgate": systemFlowgateData[i],
        "expanded": false,
        "commentSpanHeight": 0
      });
    }

    return convertedData;
  }
  else {
    return [];
  }
}

export function convertDate(dateStr) {
  // Compare two dates (could be of any type supported by the convert
  // function above) and returns:
  //  -1 : if a < b
  //   0 : if a = b
  //   1 : if a > b
  // NaN : if a or b is an illegal date
  // NOTE: The code inside isFinite does an assignment (=).
  try{
    var parts = dateStr.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    return new Date(parts[0], parts[1] - 1, parts[2]); 
  }catch(Error){
    return null;
  }
}

export function compareDate(a, b) {
  // Compare two dates (could be of any type supported by the convert
  // function above) and returns:
  //  -1 : if a < b
  //   0 : if a = b
  //   1 : if a > b
  // NaN : if a or b is an illegal date
  // NOTE: The code inside isFinite does an assignment (=).
  return (
    isFinite(a = convert(a).valueOf()) &&
      isFinite(b = convert(b).valueOf()) ?
      (a > b) - (a < b) :
      NaN
  );
}

export function convert(d) {
  // Converts the date in d to a date-object. The input can be:
  //   a date object: returned without modification
  //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
  //   a number     : Interpreted as number of milliseconds
  //                  since 1 Jan 1970 (a timestamp) 
  //   a string     : Any format supported by the javascript engine, like
  //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
  //  an object     : Interpreted as an object with year, month and date
  //                  attributes.  **NOTE** month is 0-11.
  return (
    d.constructor === Date ? d :
      d.constructor === Array ? new Date(d[0], d[1], d[2]) :
        d.constructor === Number ? new Date(d) :
          d.constructor === String ? new Date(d) :
            typeof d === "object" ? new Date(d.year, d.month, d.date) :
              NaN
  );
}

export function getDatesDifference(d1, d2) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(d1);
  const secondDate = new Date(d2);

  return Math.round(Math.abs((firstDate - secondDate) / oneDay))
}

export function yearsDiff(d1, d2) {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let yearsDiff = date2.getFullYear() - date1.getFullYear();
  return yearsDiff;
}

export function checkMonth(d1, d2, monthDiff) {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  var months = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth());
  var days = date2.getDate() - date1.getDate();
  if (months >= 13 && days >= 1) {
    return false;
  }
  else {
    return true
  }
}

export function convertDateToApiFormat(date) {
  console.log(date);
  var dt = date.split("-");
  return dt[0] + dt[1] + dt[2];
}

export function convertDateToValidationFormat(date) {
  var dt = new Date(date);
  var valDate = dt.getDate() + 1;
  return dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + ("0" + valDate).slice(-2) + " 00:00:00";
}

export function getGridColumnGroups() {
  return (
    [
      { text: '', align: 'center', name: 'Type' },
      { text: constants.en_messages.generic.common.interties, align: 'center', name: 'Interties' },
      { text: constants.en_messages.generic.common.flowGates, align: 'center', name: 'FlowGate' },
      { text: constants.en_messages.generic.common.bcAbbreviation, align: 'center', name: 'BC', parentgroup: 'Interties' },
      { text: constants.en_messages.generic.common.matlAbbreviation, align: 'center', name: 'MATL', parentgroup: 'Interties' },
      { text: constants.en_messages.generic.common.skAbbreviation, align: 'center', name: 'SK', parentgroup: 'Interties' },
      { text: constants.en_messages.generic.common.bcMatlAbbreviation, align: 'center', name: 'FlowgateBC', parentgroup: 'FlowGate' },
      { text: constants.en_messages.generic.common.systemAbbreviation, align: 'center', name: 'FlowgateSystem', parentgroup: 'FlowGate' },
    ]
  );
}

export function getGridColumns() {
  return (
    [
      { text: '', columngroup: 'Type', datafield: '0', width: "15%", cellsalign: 'right', align: 'center', class: 'grid-column', cellsrenderer },
      { text: constants.en_messages.generic.common.import, columngroup: 'BC', groupable: false, datafield: 'BCImport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.export, columngroup: 'BC', groupable: false, datafield: 'BCExport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.import, columngroup: 'MATL', groupable: false, datafield: 'MatlImport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.export, columngroup: 'MATL', groupable: false, datafield: 'MatlExport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.import, columngroup: 'SK', groupable: false, datafield: 'SKImport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.export, columngroup: 'SK', groupable: false, datafield: 'SKExport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.import, columngroup: 'FlowgateBC', groupable: false, datafield: 'FlowGateBCImport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.export, columngroup: 'FlowgateBC', groupable: false, datafield: 'FlowGateBCExport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.import, columngroup: 'FlowgateSystem', groupable: false, datafield: 'FlowGateSystemImport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.export, columngroup: 'FlowgateSystem', groupable: false, datafield: 'FlowGateSystemExport', width: '6%', cellsalign: 'right', align: 'center', rendered: renderColumnHeader },
      { text: constants.en_messages.generic.common.comments, datafield: 'Id', cellsalign: 'left', align: 'center', rendered: renderParentHeader },
    ]
  );
}

export function getGridDataFeilds() {
  return (
    [
      { name: '0', type: 'string' },
      { name: 'BCImport', type: 'string' },
      { name: 'BCExport', type: 'string' },
      { name: 'MatlImport', type: 'string' },
      { name: 'MatlExport', type: 'string' },
      { name: 'SKImport', type: 'string' },
      { name: 'SKExport', type: 'string' },
      { name: 'FlowGateBCImport', type: 'string' },
      { name: 'FlowGateBCExport', type: 'string' },
      { name: 'FlowGateSystemImport', type: 'string' },
      { name: 'FlowGateSystemExport', type: 'string' },
      { name: 'Id', type: 'string' },
      { name: '1' }
    ]
  );
}

export function getGridDefaultSource() {
  return (
    {
      dataFields: getGridDataFeilds(),
      datatype: 'json',
      localdata: []
    }
  );
}

export function cellsrenderer(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
  if (value === constants.NoDataMsg)
    return '<span class="grid-row-header">' + value + '</span>';
  else
    return '<span class="grid-row-header">Date: ' + value + '</span>';
};

export function renderParentHeader(header) {
  header[0].innerHTML = "<span class='parent-header'>" + header[0].innerText + "</span>";
};

export function renderHeader(header) {
  var innerText = header[0].innerText;
  alert(innerText)
  if (innerText === "BC") {
    header[0].innerHTML = "<span class='grid-header grid-header-bc'>" + innerText + "</span>"
  }
  else if (innerText === "MATL") {
    header[0].innerHTML = "<span class='grid-header grid-header-matl'>" + innerText + "</span>"
  }
  else if (innerText === "SK") {
    header[0].innerHTML = "<span class='grid-header grid-header-sk'>" + innerText + "</span>"
  }
  else if (innerText === "BC/MATL") {
    header[0].innerHTML = "<span class='grid-header grid-header-bc-matl'>" + innerText + "</span>"
  }
  else if (innerText === "SYSTEM") {
    header[0].innerHTML = "<span class='grid-header grid-header-system'>" + innerText + "</span>"
  }

};

export function renderColumnHeader(header) {
  header[0].innerHTML = "<span class='grid-column'>" + header[0].innerText + "</span>";
}

export function validateHourRange(hourRange) {
  var errorMessage = "<ul class='validation-message-section'>";
  var isError = false;
  var hours = hourRange.split("-");
  var startHour = parseInt(hours[0]);
  var endHour = parseInt(hours[1]);
  if (hours[0].indexOf(' ') >= 0 || hours[1].indexOf(' ') >= 0 || hours.length !== 2 || isNaN(startHour) || isNaN(endHour)) {
    isError = true;
    errorMessage += "<li>" + constants.en_messages.err.hourEndingRangeMsg + '</li>';
    return errorMessage;
  }

  if (startHour <= 0 || startHour > 24 || endHour <= 0 || endHour > 24 || endHour < startHour) {
    isError = true;
    errorMessage += "<li>" + constants.en_messages.err.hourEndingRangeMsg + '</li>';
  }

  errorMessage += "</ul>"
  return isError ? errorMessage : '';
}

export function convertToCSVData(data) {
  var convertedData = [];
  data.map((item, index) => {
    convertedData.push({
      "Date":item.date,
      "Hour Ending": item.hourEnding,
      //BC
      "BC Import Gross Offers": item[constants.bcIntertie][constants.Import_Data][constants.GrossOffer],
      "BC Import TTC": item[constants.bcIntertie][constants.Import_Data][constants.TTC],
      "BC Import TRM System": item[constants.bcIntertie][constants.Import_Data][constants.TRM_System],
      "BC Import TRM Allocation": item[constants.bcIntertie][constants.Import_Data][constants.TRM_Allocation],
      "BC Import TRM": item[constants.bcIntertie][constants.Import_Data][constants.TRM_Total],
      "BC Import Capability": item[constants.bcIntertie][constants.Import_Data][constants.ATC],
      "BC Import Reason": item[constants.bcIntertie][constants.Import_Data][constants.Comments],
      "BC Export Gross Offers": item[constants.bcIntertie][constants.Export_Data][constants.GrossOffer],
      "BC Export TTC": item[constants.bcIntertie][constants.Export_Data][constants.TTC],
      "BC Export TRM System": item[constants.bcIntertie][constants.Export_Data][constants.TRM_System],
      "BC Export TRM Allocation": item[constants.bcIntertie][constants.Export_Data][constants.TRM_Allocation],
      "BC Export TRM": item[constants.bcIntertie][constants.Export_Data][constants.TRM_Total],
      "BC Export Capability": item[constants.bcIntertie][constants.Export_Data][constants.ATC],
      "BC Export Reason": item[constants.bcIntertie][constants.Export_Data][constants.Comments],

      //MATL
      "MATL Import Gross Offers": item[constants.matlIntertie][constants.Import_Data][constants.GrossOffer],
      "MATL Import TTC": item[constants.matlIntertie][constants.Import_Data][constants.TTC],
      "MATL Import TRM System": item[constants.matlIntertie][constants.Import_Data][constants.TRM_System],
      "MATL Import TRM Allocation": item[constants.matlIntertie][constants.Import_Data][constants.TRM_Allocation],
      "MATL Import TRM": item[constants.matlIntertie][constants.Import_Data][constants.TRM_Total],
      "MATL Import Capability": item[constants.matlIntertie][constants.Import_Data][constants.ATC],
      "MATL Import Reason": item[constants.matlIntertie][constants.Import_Data][constants.Comments],
      "MATL Export Gross Offers": item[constants.matlIntertie][constants.Export_Data][constants.GrossOffer],
      "MATL Export TTC": item[constants.matlIntertie][constants.Export_Data][constants.TTC],
      "MATL Export TRM System": item[constants.matlIntertie][constants.Export_Data][constants.TRM_System],
      "MATL Export TRM Allocation": item[constants.matlIntertie][constants.Export_Data][constants.TRM_Allocation],
      "MATL Export TRM": item[constants.matlIntertie][constants.Export_Data][constants.TRM_Total],
      "MATL Export Capability": item[constants.matlIntertie][constants.Export_Data][constants.ATC],
      "MATL Export Reason": item[constants.matlIntertie][constants.Export_Data][constants.Comments],

      //SK
      "SK Import Gross Offers": item[constants.skIntertie][constants.Import_Data][constants.GrossOffer],
      "SK Import TTC": item[constants.skIntertie][constants.Import_Data][constants.TTC],
      "SK Import TRM System": item[constants.skIntertie][constants.Import_Data][constants.TRM_System],
      "SK Import TRM Allocation": item[constants.skIntertie][constants.Import_Data][constants.TRM_Allocation],
      "SK Import TRM": item[constants.skIntertie][constants.Import_Data][constants.TRM_Total],
      "SK Import Capability": item[constants.skIntertie][constants.Import_Data][constants.ATC],
      "SK Import Reason": item[constants.skIntertie][constants.Import_Data][constants.Comments],
      "SK Export Gross Offers": item[constants.skIntertie][constants.Export_Data][constants.GrossOffer],
      "SK Export TTC": item[constants.skIntertie][constants.Export_Data][constants.TTC],
      "SK Export TRM System": item[constants.skIntertie][constants.Export_Data][constants.TRM_System],
      "SK Export TRM Allocation": item[constants.skIntertie][constants.Export_Data][constants.TRM_Allocation],
      "SK Export TRM": item[constants.skIntertie][constants.Export_Data][constants.TRM_Total],
      "SK Export Capability": item[constants.skIntertie][constants.Export_Data][constants.ATC],
      "SK Export Reason": item[constants.skIntertie][constants.Export_Data][constants.Comments],

      // BC/MATL
      "BC/MATL Import Gross Offers": item[constants.bcmatlFlowgate][constants.Import_Data][constants.GrossOffer],
      "BC/MATL Import SOL": item[constants.bcmatlFlowgate][constants.Import_Data][constants.TTC],
      "BC/MATL Import TRM System": item[constants.bcmatlFlowgate][constants.Import_Data][constants.TRM_System],
      "BC/MATL Import TRM Allocation": item[constants.bcmatlFlowgate][constants.Import_Data][constants.TRM_Allocation] !== undefined ? item[constants.bcmatlFlowgate][constants.Import_Data][constants.TRM_Allocation] : '',
      "BC/MATL Import TRM": item[constants.bcmatlFlowgate][constants.Import_Data][constants.TRM_Total],
      "BC/MATL Import Capability": item[constants.bcmatlFlowgate][constants.Import_Data][constants.ATC],
      "BC/MATL Import Reason": item[constants.bcmatlFlowgate][constants.Import_Data][constants.Comments],
      "BC/MATL Export Gross Offers": item[constants.bcmatlFlowgate][constants.Export_Data][constants.GrossOffer],
      "BC/MATL Export SOL": item[constants.bcmatlFlowgate][constants.Export_Data][constants.TTC],
      "BC/MATL Export TRM System": item[constants.bcmatlFlowgate][constants.Export_Data][constants.TRM_System],
      "BC/MATL Export TRM Allocation": item[constants.bcmatlFlowgate][constants.Export_Data][constants.TRM_Allocation] !== undefined ? item[constants.bcmatlFlowgate][constants.Export_Data][constants.TRM_Allocation] : '',
      "BC/MATL Export TRM": item[constants.bcmatlFlowgate][constants.Export_Data][constants.TRM_Total],
      "BC/MATL Export Capability": item[constants.bcmatlFlowgate][constants.Export_Data][constants.ATC],
      "BC/MATL Export Reason": item[constants.bcmatlFlowgate][constants.Export_Data][constants.Comments],

      //System
      "SYSTEM Import Gross Offers": item[constants.systemFlowgate][constants.Import_Data][constants.GrossOffer],
      "SYSTEM Import SOL": item[constants.systemFlowgate][constants.Import_Data][constants.TTC],
      "SYSTEM Import TRM System": item[constants.systemFlowgate][constants.Import_Data][constants.TRM_System],
      "SYSTEM Import TRM Allocation": item[constants.systemFlowgate][constants.Import_Data][constants.TRM_Allocation] !== undefined ? item[constants.systemFlowgate][constants.Import_Data][constants.TRM_Allocation] : '',
      "SYSTEM Import TRM": item[constants.systemFlowgate][constants.Import_Data][constants.TRM_Total],
      "SYSTEM Import Capability": item[constants.systemFlowgate][constants.Import_Data][constants.ATC],
      "SYSTEM Import Reason": item[constants.systemFlowgate][constants.Import_Data][constants.Comments],
      "SYSTEM Export Gross Offers": item[constants.systemFlowgate][constants.Export_Data][constants.GrossOffer],
      "SYSTEM Export SOL": item[constants.systemFlowgate][constants.Export_Data][constants.TTC],
      "SYSTEM Export TRM System": item[constants.systemFlowgate][constants.Export_Data][constants.TRM_System],
      "SYSTEM Export TRM Allocation": item[constants.systemFlowgate][constants.Export_Data][constants.TRM_Allocation] !== undefined ? item[constants.systemFlowgate][constants.Export_Data][constants.TRM_Allocation] : '',
      "SYSTEM Export TRM": item[constants.systemFlowgate][constants.Export_Data][constants.TRM_Total],
      "SYSTEM Export Capability": item[constants.systemFlowgate][constants.Export_Data][constants.ATC],
      "SYSTEM Export Reason": item[constants.systemFlowgate][constants.Export_Data][constants.Comments],

    });
    return convertedData;
  })
  return convertedData;
}

export function convertToCSVDataForecastReport(data) {
  var convertedData = [];
  data.map((item, index) => {
    convertedData.push({
      "Date": item.date,
      "Hour Ending": item.hourEnding,
      //BC
      "BC Import": item[constants.bcIntertie][constants.Import_Data][constants.ATC],
      "BC Import Reason": item[constants.bcIntertie][constants.Import_Data][constants.Comments],
      "BC Export": item[constants.bcIntertie][constants.Export_Data][constants.ATC],
      "BC Export Reason": item[constants.bcIntertie][constants.Export_Data][constants.Comments],

      //MATL
      "MATL Import": item[constants.matlIntertie][constants.Import_Data][constants.ATC],
      "MATL Import Reason": item[constants.matlIntertie][constants.Import_Data][constants.Comments],
      "MATL Export": item[constants.matlIntertie][constants.Export_Data][constants.ATC],
      "MATL Export Reason": item[constants.matlIntertie][constants.Export_Data][constants.Comments],

      //SK
      "SK Import": item[constants.skIntertie][constants.Import_Data][constants.ATC],
      "SK Import Reason": item[constants.skIntertie][constants.Import_Data][constants.Comments],
      "SK Export": item[constants.skIntertie][constants.Export_Data][constants.ATC],
      "SK Export Reason": item[constants.skIntertie][constants.Export_Data][constants.Comments],

      // BC/MATL
      "BC/MATL Import Capability": item[constants.bcmatlFlowgate][constants.Import_Data][constants.ATC],
      "BC/MATL Import Reason": item[constants.bcmatlFlowgate][constants.Import_Data][constants.Comments],
      "BC/MATL Export Capability": item[constants.bcmatlFlowgate][constants.Export_Data][constants.ATC],
      "BC/MATL Export Reason": item[constants.bcmatlFlowgate][constants.Export_Data][constants.Comments],

      //System
      "SYSTEM Import": item[constants.systemFlowgate][constants.Import_Data][constants.ATC],
      "SYSTEM Import Reason": item[constants.systemFlowgate][constants.Import_Data][constants.Comments],
      "SYSTEM Export": item[constants.systemFlowgate][constants.Export_Data][constants.ATC],
      "SYSTEM Export Reason": item[constants.systemFlowgate][constants.Export_Data][constants.Comments],

    });
    return convertedData;
  })
  return convertedData;
}

export function convertOutageData(data) {
  var affectedLines = new Set();
  var affectedSKRow = [];
  var affectedMatlRow = [];
  var affectedBCRow = [];
  var affectedBCMatlRow = [];
  data.affectedLines = affectedLines;
  data.map((item, index) => {
    item.affectedIntertieDisplayValue = " ";
    item.affectedIntertieOrFlowgate.map((affectedIntertieItem, affectedIntertieIndex) => {
      item.affectedIntertieDisplayValue += affectedIntertieItem.displayValue + ", ";
      affectedIntertieItem.affectedLines.map((affectedLineItem, affectedLineIndex) => {
        data.affectedLines.add(affectedLineItem);
        switch (affectedLineItem) {
          case 'SK':
            affectedSKRow.push(index);
            break;
          case 'MATL':
            affectedMatlRow.push(index);
            break;
          case 'BC':
            affectedBCRow.push(index);
            break;
          case 'BC_MATL':
            affectedBCMatlRow.push(index);
            break;
          default:
            break;
        }
      })
    })
    item.affectedIntertieDisplayValue = item.affectedIntertieDisplayValue.replace(/,\s*$/, "");
  })
  
  data.affectedSKRow = affectedSKRow;
  data.affectedMatlRow = affectedMatlRow;
  data.affectedBCRow = affectedBCRow;
  data.affectedBCMatlRow = affectedBCMatlRow;
  return data;
}

export function createCSVRows(data, title) {
  const csvRows = [];

  // Create Title

  csvRows.push(title);

  // create empty row
  csvRows.push('');

  // get the headers
  const headers = Object.keys(data[0]);
  csvRows.push("\""+headers.join('","')+"\"");

  //loop over the rows
  for (const row of data) {
    const values = headers.map(header => {
      var rowVal=row[header];
      const escaped = ('' + rowVal).replace(/"/g, '\\"');
      var returnVal = '';
      if(typeof(rowVal)=='number'){
        returnVal = `${escaped}`;
      }else{
        returnVal = `"${escaped}"`;
      }
      return returnVal;
    })

    csvRows.push(values.join(","));
  }

  return csvRows;
}

export function downloadCSV(data, fileName) {
  const blob = new Blob([data], { type: 'text/csv' });

  if (window.navigator && window.navigator.msSaveBlob) {
    // Internet Explorer workaround
    window.navigator.msSaveBlob(blob, fileName);
  }
  else {
    // other browsers
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function getCalendarMinDate() {
  var d = new Date();
  d.setDate(d.getDate() - 1);
  return new Date(d);
}

/**
   * Method that checks whether cls is present in element object.
   * @param  {Object} ele DOM element which needs to be checked
   * @param  {Object} cls Classname is tested
   * @return {Boolean} True if cls is present, false otherwise.
   */
  export function hasClass(ele, cls) {
    return ele.getAttribute('class').indexOf(cls) > -1;
  }

  /**
   * Method that adds a class to given element.
   * @param  {Object} ele DOM element where class needs to be added
   * @param  {Object} cls Classname which is to be added
   * @return {null} nothing is returned.
   */
  export function addClass(ele, cls) {
      if (ele.classList) {
          ele.classList.add(cls);
      } else if (!hasClass(ele, cls)) {
          ele.setAttribute('class', ele.getAttribute('class') + ' ' + cls);
      }
  }

  /**
   * Method that does a check to ensure that class is removed from element.
   * @param  {Object} ele DOM element where class needs to be removed
   * @param  {Object} cls Classname which is to be removed
   * @return {null} Null nothing is returned.
   */
  export function removeClass(ele, cls) {
      if (ele.classList) {
          ele.classList.remove(cls);
      } else if (hasClass(ele, cls)) {
          ele.setAttribute('class', ele.getAttribute('class').replace(cls, ' '));
      }
  }

  export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export function handleItcApiError(e){
    var errorMessage = "<ul class='validation-message-section'>";
    // errorMessage += "<li>" + e.message + '</li>';
    if(e.metadata)
    {
        var message=e.message;
        if (typeof e.metadata === 'string')
            errorMessage += "<li>" + e.metadata + '</li>';
        else if(Array.isArray(e.metadata))                    {
            const errorObject = e.metadata
            errorObject.forEach(element => {
                errorMessage += "<li>" + element + '</li>';
            });
        } else {
            message="Something unexpected happened. Please report this issue to AESO support team.";
            errorMessage += "<li>" + message + '</li>';
        }
    } else {
        message="Something unexpected happened. Please report this issue to AESO support team.";
        errorMessage += "<li>" + message + '</li>';
    }
    return errorMessage;
}

export function updateEnvWatermarkAndVersion(env){
  try {
    if('dev'===env){
      addClass(document.body,'dev-background-watermark');
      removeClass(document.body,'test-background-watermark');
      removeClass(document.body,'integ-background-watermark');
    } else if('uat'===env){
      removeClass(document.body,'dev-background-watermark');
      addClass(document.body,'test-background-watermark');
      removeClass(document.body,'integ-background-watermark');
    } else if('integ'===env){
        removeClass(document.body,'dev-background-watermark');
        removeClass(document.body,'test-background-watermark');
        addClass(document.body,'integ-background-watermark');
    } else {
      removeClass(document.body,'dev-background-watermark');
      removeClass(document.body,'test-background-watermark');
      removeClass(document.body,'integ-background-watermark');
    }
  } catch (error) {
    console.log(error);
  }
}
