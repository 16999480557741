import React from 'react';
import * as constants from '../../constants/Constants';
import PlannedOutage from '../../components/realtime/PlannedOutage';
import parse from 'html-react-parser';
import LineChart from '../../components/realtime/LineChart';
import Card from '../../components/common/card/Card';
import DetailViewGrid from '../../components/realtime/DetailViewGrid';
import JqxCheckBox from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxcheckbox';
import { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxlistbox';
import RealTimeReportService from '../../apis/RealTimeReportService';
import { groupBy, convertApiObjectFormat, getGridColumnGroups, getGridColumns, getGridDataFeilds, getGridDefaultSource,convertToCSVData,downloadCSV,createCSVRows,updateEnvWatermarkAndVersion,handleItcApiError  } from '../../utilities/HelperMethods';
//import '../../assets/scss/components/app.scss';
import SectionTitle from '../../components/common/title/SectionTitle';
import '../../assets/scss/components/Trends.scss';

import configuration from '../../configuration';
import Grid from '../../components/common/grid/Grid';
import AESOLoader from '../../components/common/loader/Loader';
import moment from 'moment';
import Time from 'react-time-format'

class RealTimeReport extends React.Component {
    constructor(props) {
        super(props);
        this.childRef = React.createRef();
        this.lineChartChildRef = React.createRef();
        this.lineChart = React.createRef();
        this.chkBoxATC = React.createRef();
        this.chkBoxGO = React.createRef();
        this.chkBoxTTC = React.createRef();
        this.chkBoxBC = React.createRef();
        this.chkBoxMATL = React.createRef();
        this.chkBoxSK = React.createRef();
        this.chkBoxBCMATL = React.createRef();
        this.chkBoxSystem = React.createRef();
        this.plannedOutagesRef = React.createRef();
        this.importTab = React.createRef();
        this.exportTab = React.createRef();
        this.gridPannel = React.createRef();
        this.realTimePannel = React.createRef();
        this.graphViewBtn = React.createRef();
        this.refreshBtn = React.createRef();
        this.gridViewBtn = React.createRef();
        this.realTimeReportService = new RealTimeReportService();
        this.collpaseButton=React.createRef();
        this.expandButton=React.createRef();
        this.gridViewMsg = React.createRef();
        this.graphicalViewMsg = React.createRef();
        this.childgridRealTime = React.createRef();
        this.errMsg = React.createRef();

        this.state = {
            gridVisible: this.props.grid,
            columngroups: getGridColumnGroups(),
            columns: getGridColumns(),
            gridSource: getGridDefaultSource(),
            lineChartsource: {},
            seriesGroups: this.getRealTimeChartImportSeriesGroup(),
            valueAxis: {
                title: { text: constants.en_messages.generic.realtime.section2.y_value },
                visible: true,
                showGridLines: false,
                // gridLines: {
                //     dashStyle: '0,0',
                //     showGridLines: false,
                // },
                minValue: 0,
                unitInterval: 100,
                formatFunction: (value) => {
                    if(value < 0)
                        return " ";
                    else 
                        return value;
                },
            },
            xAxis: {
				unitInterval: 1,
                dataField: 'hourEnding',
                padding: { top: 5 },
                type: 'basic',
                flip: false,
                title: { text: constants.en_messages.generic.realtime.section2.x_value },
                showGridLines: false,
                // gridLines: {
                //     dashStyle: '0,0',
                //     showGridLines: false,
                // },
                rangeSelector: {
                    backgroundColor: 'white',
                    gridLines: { visible: false },
                    padding: { top: 0, bottom: 0 },
                    serieType: 'line',
                    size: 30,
                    // minValue:7,
                    formatFunction: (value) => {
                        return " ";
                    },
                },
                maxValue: 11,
                minValue: 0,

                valuesOnTicks: true
            },
            realTimeReportData: [],
            selectedHour: '0',
            selectedDate: '',
            detailgridColumns: [
                { text: '', datafield: 'category', width: 120 },
                { text: 'BC', datafield: 'BC', width: 70 },
                { text: 'MATL', datafield: 'MATL', width: 70 },
                { text: 'SK', datafield: 'SK', width: 70 },
            ],
            detailgridSource: {},
            currentHourEndingData: {},
            chkBoxATCVal: true,
            chkBoxGOVal: true,
            chkBoxTTCVal: true,
            chkBoxBCVal: false,
            chkBoxMATLVal: false,
            chkBoxSKVal: false,
            chkBoxBCMATLVal: true,
            chkBoxSystemVal: false,
            collpaseDetailGrid: false,
            chartImportExportType: 'Import',
            isLineChartUpdate: false,
            gridHeight: 200,
            lastRefreshTime:null,
            dataLoaded:false,
            csvData : [],
            errorMesage: ""}
        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel(e) {
        if (this.state.collpaseDetailGrid) {
            this.setState({ collpaseDetailGrid: !this.state.collpaseDetailGrid });
            document.querySelector('#icon-showhide').classList.add('up');
            document.querySelector('#icon-showhide').classList.add('mt-2');
            document.querySelector('#icon-showhide').classList.remove('down');
        }
        else {
            document.querySelector('.section.collapsible').classList.toggle('collapsed');
            setTimeout(() => {
                this.setState({ collpaseDetailGrid: !this.state.collpaseDetailGrid })
                document.querySelector('#icon-showhide').classList.add('down');
                document.querySelector('#icon-showhide').classList.remove('up');
                document.querySelector('#icon-showhide').classList.remove('mt-2');
            }, 300)
        }
    }

    onShow = (e) => {
       this.setGridData();
    }

    componentDidMount() {
        const data = getGridDefaultSource();
        this.setState({ gridSource: data, gridHeight: 200 });
        this.getData();
        window.addEventListener('resize', this.handleResizeEvent);
    }

   

    async getData() {
        AESOLoader.singletonRef.show();
        this.setState({errorMesage: ""});
        this.refreshBtn.current.classList.add("selected");
        this.realTimeReportService.retrieveData()
        .then(items => {
            if(items.headers!==null && items.headers!==undefined){
                updateEnvWatermarkAndVersion(items.headers['itc-profile']);
            }
            this.setState({
                lastRefreshTime:Date.now()
            },()=>{
                this.transformLineData(items);
            });
            if(this.state.gridVisible){
                this.setState({
                    gridVisible:false
                });
                this.switchView("grid");
            }
            this.setGridData();
            AESOLoader.singletonRef.hide();
            this.refreshBtn.current.classList.remove("selected");
        })
        .catch(e => {
            AESOLoader.singletonRef.hide();
            this.setState({errorMesage: handleItcApiError(e)});
            this.refreshBtn.current.classList.remove("selected");
            console.log(e);
            this.setState({
                lastRefreshTime:Date.now()
            });
        });
    }

    transformLineData(items) {
        var converteddata = convertApiObjectFormat(items);
        const data = {
            datafields: this.getLineChartDataFeilds(),
            datatype: 'json',
            localdata: converteddata
        }
        var currentItem = this.getCurrentHourDetail(converteddata);
       // console.log("parent called- "+new Date())
        this.setState({
            lineChartsource:  {chartData:converteddata,
                               sourceObj:new jqx.dataAdapter(data)},
            realTimeReportData: converteddata,
            detailgridSource: currentItem,
            currentHourEndingData: currentItem,
			csvData:converteddata
        },()=>
        {
         // console.log("parent update- "+new Date())
          this.hideShowIntertieFlowgateChartLines("BC");
          this.hideShowIntertieFlowgateChartLines("MATL");
          this.hideShowIntertieFlowgateChartLines("SK");
          this.hideShowIntertieFlowgateChartLines("SYSTEM");
        }
        );
    }

    getLineChartDataFeilds() {
        return [
            { name: 'hourEnding' },
            { name: 'value' },
            //Import - ATC
            { name: 'bcImportATC', map: 'bcIntertie>' + constants.Import_Data + '>' + constants.ATC },
            { name: 'matlImportATC', map: 'matlInertie>' + constants.Import_Data + '>' + constants.ATC },
            { name: 'skImportATC', map: 'skInertie>' + constants.Import_Data + '>' + constants.ATC },
            { name: 'bcmatlFlowgateImportATC', map: 'bcmatlFlowgate>' + constants.Import_Data + '>' + constants.ATC },
            { name: 'systemFlowgateImportATC', map: 'systemFlowgate>' + constants.Import_Data + '>' + constants.ATC },
            //Import - GO
            { name: 'bcImportGO', map: 'bcIntertie>' + constants.Import_Data + '>' + constants.GrossOffer },
            { name: 'matlImportGO', map: 'matlInertie>' + constants.Import_Data + '>' + constants.GrossOffer },
            { name: 'skImportGO', map: 'skInertie>' + constants.Import_Data + '>' + constants.GrossOffer },
            { name: 'bcmatlFlowgateImportGO', map: 'bcmatlFlowgate>' + constants.Import_Data + '>' + constants.GrossOffer },
            { name: 'systemFlowgateImportGO', map: 'systemFlowgate>' + constants.Import_Data + '>' + constants.GrossOffer },
            //Import - TTC
            { name: 'bcImportTTC', map: 'bcIntertie>' + constants.Import_Data + '>' + constants.TTC },
            { name: 'matlImportTTC', map: 'matlInertie>' + constants.Import_Data + '>' + constants.TTC },
            { name: 'skImportTTC', map: 'skInertie>' + constants.Import_Data + '>' + constants.TTC },
            { name: 'bcmatlFlowgateImportTTC', map: 'bcmatlFlowgate>' + constants.Import_Data + '>' + constants.TTC },
            { name: 'systemFlowgateImportTTC', map: 'systemFlowgate>' + constants.Import_Data + '>' + constants.TTC },
            //Export - ATC
            { name: 'bcExportATC', map: 'bcIntertie>' + constants.Export_Data + '>' + constants.ATC },
            { name: 'matlExportATC', map: 'matlInertie>' + constants.Export_Data + '>' + constants.ATC },
            { name: 'skExportATC', map: 'skInertie>' + constants.Export_Data + '>' + constants.ATC },
            { name: 'bcmatlFlowgateExportATC', map: 'bcmatlFlowgate>' + constants.Export_Data + '>' + constants.ATC },
            { name: 'systemFlowgateExportATC', map: 'systemFlowgate>' + constants.Export_Data + '>' + constants.ATC },
            //Export - GO
            { name: 'bcExportGO', map: 'bcIntertie>' + constants.Export_Data + '>' + constants.GrossOffer },
            { name: 'matlExportGO', map: 'matlInertie>' + constants.Export_Data + '>' + constants.GrossOffer },
            { name: 'skExportGO', map: 'skInertie>' + constants.Export_Data + '>' + constants.GrossOffer },
            { name: 'bcmatlFlowgateExportGO', map: 'bcmatlFlowgate>' + constants.Export_Data + '>' + constants.GrossOffer },
            { name: 'systemFlowgateExportGO', map: 'systemFlowgate>' + constants.Export_Data + '>' + constants.GrossOffer },
            //Export - TTC
            { name: 'bcExportTTC', map: 'bcIntertie>' + constants.Export_Data + '>' + constants.TTC },
            { name: 'matlExportTTC', map: 'matlInertie>' + constants.Export_Data + '>' + constants.TTC },
            { name: 'skExportTTC', map: 'skInertie>' + constants.Export_Data + '>' + constants.TTC },
            { name: 'bcmatlFlowgateExportTTC', map: 'bcmatlFlowgate>' + constants.Export_Data + '>' + constants.TTC },
            { name: 'systemFlowgateExportTTC', map: 'systemFlowgate>' + constants.Export_Data + '>' + constants.TTC },
        ];
    }

    getCurrentHourDetail = (items) => {
        var today = new Date();
        var todayDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);
        var hour = today.getHours() + 1;
        var currentItem = items.find(item => item.date === todayDate && parseInt(item.hourEnding) === hour);
        return currentItem;
    }
    handleEvent(e) {

    }

    getRealTimeChartImportSeriesGroup = () => {
        return (
            [
                {
                    series: [{ name: "bc", dataField: 'bcImportATC', displayText: 'BC Import ATC', color: 'bcline' },
                    { dataField: 'bcImportGO', displayText: 'BC Import GO', dashStyle: '2,2', color: 'bcline' },
                    { dataField: 'bcImportTTC', displayText: 'BC Import TTC', dashStyle: '10,10', color: 'bcline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'matlImportATC', displayText: '', color: 'matlline' },
                    { dataField: 'matlImportGO', displayText: '', dashStyle: '2,2', color: 'matlline' },
                    { dataField: 'matlImportTTC', displayText: '', dashStyle: '10,10', color: 'matlline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'skImportATC', displayText: '', color: 'skline' },
                    { dataField: 'skImportGO', displayText: '', dashStyle: '2,2', color: 'skline' },
                    { dataField: 'skImportTTC', displayText: '', dashStyle: '10,10', color: 'skline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'bcmatlFlowgateImportATC', displayText: '', color: 'bcmatlline' },
                    { dataField: 'bcmatlFlowgateImportGO', displayText: '', dashStyle: '2,2', color: 'bcmatlline' },
                    { dataField: 'bcmatlFlowgateImportTTC', displayText: '', dashStyle: '10,10', color: 'bcmatlline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'systemFlowgateImportATC', displayText: '', color: 'systemline' },
                    { dataField: 'systemFlowgateImportGO', displayText: '', dashStyle: '2,2', color: 'systemline' },
                    { dataField: 'systemFlowgateImportTTC', displayText: '', dashStyle: '10,10', color: 'systemline' }
                    ],
                    type: 'line'
                }
            ]
        );
    }

    getRealTimeChartExportSeriesGroup = () => {
        return (
            [
                {
                    series: [{ dataField: 'bcExportATC', displayText: '', color: 'bcline' },
                    { dataField: 'bcExportGO', displayText: '', dashStyle: '2,2', color: 'bcline' },
                    { dataField: 'bcExportTTC', displayText: '', dashStyle: '10,10', color: 'bcline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'matlExportATC', displayText: '', color: 'matlline' },
                    { dataField: 'matlExportGO', displayText: '', dashStyle: '2,2', color: 'matlline' },
                    { dataField: 'matlExportTTC', displayText: '', dashStyle: '10,10', color: 'matlline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'skExportATC', displayText: '', color: 'skline' },
                    { dataField: 'skExportGO', displayText: '', dashStyle: '2,2', color: 'skline' },
                    { dataField: 'skExportTTC', displayText: '', dashStyle: '10,10', color: 'skline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'bcmatlFlowgateExportATC', displayText: '', color: 'bcmatlline' },
                    { dataField: 'bcmatlFlowgateExportGO', displayText: '', dashStyle: '2,2', color: 'bcmatlline' },
                    { dataField: 'bcmatlFlowgateExportTTC', displayText: '', dashStyle: '10,10', color: 'bcmatlline' }
                    ],
                    type: 'line'
                },
                {
                    series: [{ dataField: 'systemFlowgateExportATC', displayText: '', color: 'systemline' },
                    { dataField: 'systemFlowgateExportGO', displayText: '', dashStyle: '2,2', color: 'systemline' },
                    { dataField: 'systemFlowgateExportTTC', displayText: '', dashStyle: '10,10', color: 'systemline' }
                    ],
                    type: 'line'
                }
            ]
        );
    }

    lineCallback = (item) => {
        this.setState({
            detailgridSource: item,
        });
    }

    hideShowIntertieFlowgateChartLines=(selectedVal)=>
    {
        if(selectedVal==="BC")
        {
            this.setState({chkBoxBCVal: this.chkBoxBC.current.checked},
               ()=>{
                   this.lineChartChildRef.current.showHideBCGraphLines(this.state.chkBoxATCVal,
                   this.state.chkBoxGOVal,this.state.chkBoxTTCVal,this.state.chkBoxBCVal)
               });
        }
        else if(selectedVal==="MATL")
        {
            this.setState({chkBoxMATLVal: this.chkBoxMATL.current.checked},
               ()=>{
                   this.lineChartChildRef.current.showHideMATLGraphLines(this.state.chkBoxATCVal,
                   this.state.chkBoxGOVal,this.state.chkBoxTTCVal,this.state.chkBoxMATLVal)
               });
        }
        else if(selectedVal==="SK")
        {
             this.setState({chkBoxSKVal: this.chkBoxSK.current.checked},
               ()=>{
                   this.lineChartChildRef.current.showHideSKGraphLines(this.state.chkBoxATCVal,
                   this.state.chkBoxGOVal,this.state.chkBoxTTCVal,this.state.chkBoxSKVal)
               });
        }
        else if(selectedVal==="BCMATL")
        {
             this.setState({chkBoxBCMATLVal: this.chkBoxBCMATL.current.checked},
               ()=>{
                   this.lineChartChildRef.current.showHideBCMATLGraphLines(this.state.chkBoxATCVal,
                   this.state.chkBoxGOVal,this.state.chkBoxTTCVal,this.state.chkBoxBCMATLVal)
               });
        }
        else if(selectedVal==="SYSTEM")
        {
             this.setState({chkBoxSystemVal: this.chkBoxSystem.current.checked},
               ()=>{
                   this.lineChartChildRef.current.showHideSystemGraphLines(this.state.chkBoxATCVal,
                   this.state.chkBoxGOVal,this.state.chkBoxTTCVal,this.state.chkBoxSystemVal)
               });
        }
        
    }

    hideShowGrossOfferLines()
    {
        this.setState({chkBoxGOVal: this.chkBoxGO.current.val()},
               ()=>{
                   this.lineChartChildRef.current.showHideGrossOfferLines(this.state.chkBoxGOVal,
                   this.state.chkBoxBCVal,this.state.chkBoxMATLVal,
                   this.state.chkBoxSKVal,this.state.chkBoxBCMATLVal,this.state.chkBoxSystemVal)
               });
    }

    hideShowATCLines()
    {
        this.setState({chkBoxATCVal: this.chkBoxATC.current.val()},
               ()=>{
                   this.lineChartChildRef.current.showHideATCLines(this.state.chkBoxATCVal,
                   this.state.chkBoxBCVal,this.state.chkBoxMATLVal,
                   this.state.chkBoxSKVal,this.state.chkBoxBCMATLVal,this.state.chkBoxSystemVal)
               });
    }

    hideShowTTCLines()
    {
        this.setState({chkBoxTTCVal: this.chkBoxTTC.current.val()},
               ()=>{
                   this.lineChartChildRef.current.showHideTTCLines(this.state.chkBoxTTCVal,
                   this.state.chkBoxBCVal,this.state.chkBoxMATLVal,
                   this.state.chkBoxSKVal,this.state.chkBoxBCMATLVal,this.state.chkBoxSystemVal)
               });
    }


    hideShowGraphLines = (event) => {
        this.setState({
            chkBoxATCVal: this.chkBoxATC.current.val(),
            chkBoxGOVal: this.chkBoxGO.current.val(),
            chkBoxTTCVal: this.chkBoxTTC.current.val(),
            chkBoxBCVal: this.chkBoxBC.current.checked,
            chkBoxMATLVal: this.chkBoxMATL.current.checked,
            chkBoxSKVal: this.chkBoxSK.current.checked,
            chkBoxBCMATLVal: this.chkBoxBCMATL.current.checked,
            chkBoxSystemVal: this.chkBoxSystem.current.checked,

        }, () => {
            this.lineChartChildRef.current.hideShowGraphLines(this.state.chkBoxATCVal,
                this.state.chkBoxGOVal, this.state.chkBoxTTCVal,
                this.state.chkBoxBCVal, this.state.chkBoxMATLVal,
                this.state.chkBoxSKVal, this.state.chkBoxBCMATLVal, this.state.chkBoxSystemVal);
                
                if(this.chkBoxBC.current.checked)
                    this.chkBoxBC.current.parentElement.classList.add('selected');
                else
                    this.chkBoxBC.current.parentElement.classList.remove('selected');

                if(this.chkBoxMATL.current.checked)
                    this.chkBoxMATL.current.parentElement.classList.add('selected');
                else
                    this.chkBoxMATL.current.parentElement.classList.remove('selected');

                if(this.chkBoxSK.current.checked)
                    this.chkBoxSK.current.parentElement.classList.add('selected');
                else
                    this.chkBoxSK.current.parentElement.classList.remove('selected');

                if(this.chkBoxBCMATL.current.checked)
                    this.chkBoxBCMATL.current.parentElement.classList.add('selected');
                else
                    this.chkBoxBCMATL.current.parentElement.classList.remove('selected');

                if(this.chkBoxSystem.current.checked)
                    this.chkBoxSystem.current.parentElement.classList.add('selected');
                else
                    this.chkBoxSystem.current.parentElement.classList.remove('selected');

        });
    }

    ShowImportExportData = (e, type) => {
        if (type === "Export") {
            this.exportTab.current.classList.remove("type-selected")
            this.importTab.current.classList.add("type-selected")
            this.setState({
                seriesGroups: this.getRealTimeChartExportSeriesGroup(),
                chartImportExportType: 'Export',
                isLineChartUpdate: true
            });
        } else {
            this.importTab.current.classList.remove("type-selected");
            this.exportTab.current.classList.add("type-selected");
            this.setState({
                seriesGroups: this.getRealTimeChartImportSeriesGroup(),
                chartImportExportType: "Import",
                isLineChartUpdate: true
            });
        }
    }

    switchView = (view) => {
        if (view.toLowerCase() === "grid") {
                   this.setGridData();
            this.realTimePannel.current.classList.add("hidePannel");
            this.gridPannel.current.classList.remove("hidePannel");
            this.graphViewBtn.current.classList.remove("group-btn-selected");
            this.gridViewBtn.current.classList.add("group-btn-selected");
            setTimeout(() => {
                this.refs.childgridRealTime.refreshGrid();
                this.refs.childgridRealTime.expandFirstRow();
                this.refs.childgridRealTime.handleResize();
             }, 200)
             this.graphicalViewMsg.current.classList.add('d-none');
             this.gridViewMsg.current.classList.remove('d-none');
             this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/realtime/grid/");
        }
        else {
            this.gridPannel.current.classList.add("hidePannel");
            this.realTimePannel.current.classList.remove("hidePannel");
            this.graphViewBtn.current.classList.add("group-btn-selected");
            this.gridViewBtn.current.classList.remove("group-btn-selected");
            this.gridViewMsg.current.classList.add('d-none');
            this.graphicalViewMsg.current.classList.remove('d-none');
            this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/realtime/graph/");
        }
    }

    handleResizeEvent=()=>{
        this.setGridData();
    }

    refreshRealTimePage(){
       this.getData();
       this.plannedOutagesRef.current.refresh();
    }

    setGridData = () => {
        var groups = groupBy(this.state.realTimeReportData, 'date');
        var result = Object.entries(groups);
        const data = {
            dataFields: getGridDataFeilds(),
            // localdata: result
            datatype: 'json',
            localdata: result
        }
        this.setState({ gridSource: data , gridHeight:null,dataLoaded:true});
        //Expand first row bydefault
        this.refs.childgridRealTime.expandFirstRow();
        this.expandButton.current.style.display="none";
        this.collpaseButton.current.style.display="";
        this.refs.childgridRealTime.handleResize();
    }

    collapseRows=(e)=>{
        if(this.state.dataLoaded)
        {
            this.refs.childgridRealTime.hideRowDetails();
            this.collpaseButton.current.style.display="none";
            this.expandButton.current.style.display="";
        }
    }

    onRowShowhide=(expandAll)=>{
        if(expandAll){
            this.expandButton.current.style.display="none";
            this.collpaseButton.current.style.display="";
        }else{
            this.collpaseButton.current.style.display="none";
            this.expandButton.current.style.display="";
        }
    }

    expandRows=(e)=>{
        this.refs.childgridRealTime.showRowDetails();
        this.expandButton.current.style.display="none";
        this.collpaseButton.current.style.display="";
    }
    
    downloadCSV=()=>{
         var data= this.state.csvData;
         if(data!==undefined && data!==null && data.length>0)
         {
            var csvData=convertToCSVData(data) ;
            var reportTime=moment().tz("Canada/Mountain").format("YYYY-MM-DD HH:mm:ss");
            var title="Realtime Intertie Capability Report As Of: "+reportTime+" MDT";
            var csvRows=createCSVRows(csvData,title);
            var csvString = csvRows.join('\n');
            downloadCSV(csvString,constants.RealtimeReportFileName);
         }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row ml-0 mr-0 mt-4">
                    <div className="col-md-12 ml-0 mr-0 pl-0 pr-0">
                        <div className="row ml-0 mr-0 float-right">

                            <div className="groupBtnInfo mr-3">
                                <span>{constants.en_messages.generic.realtime.btnMsg}<Time value={this.state.lastRefreshTime} format="hh:mm"  /></span>
                            </div>
                            <div className="text-center group-btn-refresh  mr-3" onClick={() =>{this.refreshRealTimePage()}}>
                                <button ref={this.refreshBtn} className=""> </button>
                            </div>
                            <div ref={this.graphViewBtn} className="text-center group-btn-1 group-btn-label group-btn-selected" onClick={() => this.switchView("graph")}>
                                {constants.en_messages.generic.realtime.view1}
                            </div>
                            <div ref={this.gridViewBtn} className="text-center group-btn-2 group-btn-label" onClick={() => this.switchView("grid")}>
                                {constants.en_messages.generic.realtime.view2}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row ml-0 mr-0 mt-2 mb-3">
                    <div ref={this.gridViewMsg} className="col-md-12 ml-0 mr-0 pl-0 pr-0 d-none">
                        <div className="row ml-0 mr-0 justify-content-end">
                            <div className=" ml-0 mr-0 pl-0 pr-0 align-self-end">
                                <span className="info-txt-grid-view ">{constants.en_messages.generic.realtime.view2msg1}</span>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0 justify-content-end">
                            <div className=" ml-0 mr-0 pl-0 pr-0 align-self-end">
                                <span className="info-txt-grid-view-red">{constants.en_messages.generic.realtime.msg1}<br/>{constants.en_messages.generic.realtime.msg2}</span>
                            </div>
                        </div>
                    </div>  
                    <div ref={this.graphicalViewMsg} className="col-md-12 ml-0 mr-0 pl-0 pr-0">
                        <div className="row ml-0 mr-0 justify-content-end">
                            <div className=" ml-0 mr-0 pl-0 pr-0 align-self-end">
                                <span className="info-txt-grid-view ">{constants.en_messages.generic.realtime.view1msg1}</span>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0 justify-content-end">
                            <div className=" ml-0 mr-0 pl-0 pr-0 align-self-end">
                                <span className="info-txt-grid-view-red">{constants.en_messages.generic.realtime.msg1}<br/>{constants.en_messages.generic.realtime.msg2}</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="row ml-0 mr-0 mb-2">
                    <div className="col-md-12 ml-0 mr-0 pl-0 pr-0 legend aeso-err-msg">
                        {parse(this.state.errorMesage)}
                    </div>
                </div>    
                <div className="row ml-0 mr-0">
                    <div ref={this.realTimePannel} className="container pl-0 pr-0 ml-0 mr-0 slide-in">
                        <div className="row ml-0 mr-0 d-flex justify-content-between">
                            <React.Fragment>
                                <SectionTitle styleClass="atc-section mt-0" title={constants.en_messages.generic.realtime.section1.title} />
                                <div className="ml-0 mr-0 pl-0 pr-0" style={{ clear: 'both' }}>
                                    <span className="real-Time-info-var ml-1">{constants.en_messages.generic.common.date}: </span><span className="real-Time-info-var-val mr-2">{this.state.currentHourEndingData !== undefined ? this.state.currentHourEndingData.date : ''}</span>
                                    <span className="real-Time-info-var ml-1">{constants.en_messages.generic.common.hourEnding}: </span><span className="real-Time-info-var-val mr-2">{this.state.currentHourEndingData !== undefined ? this.state.currentHourEndingData.hourEnding : ''}</span>
                                </div>
                                <div className="container ml-0 pl-0 pr-0 mr-0 mb-0 mt-4">
                                    <div className="row h-100 justify-content-center align-items-center">
                                        <div className=" col-md-2-2 ml-0 mr-0 pl-0 prpx-1">
                                            <Card import={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.bcIntertie !== undefined ? this.state.currentHourEndingData.bcIntertie[constants.Import_Data][constants.ATC] : 0}
                                                export={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.bcIntertie !== undefined ? this.state.currentHourEndingData.bcIntertie[constants.Export_Data][constants.ATC] : 0}
                                                title={constants.en_messages.generic.common.bcAbbreviation} cls={'bc-atc'} />
                                        </div>
                                        <div className=" col-md-2-2 ml-0 mr-0 pl-0 prpx-1">
                                            <Card import={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.matlInertie !== undefined ? this.state.currentHourEndingData.matlInertie[constants.Import_Data][constants.ATC] : 0}
                                                export={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.matlInertie !== undefined ? this.state.currentHourEndingData.matlInertie[constants.Export_Data][constants.ATC] : 0}
                                                title={constants.en_messages.generic.common.matlAbbreviation} cls={'matl-atc'} />
                                        </div>
                                        <div className=" col-md-2-2 ml-0 mr-0 pl-0 prpx-1">
                                            <Card import={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.skInertie !== undefined ? this.state.currentHourEndingData.skInertie[constants.Import_Data][constants.ATC] : 0}
                                                export={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.skInertie !== undefined ? this.state.currentHourEndingData.skInertie[constants.Export_Data][constants.ATC] : 0}
                                                title={constants.en_messages.generic.common.skAbbreviation} cls={'sk-atc'} />
                                        </div>
                                        <div className=" col-md-2-2 ml-0 mr-0 pl-0 prpx-1">
                                            <Card import={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.bcmatlFlowgate !== undefined ? this.state.currentHourEndingData.bcmatlFlowgate[constants.Import_Data][constants.ATC] : 0}
                                                export={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.bcmatlFlowgate !== undefined ? this.state.currentHourEndingData.bcmatlFlowgate[constants.Export_Data][constants.ATC] : 0}
                                                title={constants.en_messages.generic.common.bcMatlAbbreviation} cls={'bcmatl-atc'} />
                                        </div>
                                        <div className=" col-md-2-2 ml-0 mr-0 pl-0 ">
                                            <Card import={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.systemFlowgate !== undefined ? this.state.currentHourEndingData.systemFlowgate[constants.Import_Data][constants.ATC] : 0}
                                                export={this.state.currentHourEndingData !== undefined && this.state.currentHourEndingData.systemFlowgate !== undefined ? this.state.currentHourEndingData.systemFlowgate[constants.Export_Data][constants.ATC] : 0}
                                                title={constants.en_messages.generic.common.systemAbbreviation} cls={'system-atc'} />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                        <div className="row ml-0 mr-0 justify-content-center align-items-center pl-0 pr-0">
                            <React.Fragment>

                                <SectionTitle title="Intertie Capability: 48 hours Trend" />
                                <div className="container interie-section  ml-0 mr-0 pl-0 pr-0">
                                    <div className="row section-type">
                                        <button ref={this.importTab} className="col-md pl-0 pr-0 text-center import type-label" onClick={(e) => this.ShowImportExportData(e, "Import")}>
                                            {constants.en_messages.generic.common.import?constants.en_messages.generic.common.import.toUpperCase():""}
                                        </button>
                                        <button ref={this.exportTab} className="col-md pl-0 pr-0 text-center export type-label type-selected" onClick={(e) => this.ShowImportExportData(e, "Export")}>
                                            {constants.en_messages.generic.common.export?constants.en_messages.generic.common.export.toUpperCase():""}
                                        </button>
                                    </div>
                                    <div className="row section-type section-margin-top justify-content-between">
                                        <div className="col-md-6 ml-0 mr-0 pl-0 pr-0 align-self-center">
                                            <span className="real-Time-info-var ml-10">{constants.en_messages.generic.common.date}: </span><span className="real-Time-info-var-val mr-2"> {this.state.detailgridSource !== undefined ? this.state.detailgridSource.date : ''}</span>
                                            <span className="real-Time-info-var ml-1">{constants.en_messages.generic.common.hourEnding}: </span><span className="real-Time-info-var-val mr-2">{this.state.detailgridSource !== undefined ? this.state.detailgridSource.hourEnding : ''}</span>

                                        </div>
                                        <div className="col-md-6 ml-0 mr-0 pl-0 pr-0">
                                            <div className="row">
                                                <div className="col-1" style={{ marginLeft: '33px' }}>
                                                    <JqxCheckBox theme="aeso-checkbox" ref={this.chkBoxGO} onChange={(event) => this.hideShowGrossOfferLines()}
                                                        checked={this.state.chkBoxGOVal} width={25} height={25} />
                                                </div>
                                                <div className="col-2 chk-label p-0">{constants.en_messages.generic.common.grossOffers}</div>
                                                <div className="col-1 ml-3">
                                                    <JqxCheckBox theme="aeso-checkbox" ref={this.chkBoxATC} onChange={(event) => this.hideShowATCLines()} checked={this.state.chkBoxATCVal} width={25} height={25} />
                                                </div>
                                                <div className="col-1 chk-label">{constants.en_messages.generic.common.atc}</div>
                                                <div className="col-1">
                                                    <JqxCheckBox theme="aeso-checkbox" ref={this.chkBoxTTC} onChange={(event) => this.hideShowTTCLines()} checked={this.state.chkBoxTTCVal} width={25} height={25} />
                                                </div>
                                                <div className="col-2 chk-label">{constants.en_messages.generic.common.ttc}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row section-type mt-3 justify-content-between border-top-color-2">
                                        <div className="col-md-6 ml-0 mr-0 pl-0 pr-0 align-self-center">
                                        </div>
                                        <div className="col-md-6  ml-0 mr-0 pl-0 pr-0 mt-3 align-self-center">
                                            <span className="legend-ttc"></span>
                                            <span className="chart-legend-label"><span className="chart-legend-text">{constants.en_messages.generic.common.ttc}</span></span>
                                            <span className="chart-legend legend-atc"></span>
                                            <span className="chart-legend-label"><span className="chart-legend-text">{constants.en_messages.generic.common.atc}</span></span>
                                            <span className="chart-legend legend-grossoffer"></span>
                                            <span className="chart-legend-label"><span className="chart-legend-text">{constants.en_messages.generic.common.grossOffers}</span></span>
                                        </div>
                                    </div>
                                    <div className="row section-type">
                                        <div className="col-md-12 pt-20">
                                            <LineChart ref={this.lineChartChildRef}
                                                padding={this.state.padding}
                                                seriesGroups={this.state.seriesGroups}
                                                source={this.state.lineChartsource}
                                                valueAxis={this.state.valueAxis}
                                                xAxis={this.state.xAxis}
                                               // chartData={this.state.realTimeReportData}
                                                lineCallback={this.lineCallback}
                                                isUpdate={this.state.isLineChartUpdate}
                                            />
                                        </div>
                                        <div className="section-state-chkbox pr-0 mr-0">
                                            <div className="section-state-section sec-bc">
                                                <input className="checkbox-state-bc" type="checkbox" name="chkBC" id="chkBC"
                                                    ref={this.chkBoxBC} onChange={(event) => this.hideShowIntertieFlowgateChartLines("BC")}
                                                    checked={this.state.chkBoxBCVal} />
                                                <label className="checkox-state-label" htmlFor="chkBC"><span className="checkbox-state-label-span" >{constants.en_messages.generic.common.bcAbbreviation}</span></label>
                                            </div>
                                            <div className="section-state-section sec-matl">
                                                <input className="checkbox-state-matl" type="checkbox" name="chkMATL" id="chkMATL"
                                                    ref={this.chkBoxMATL} onChange={(event) => this.hideShowIntertieFlowgateChartLines("MATL")}
                                                    checked={this.state.chkBoxMATLVal} />
                                                <label className="checkox-state-label" htmlFor="chkMATL"><span className="checkbox-state-label-span" >{constants.en_messages.generic.common.matlAbbreviation}</span></label>
                                            </div>
                                            <div className="section-state-section sec-sk">
                                                <input className="checkbox-state-sk" type="checkbox" name="chkSK" id="chkSK"
                                                    ref={this.chkBoxSK} onChange={(event) => this.hideShowIntertieFlowgateChartLines("SK")}
                                                    checked={this.state.chkBoxSKVal} />
                                                <label className="checkox-state-label" htmlFor="chkSK"><span className="checkbox-state-label-span">{constants.en_messages.generic.common.skAbbreviation}</span></label>
                                            </div>
                                            <div className="section-state-section sec-bc-matl">
                                                <input className="checkbox-state-bc-matl" type="checkbox" name="chkBCMATL" id="chkBCMATL"
                                                    ref={this.chkBoxBCMATL}
                                                    onChange={(event) => this.hideShowIntertieFlowgateChartLines("BCMATL")}
                                                    checked={this.state.chkBoxBCMATLVal} />
                                                <label className="checkox-state-label" htmlFor="chkBCMATL"><span className="checkbox-state-label-span">{constants.en_messages.generic.common.bcMatlAbbreviation}</span></label>
                                            </div>
                                            <div className="section-state-section sec-system">
                                                <input className="checkbox-state-system" type="checkbox" name="chkSystem" id="chkSystem"
                                                    ref={this.chkBoxSystem}
                                                    checked={this.state.chkBoxSystemVal} onChange={(event) => this.hideShowIntertieFlowgateChartLines('SYSTEM')} />
                                                <label className="checkox-state-label border-bottom-2" htmlFor="chkSystem" ><span className="checkbox-state-label-span">{constants.en_messages.generic.common.systemAbbreviation}</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row detail-view section-type justify-content-center align-items-center mt-4">
                                        <div className="col-md-12 label-color ml-0 mr-0 pl-2 pr-2 pt-2 mt-0" onClick={(e) => this.togglePanel(e)} style={{ cursor: 'pointer' }}>
                                            <h6 className="detail-grid-label">{constants.en_messages.generic.realtime.section2.dtlView}</h6> <i id="icon-showhide" className="arrow up mt-2"></i>
                                        </div>
                                        {!this.state.collpaseDetailGrid ? (
                                            <div className="col-12-real-detail section collapsible">
                                                <DetailViewGrid item={this.state.detailgridSource} type={this.state.chartImportExportType} />
                                            </div>
                                        ) : <></>}
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                        <div className="row ml-0 mr-0  pl-0 pr-0">
                            <PlannedOutage ref={this.plannedOutagesRef}></PlannedOutage> 
                        </div> 
                    </div>
                </div>
                <React.Fragment>
            <div ref={this.gridPannel} className="container pl-0 pr-0 ml-0 mr-0 hidePannel">
              <SectionTitle styleClass="atc-section mt-0" title={constants.en_messages.generic.realtime.section3.title} />
              <div className="row ml-0 mr-0">
                     <div className="col-md-7 ml-0 mr-0 mt-3 pl-0 pr-0 float-left sub-description">
                        {constants.en_messages.generic.realtime.msg3}
                     </div>
                     <div className="col-md-5 ml-0 mr-0 mt-2 pl-0 pr-0 float-right">
                        <button ref={this.collpaseButton} className='float-right ml-4 seconday-button' onClick={(e)=>this.collapseRows(e)}>
                           <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg3}</span>
                        </button>
                        <button ref={this.expandButton} style={{display:'none'}} className='float-right ml-4 seconday-button' onClick={(e)=>this.expandRows(e)}>
                         <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg4}</span>
                        </button>
                        <button title="Download the displayed data." className='seconday-button float-right'  onClick={()=>{this.downloadCSV()}}>
                            <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg2}</span></button>
                     </div>
                </div>
                <div className="row ml-0 mr-0">
                    <div className="col-md-12 ml-0 mr-0 mt-3 pl-0 pr-0"> 
                        <Grid  
                        ref="childgridRealTime"
                        theme="aeso-grid" 
                        columngroups={this.state.columngroups}
                        columns={this.state.columns}
                        source={new jqx.dataAdapter(this.state.gridSource)}
                        cellsrenderer={this.cellsrenderer}
                        groups={this.state.groups}
                        pageable={false}
                        width={'99.46%'}
                        rowdetails={true}
                        onRowShowhide={this.onRowShowhide}
                        rowDetailItemHeight={175}
                        height={this.state.gridHeight}
                        />
                    </div>
                </div>
            </div>
            </React.Fragment>
            </React.Fragment>
        );
    }
}
export default RealTimeReport;