import * as React from 'react';
import '../../assets/scss/components/DetailViewGrid.scss';
import * as constants from '../../constants/Constants';
import parse from 'html-react-parser';

class DetailViewGrid extends React.PureComponent {
    state = {
        detailgridSource: {},
        type: ''
    }

    componentDidMount = () => {
        console.log(this.props.type);
        this.setState({
            detailgridSource: this.props.item,
            type: this.props.type
        });
    }

    componentDidUpdate() {
        this.setState({
            detailgridSource: this.props.item,
            type: this.props.type
        });
    }

    render() {
        if (this.state.detailgridSource === {} || this.state.detailgridSource === undefined || this.state.detailgridSource.bcIntertie === undefined) {
            return null;
        }
        var bcIntertie = this.state.detailgridSource.bcIntertie;
        var matlIntertie = this.state.detailgridSource.matlInertie;
        var skIntertie = this.state.detailgridSource.skInertie;
        var bcmatlFlowgate = this.state.detailgridSource.bcmatlFlowgate;
        var systemFlowgate = this.state.detailgridSource.systemFlowgate;
        var comments = '';
        var atcBC = '';
        var atcMATL = '';
        var atcSK = '';
        var atBCMATL = '';
        var atcSystem = '';
        var index = 1;
        if (this.state.type === "Import") {
            if (bcIntertie[constants.Import_Data][constants.Comments] !== null && bcIntertie[constants.Import_Data][constants.Comments] !== undefined && bcIntertie[constants.Import_Data][constants.Comments].length > 0) {
                atcBC = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcBC + " " + bcIntertie[constants.Import_Data][constants.Comments] + "</p>";
                index++;
            }
            if (matlIntertie[constants.Import_Data][constants.Comments] !== null && matlIntertie[constants.Import_Data][constants.Comments] !== undefined && matlIntertie[constants.Import_Data][constants.Comments].length > 0) {
                atcMATL = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcMATL + " " + matlIntertie[constants.Import_Data][constants.Comments] + "</p>";
                index++;
            }
            if (skIntertie[constants.Import_Data][constants.Comments] !== null && skIntertie[constants.Import_Data][constants.Comments] !== undefined && skIntertie[constants.Import_Data][constants.Comments].length > 0) {
                atcSK = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcSK + " " + skIntertie[constants.Import_Data][constants.Comments] + "</p>";
                index++;
            }
            if (bcmatlFlowgate[constants.Import_Data][constants.Comments] !== null && bcmatlFlowgate[constants.Import_Data][constants.Comments] !== undefined && bcmatlFlowgate[constants.Import_Data][constants.Comments].length > 0) {
                atBCMATL = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atBCMATL + " " + bcmatlFlowgate[constants.Import_Data][constants.Comments] + "</p>";
                index++;
            }
            if (systemFlowgate[constants.Import_Data][constants.Comments] !== null && systemFlowgate[constants.Import_Data][constants.Comments] !== undefined && systemFlowgate[constants.Import_Data][constants.Comments].length > 0) {
                atcSystem = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcSystem + " " + systemFlowgate[constants.Import_Data][constants.Comments] + "</p>";
                index++;
            }
        }

        if (this.state.type === "Export") {
            if (bcIntertie[constants.Export_Data][constants.Comments] !== null && bcIntertie[constants.Export_Data][constants.Comments] !== undefined && bcIntertie[constants.Export_Data][constants.Comments].length > 0) {
                atcBC = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcBC + " " + bcIntertie[constants.Export_Data][constants.Comments] + "</p>";
                index++;
            }
            if (matlIntertie[constants.Export_Data][constants.Comments] !== null && matlIntertie[constants.Export_Data][constants.Comments] !== undefined && bcIntertie[constants.Export_Data][constants.Comments].length > 0) {
                atcMATL = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcMATL + " " + matlIntertie[constants.Export_Data][constants.Comments] + "</p>";
                index++;
            }
            if (skIntertie[constants.Export_Data][constants.Comments] !== null && skIntertie[constants.Export_Data][constants.Comments] !== undefined && skIntertie[constants.Export_Data][constants.Comments].length > 0) {
                atcSK = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcSK + " " + skIntertie[constants.Export_Data][constants.Comments] + "</p>";
                index++;
            }
            if (bcmatlFlowgate[constants.Export_Data][constants.Comments] !== null && bcmatlFlowgate[constants.Export_Data][constants.Comments] !== undefined && bcmatlFlowgate[constants.Export_Data][constants.Comments].length > 0) {
                atBCMATL = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atBCMATL + " " + bcmatlFlowgate[constants.Export_Data][constants.Comments] + "</p>";
                index++;
            }
            if (systemFlowgate[constants.Export_Data][constants.Comments] !== null && systemFlowgate[constants.Export_Data][constants.Comments] !== undefined && systemFlowgate[constants.Export_Data][constants.Comments].length > 0) {
                atcSystem = "(" + index + ")";
                comments += "<p class='p-0 m-0'>" + atcSystem + " " + systemFlowgate[constants.Export_Data][constants.Comments] + "</p>";
                index++;
            }

        }


        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th rowSpan="2" colSpan="2" className="header-empty"></th>
                            <th colSpan="3" className="title-intertie">{constants.en_messages.generic.common.interties}</th>
                            <th colSpan="2" className="title-intertie">{constants.en_messages.generic.common.flowGates}</th>
                            <th rowSpan="2" className="header-comments">{constants.en_messages.generic.common.comments}</th>
                        </tr>
                        <tr>
                            <th className="header-bc">{constants.en_messages.generic.common.bcAbbreviation}</th>
                            <th className="header-matl">{constants.en_messages.generic.common.matlAbbreviation}</th>
                            <th className="header-sk">{constants.en_messages.generic.common.skAbbreviation}</th>
                            <th className="header-bcmatl">{constants.en_messages.generic.common.bcMatlAbbreviation}</th>
                            <th className="header-system">{constants.en_messages.generic.common.systemAbbreviation}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2" className="label-color">{constants.en_messages.generic.common.grossOffers}</td>
                            <td className="">{this.state.type === "Import" ? bcIntertie[constants.Import_Data][constants.GrossOffer] : bcIntertie[constants.Export_Data][constants.GrossOffer]}</td>
                            <td className="">{this.state.type === "Import" ? matlIntertie[constants.Import_Data][constants.GrossOffer] : matlIntertie[constants.Export_Data][constants.GrossOffer]}</td>
                            <td className="">{this.state.type === "Import" ? skIntertie[constants.Import_Data][constants.GrossOffer] : skIntertie[constants.Export_Data][constants.GrossOffer]}</td>
                            <td className="">{this.state.type === "Import" ? bcmatlFlowgate[constants.Import_Data][constants.GrossOffer] : bcmatlFlowgate[constants.Export_Data][constants.GrossOffer]}</td>
                            <td className=" border-right">{this.state.type === "Import" ? systemFlowgate[constants.Import_Data][constants.GrossOffer] : systemFlowgate[constants.Export_Data][constants.GrossOffer]}</td>
                            <td rowSpan="6" className="comment-row text-left pl-3">
                                {parse(comments)}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="label-color">{constants.en_messages.generic.common.ttc}</td>
                            <td className="">{this.state.type === "Import" ? bcIntertie[constants.Import_Data][constants.TTC] : bcIntertie[constants.Export_Data][constants.TTC]}</td>
                            <td className="">{this.state.type === "Import" ? matlIntertie[constants.Import_Data][constants.TTC] : matlIntertie[constants.Export_Data][constants.TTC]}</td>
                            <td className="">{this.state.type === "Import" ? skIntertie[constants.Import_Data][constants.TTC] : skIntertie[constants.Export_Data][constants.TTC]}</td>
                            <td className="">{this.state.type === "Import" ? bcmatlFlowgate[constants.Import_Data][constants.TTC] : bcmatlFlowgate[constants.Export_Data][constants.TTC]}</td>
                            <td className=" border-right">{this.state.type === "Import" ? systemFlowgate[constants.Import_Data][constants.TTC] : systemFlowgate[constants.Export_Data][constants.TTC]}</td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className="label-color border-right pl-1 pr-1">{constants.en_messages.generic.common.trm}</td>
                            <td className="row-title">System</td>
                            <td className="">{this.state.type === "Import" ? bcIntertie[constants.Import_Data][constants.TRM_System] : bcIntertie[constants.Export_Data][constants.TRM_System]}</td>
                            <td className="">{this.state.type === "Import" ? matlIntertie[constants.Import_Data][constants.TRM_System] : matlIntertie[constants.Export_Data][constants.TRM_System]}</td>
                            <td className="">{this.state.type === "Import" ? skIntertie[constants.Import_Data][constants.TRM_System] : skIntertie[constants.Export_Data][constants.TRM_System]}</td>
                            <td className="">{this.state.type === "Import" ? bcmatlFlowgate[constants.Import_Data][constants.TRM_System] : bcmatlFlowgate[constants.Export_Data][constants.TRM_System]}</td>
                            <td className=" border-right">{this.state.type === "Import" ? systemFlowgate[constants.Import_Data][constants.TRM_System] : systemFlowgate[constants.Export_Data][constants.TRM_System]}</td>
                        </tr>
                        <tr>
                            <td className="row-title">{constants.en_messages.generic.common.trmAlloc}</td>
                            <td className="">{this.state.type === "Import" ? bcIntertie[constants.Import_Data][constants.TRM_Allocation] : bcIntertie[constants.Export_Data][constants.TRM_Allocation]}</td>
                            <td className="">{this.state.type === "Import" ? matlIntertie[constants.Import_Data][constants.TRM_Allocation] : matlIntertie[constants.Export_Data][constants.TRM_Allocation]}</td>
                            <td className="">{this.state.type === "Import" ? skIntertie[constants.Import_Data][constants.TRM_Allocation] : skIntertie[constants.Export_Data][constants.TRM_Allocation]}</td>
                            <td className="">{this.state.type === "Import" ? bcmatlFlowgate[constants.Import_Data][constants.TRM_Allocation] : bcmatlFlowgate[constants.Export_Data][constants.TRM_Allocation]}</td>
                            <td className=" border-right">{this.state.type === "Import" ? systemFlowgate[constants.Import_Data][constants.TRM_Allocation] : systemFlowgate[constants.Export_Data][constants.TRM_Allocation]}</td>
                        </tr>
                        <tr>
                            <td className="row-title">{constants.en_messages.generic.common.trmTotal}</td>
                            <td className="">{this.state.type === "Import" ? bcIntertie[constants.Import_Data][constants.TRM_Total] : bcIntertie[constants.Export_Data][constants.TRM_Total]}</td>
                            <td className="">{this.state.type === "Import" ? matlIntertie[constants.Import_Data][constants.TRM_Total] : matlIntertie[constants.Export_Data][constants.TRM_Total]}</td>
                            <td className="">{this.state.type === "Import" ? skIntertie[constants.Import_Data][constants.TRM_Total] : skIntertie[constants.Export_Data][constants.TRM_Total]}</td>
                            <td className="">{this.state.type === "Import" ? bcmatlFlowgate[constants.Import_Data][constants.TRM_Total] : bcmatlFlowgate[constants.Export_Data][constants.TRM_Total]}</td>
                            <td className=" border-right">{this.state.type === "Import" ? systemFlowgate[constants.Import_Data][constants.TRM_Total] : systemFlowgate[constants.Export_Data][constants.TRM_Total]}</td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="label-color">{constants.en_messages.generic.common.atc}</td>
                            <td className=" label-color">{this.state.type === "Import" ? bcIntertie[constants.Import_Data][constants.ATC] : bcIntertie[constants.Export_Data][constants.ATC]}<span className="atc-comment-number">{atcBC}</span></td>
                            <td className=" label-color">{this.state.type === "Import" ? matlIntertie[constants.Import_Data][constants.ATC] : matlIntertie[constants.Export_Data][constants.ATC]}<span className="atc-comment-number">{atcMATL}</span></td>
                            <td className=" label-color">{this.state.type === "Import" ? skIntertie[constants.Import_Data][constants.ATC] : skIntertie[constants.Export_Data][constants.ATC]}<span className="atc-comment-number">{atcSK}</span></td>
                            <td className=" label-color">{this.state.type === "Import" ? bcmatlFlowgate[constants.Import_Data][constants.ATC] : bcmatlFlowgate[constants.Export_Data][constants.ATC]}<span className="atc-comment-number">{atBCMATL}</span></td>
                            <td className=" border-right label-color">{this.state.type === "Import" ? systemFlowgate[constants.Import_Data][constants.ATC] : systemFlowgate[constants.Export_Data][constants.ATC]}<span className="atc-comment-number">{atcSystem}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

}

DetailViewGrid.defaultProps = {
    detailgridSource: {},
    type: 'Import'
}

export default DetailViewGrid;