import React from 'react';

import Loader from 'react-loader-spinner'
import '../../../assets/scss/components/Loader.scss';

class AESOLoader extends React.Component {

    static singletonRef;

    constructor(props) {
        super(props);
        this.state = {
            show: true
        };

        if (AESOLoader.singletonRef == null) {
            AESOLoader.singletonRef = this;
        } else {
            this.state = {
                show: AESOLoader.singletonRef.show
            }
            AESOLoader.singletonRef.setState({
                show: true
            });
            AESOLoader.singletonRef = this;
        }
    }

    show = () => {
        this.setState({
            show: true,
        });
    };

    hide = () => {
        this.setState({
            show: false,
        });
    };

    options = {
        lines: 13,
        length: 20,
        width: 10,
        radius: 30,
        scale: 1.00,
        corners: 1,
        color: '#003D75',
        opacity: 0.25,
        rotate: 0,
        direction: 1,
        speed: 1,
        trail: 60,
        fps: 20,
        zIndex: 2e9,
        top: '50%',
        left: '50%',
        shadow: true,
        hwaccel: true,
        position: 'absolute'
    };

     
    // const handleShow = () => setShow(true);

    render() {
        return (
            
            this.state.show ?
            <div className="spinner-pannel">
              <Loader
              loaded={true} type="Oval" color="#FFFFC9"  options={this.options} className="spinner"  />
            </div>
            : <></>
        )
    }
}

export default AESOLoader;
