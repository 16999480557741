import * as React from 'react';
import ReactDOM from 'react-dom';
import parse from 'html-react-parser';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdatatable';
import * as constants from '../../../constants/Constants';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import '../../../assets/scss/components/Grid.scss';


const { detect } = require('detect-browser');
const browser = detect();

class Grid extends React.PureComponent {
    commentSpanHeight=0;
    commentParentRowIndex=0;
    expandIndex=new Set();
    
    state = {
        columngroups: [],
        columns: [],
        source:  {},
        groups: [],
        width: '',
        groupsexpandedbydefault: false,
        pageable: false,
        groupable: false,
        initrowdetails: {},
        rowdetails: false,
        isForecastReport:false,
        rowDetailItemHeight:141,
        height:120,
        page:1
    }
    
   

    isAllExpanded=()=>{
        const tableArea = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-widget-content-aeso-grid');
        if(tableArea[0].offsetWidth > 0){
            const rows = tableArea[0].getElementsByTagName('TABLE')[0].children[1].children;
            //data-key data-role
            for(var i=1;i<rows.length;i=i+2){
                if(!(rows[i].offsetWidth > 0)){
                    return false;    
                }
            }
        }
        return true;
    }

    getFirstIndexofVisibleRows=()=>{
        try {
            const tableArea = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-widget-content-aeso-grid');
            if(tableArea[0].offsetWidth > 0){
                const rows = tableArea[0].getElementsByTagName('TABLE')[0].children[1].children;
                if(rows.length>0)
                    return rows[0].getAttribute('data-key');
                else
                return -1; 
            }else{
                return -1;
            }
            
        } catch (error) {
            return 0;
        }
    }

    getLastIndexofVisibleRows=()=>{
        try {
            const tableArea = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-widget-content-aeso-grid');
            if(tableArea[0].offsetWidth > 0){
                const rows = tableArea[0].getElementsByTagName('TABLE')[0].children[1].children;
                if(rows.length>0)
                    return rows[rows.length-2].getAttribute('data-key');
                else
                    return -1;
            }else{
                return -1;
            }
        } catch (error) {
            return 0;
        }
    }

    gridRef = React.createRef();

    mergeComments=(item)=>
    {
        var index=1;
        var commentsObj={
            comments:'',
            bcIntertieImport:'',
            bcIntertieExport:'',
            matlIntertieImport:'',
            matlIntertieExport:'',
            skIntertieImport:'',
            skIntertieExport:'',
            bcmatlFlowgateImport:'',
            bcmatlFlowgateExport:'',
            systemFlowgateImport:'',
            systemFlowgateExport:'',
        } 
        // BC Interties
        if(item.bcIntertie!==undefined && item.bcIntertie[constants.Import_Data]!==undefined && item.bcIntertie[constants.Import_Data][constants.Comments]!==null && item.bcIntertie[constants.Import_Data][constants.Comments].length>0 )
        {
            commentsObj.bcIntertieImport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.bcIntertieImport+" "+ item.bcIntertie[constants.Import_Data][constants.Comments] +"</p>";
            index++;  
        }
        if(item.bcIntertie!==undefined && item.bcIntertie[constants.Export_Data]!==undefined && item.bcIntertie[constants.Export_Data][constants.Comments]!==null && item.bcIntertie[constants.Export_Data][constants.Comments].length>0 )
        {
            commentsObj.bcIntertieExport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.bcIntertieExport+" "+ item.bcIntertie[constants.Export_Data][constants.Comments] +"</p>";
            index++;  
        }
        // MATL interties
        if(item.matlInertie!==undefined && item.matlInertie[constants.Import_Data]!==undefined && item.matlInertie[constants.Import_Data][constants.Comments]!==null && item.matlInertie[constants.Import_Data][constants.Comments].length>0)
        {
            commentsObj.matlIntertieImport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.matlIntertieImport+" "+ item.matlInertie[constants.Import_Data][constants.Comments]+"</p>";
            index++;  
        }
        if(item.matlInertie!==undefined && item.matlInertie[constants.Export_Data]!==undefined &&item.matlInertie[constants.Export_Data][constants.Comments]!==null && item.matlInertie[constants.Export_Data][constants.Comments].length>0)
        {
            commentsObj.matlIntertieExport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.matlIntertieExport+" "+ item.matlInertie[constants.Export_Data][constants.Comments]+"</p>";
            index++;  
        }
        // SK interties
        if(item.skInertie!==undefined && item.skInertie[constants.Import_Data]!==undefined && item.skInertie[constants.Import_Data][constants.Comments]!==null && item.skInertie[constants.Import_Data][constants.Comments].length>0)
        {
            commentsObj.skIntertieImport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.skIntertieImport+" "+ item.skInertie[constants.Import_Data][constants.Comments]+"</p>";
            index++;  
        }
        if(item.skInertie!==undefined && item.skInertie[constants.Export_Data]!==undefined && item.skInertie[constants.Export_Data][constants.Comments]!==null && item.skInertie[constants.Export_Data][constants.Comments].length>0)
        {
            commentsObj.skIntertieExport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.skIntertieExport+" "+ item.skInertie[constants.Export_Data][constants.Comments]+"</p>";
            index++;  
        }

        // BC MATL Flowgate
        if(item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Import_Data]!==undefined && item.bcmatlFlowgate[constants.Import_Data][constants.Comments]!==null && item.bcmatlFlowgate[constants.Import_Data][constants.Comments].length>0)
        {
            commentsObj.bcmatlFlowgateImport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.bcmatlFlowgateImport+" "+ item.bcmatlFlowgate[constants.Import_Data][constants.Comments]+"</p>";
            index++;  
        }
        if(item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Export_Data]!==undefined && item.bcmatlFlowgate[constants.Export_Data][constants.Comments]!==null && item.bcmatlFlowgate[constants.Export_Data][constants.Comments].length>0)
        {
            commentsObj.bcmatlFlowgateExport="("+index+")" ;
            commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.bcmatlFlowgateExport+" "+ item.bcmatlFlowgate[constants.Export_Data][constants.Comments]+"</p>";
            index++;  
        }
         // system Flowgate
         if(item.systemFlowgate!==undefined && item.systemFlowgate[constants.Import_Data]!==undefined && item.systemFlowgate[constants.Import_Data][constants.Comments]!==null && item.systemFlowgate[constants.Import_Data][constants.Comments].length>0)
         {
             commentsObj.systemFlowgateImport="("+index+")" ;
             commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.systemFlowgateImport+" "+ item.systemFlowgate[constants.Import_Data][constants.Comments]+"</p>";
             index++;  
         }
         if(item.systemFlowgate!==undefined && item.systemFlowgate[constants.Export_Data]!==undefined && item.systemFlowgate[constants.Export_Data][constants.Comments]!==null && item.systemFlowgate[constants.Export_Data][constants.Comments].length>0)
         {
             commentsObj.systemFlowgateExport="("+index+")" ;
             commentsObj.comments+= "<p class='p-0 m-0'>"+commentsObj.systemFlowgateExport+" "+ item.systemFlowgate[constants.Export_Data][constants.Comments]+"</p>";
             index++;  
         }

        return commentsObj;
    }

    initrowdetails = (id,datarecord, element, rowinfo) => {
        
        if(datarecord[0]===constants.NoDataMsg)
        {
            return false;
        }

        if(id===this.commentParentRowIndex)
        {
            rowinfo.detailsHeight = (this.state.rowDetailItemHeight*datarecord[1].length)+this.commentSpanHeight
        }
        else
        {
            rowinfo.detailsHeight = this.state.rowDetailItemHeight*datarecord[1].length
        }

        //const tableArea = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-widget-content-aeso-grid');
        const header = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-grid-header-aeso-grid');
        var zoomLevel = this.getZoomLevel();
                
        // console.log(tableArea)
        // console.log(header) 
        // console.log(zoomLevel)

        var widthJson = {};
        const jqxColumns = header[0].children[0].children;
        if(jqxColumns[0].getBoundingClientRect().width > 0){
            widthJson.placeholderWidth = (jqxColumns[0].getBoundingClientRect().width - (browser.name === 'chrome'?zoomLevel:0)) + 'px';//placeholder
        }
        if(jqxColumns[1].getBoundingClientRect().width > 0)
            widthJson.blank = (jqxColumns[1].getBoundingClientRect().width) + 'px';//blank
        if(jqxColumns[2].getBoundingClientRect().width > 0)
            widthJson.bcImport = (jqxColumns[2].getBoundingClientRect().width-zoomLevel) + 'px';//bc import
        if(jqxColumns[3].getBoundingClientRect().width > 0)
            widthJson.bcExport = (jqxColumns[3].getBoundingClientRect().width - (browser.name !== 'chrome'?zoomLevel:0)) + 'px';//bc export
        if(jqxColumns[4].getBoundingClientRect().width > 0)
            widthJson.matlImport = (jqxColumns[4].getBoundingClientRect().width-zoomLevel) + 'px';//matl import
        if(jqxColumns[5].getBoundingClientRect().width > 0)
            widthJson.matlExport = (jqxColumns[5].getBoundingClientRect().width - (browser.name !== 'chrome'?zoomLevel:0)) + 'px';//matl export
        if(jqxColumns[6].getBoundingClientRect().width > 0)
            widthJson.skImport = (jqxColumns[6].getBoundingClientRect().width-zoomLevel) + 'px';//sk import
        if(jqxColumns[7].getBoundingClientRect().width > 0)
            widthJson.skExport = (jqxColumns[7].getBoundingClientRect().width - (browser.name !== 'chrome'?zoomLevel:0)) + 'px';//sk export
        if(jqxColumns[8].getBoundingClientRect().width > 0)
            widthJson.bcMatlImport = (jqxColumns[8].getBoundingClientRect().width-zoomLevel) + 'px';//bc matl import
        if(jqxColumns[9].getBoundingClientRect().width > 0)
            widthJson.bcMatlExport = (jqxColumns[9].getBoundingClientRect().width - (browser.name !== 'chrome'?zoomLevel:0)) + 'px';//bc matl export
        if(jqxColumns[10].getBoundingClientRect().width > 0)
            widthJson.systemImport = (jqxColumns[10].getBoundingClientRect().width-zoomLevel) + 'px';//system import
        if(jqxColumns[11].getBoundingClientRect().width > 0)
            widthJson.systemExport = (jqxColumns[11].getBoundingClientRect().width - (browser.name !== 'chrome'?zoomLevel:0)) + 'px';//system export

       //console.log(widthJson);
       const parentdiv = element[0];
        if (parentdiv != null) {
            const child =
                    datarecord[1].map((item, index) => (
                        
                        <div key={index}>
                            {
                                // For Real-Time and Historical Report
                             !this.state.isForecastReport ?
                                ( 
                                 <>
                                <table data-table={index} className="custom-table-fixed">
                                    <tbody>
                                    <tr className="hour-ending-section" >
                                        <td className="grid-placehoder-25 hour-ending-background" style={{width:widthJson.placeholderWidth}}></td>
                                        <td className="grid-detail-row-title-width hour-ending-background" colSpan="2" style={{width:widthJson.blank}}><span className="grid-detail-row-title "> {constants.en_messages.generic.common.hourEndingGrid}: {item.hourEnding}</span></td>
                                        <td className="grid-cell-width border-right-0" style={{width:widthJson.bcImport}}></td>
                                        <td className="grid-cell-width" style={{width:widthJson.bcExport}}></td>
                                        <td className="grid-cell-width border-right-0" style={{width:widthJson.matlImport}}></td>
                                        <td className="grid-cell-width" style={{width:widthJson.matlExport}}></td>
                                        <td className="grid-cell-width border-right-0" style={{width:widthJson.skImport}}></td>
                                        <td className="grid-cell-width" style={{width:widthJson.skExport}}></td>
                                        <td className="grid-cell-width border-right-0" style={{width:widthJson.bcMatlImport}}></td>
                                        <td className="grid-cell-width" style={{width:widthJson.bcMatlExport}}></td>
                                        <td className="grid-cell-width border-right-0" style={{width:widthJson.systemImport}}></td>
                                        <td className="grid-cell-width" style={{width:widthJson.systemExport}}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                            <td className="grid-placehoder-25" ></td>
                                            <td className="grid-detail-row-title-width" colSpan="2"><span className="grid-detail-row-title">{constants.en_messages.generic.common.grossOffers}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Import_Data] !==undefined ? item.bcIntertie[constants.Import_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Export_Data]!==undefined ? item.bcIntertie[constants.Export_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Import_Data]!==undefined ? item.matlInertie[constants.Import_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Export_Data]!==undefined ? item.matlInertie[constants.Export_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Import_Data]!==undefined ? item.skInertie[constants.Import_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Export_Data]!==undefined ? item.skInertie[constants.Export_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Import_Data]!==undefined ? item.bcmatlFlowgate[constants.Import_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Export_Data]!==undefined ? item.bcmatlFlowgate[constants.Export_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Import_Data]!==undefined ?  item.systemFlowgate[constants.Import_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Export_Data]!==undefined ? item.systemFlowgate[constants.Export_Data][constants.GrossOffer]:''}</span></td>
                                            <td className="grid-cell-comments-width" rowSpan="6">
                                                <span className="grid-cell-comments-text">
                                                   {parse(this.mergeComments(item).comments)} 
                                                </span>
                                            </td>
                                    </tr>
                                    <tr>
                                            <td className="grid-placehoder-25" ></td>
                                            <td className="grid-detail-row-title-width" colSpan="2"><span className="grid-detail-row-title">{constants.en_messages.generic.common.ttc}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Import_Data]!==undefined ? item.bcIntertie[constants.Import_Data][constants.TTC] :'' }</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Export_Data] !==undefined ? item.bcIntertie[constants.Export_Data][constants.TTC] : ''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Import_Data]!==undefined  ? item.matlInertie[constants.Import_Data][constants.TTC]: ''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Export_Data]!==undefined ? item.matlInertie[constants.Export_Data][constants.TTC]: ''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Import_Data] !==undefined ? item.skInertie[constants.Import_Data][constants.TTC] : ''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Export_Data] !==undefined ? item.skInertie[constants.Export_Data][constants.TTC] : ''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Import_Data]!==undefined ? item.bcmatlFlowgate[constants.Import_Data][constants.TTC] : ''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Export_Data]!==undefined ? item.bcmatlFlowgate[constants.Export_Data][constants.TTC] : ''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Import_Data] !==undefined? item.systemFlowgate[constants.Import_Data][constants.TTC] : ''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Export_Data]!==undefined ? item.systemFlowgate[constants.Export_Data][constants.TTC] : ''}</span></td>
                                    </tr>
                                    <tr>
                                            <td className="grid-placehoder-25" ></td>
                                            <td className="grid-detail-row-sub-title-width" rowSpan="3"><span className="grid-detail-row-title">{constants.en_messages.generic.common.trm}</span></td>
                                            <td className="grid-detail-row-sub-title-width"><span className="grid-detail-row-sub-title">{constants.en_messages.generic.common.trmSystem}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Import_Data]!==undefined ? item.bcIntertie[constants.Import_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Export_Data] !==undefined ? item.bcIntertie[constants.Export_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Import_Data]!==undefined ? item.matlInertie[constants.Import_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Export_Data]!==undefined ? item.matlInertie[constants.Export_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.matlInertie!==undefined && item.skInertie[constants.Import_Data]!==undefined ? item.skInertie[constants.Import_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.matlInertie!==undefined && item.skInertie[constants.Export_Data]!==undefined ? item.skInertie[constants.Export_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Import_Data]!==undefined ? item.bcmatlFlowgate[constants.Import_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Export_Data]!==undefined ? item.bcmatlFlowgate[constants.Export_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Import_Data]!==undefined ? item.systemFlowgate[constants.Import_Data][constants.TRM_System]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Export_Data]!==undefined ? item.systemFlowgate[constants.Export_Data][constants.TRM_System]:''}</span></td>
                                    </tr>
                                    <tr>
                                            <td className="grid-placehoder-25" ></td>
                                            <td className="grid-detail-row-sub-title-width"><span className="grid-detail-row-sub-title">{constants.en_messages.generic.common.trmAlloc}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Import_Data]!==undefined ? item.bcIntertie[constants.Import_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Export_Data]!==undefined ? item.bcIntertie[constants.Export_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Import_Data]!==undefined ? item.matlInertie[constants.Import_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Export_Data]!==undefined ? item.matlInertie[constants.Export_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Import_Data]!==undefined ? item.skInertie[constants.Import_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Export_Data]!==undefined ? item.skInertie[constants.Export_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Import_Data]!==undefined ? item.bcmatlFlowgate[constants.Import_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Export_Data]!==undefined ? item.bcmatlFlowgate[constants.Export_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Import_Data]!==undefined ? item.systemFlowgate[constants.Import_Data][constants.TRM_Allocation]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Export_Data]!==undefined ?item.systemFlowgate[constants.Export_Data][constants.TRM_Allocation]:''}</span></td>
                                    </tr>
                                    <tr>
                                            <td className="grid-placehoder-25" ></td>
                                            <td className="grid-detail-row-sub-title-width"><span className="grid-detail-row-sub-title">{constants.en_messages.generic.common.trmTotal}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Import_Data]!==undefined ? item.bcIntertie[constants.Import_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcIntertie!==undefined && item.bcIntertie[constants.Export_Data]!==undefined ? item.bcIntertie[constants.Export_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Import_Data]!==undefined ? item.matlInertie[constants.Import_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.matlInertie!==undefined && item.matlInertie[constants.Export_Data]!==undefined ? item.matlInertie[constants.Export_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Import_Data]!==undefined ? item.skInertie[constants.Import_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.skInertie!==undefined && item.skInertie[constants.Export_Data]!==undefined ? item.skInertie[constants.Export_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Import_Data]!==undefined ? item.bcmatlFlowgate[constants.Import_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Export_Data]!==undefined ? item.bcmatlFlowgate[constants.Export_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell">{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Import_Data]!==undefined ?item.systemFlowgate[constants.Import_Data][constants.TRM_Total]:''}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell">{ item.systemFlowgate!==undefined && item.systemFlowgate[constants.Export_Data]!==undefined ? item.systemFlowgate[constants.Export_Data][constants.TRM_Total]:''}</span></td>
                                    </tr>
                                    <tr>
                                            <td className="grid-placehoder-25" ></td>
                                            <td className="grid-detail-row-title-width" colSpan="2"><span className="grid-detail-row-title">{constants.en_messages.generic.common.atc}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.bcIntertie!==undefined && item.bcIntertie[constants.Import_Data]!==undefined ? item.bcIntertie[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcIntertieImport}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.bcIntertie!==undefined && item.bcIntertie[constants.Export_Data]!==undefined ? item.bcIntertie[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcIntertieExport}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.matlInertie!==undefined && item.matlInertie[constants.Import_Data]!==undefined ? item.matlInertie[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).matlIntertieImport}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.matlInertie!==undefined && item.matlInertie[constants.Export_Data]!==undefined ? item.matlInertie[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).matlIntertieExport}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.skInertie!==undefined && item.skInertie[constants.Import_Data]!==undefined ? item.skInertie[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).skIntertieImport}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.skInertie!==undefined && item.skInertie[constants.Export_Data]!==undefined ? item.skInertie[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).skIntertieExport}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Import_Data]!==undefined ? item.bcmatlFlowgate[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcmatlFlowgateImport}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.bcmatlFlowgate!==undefined && item.bcmatlFlowgate[constants.Export_Data]!==undefined ? item.bcmatlFlowgate[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcmatlFlowgateExport}</span></td>
                                            <td className="grid-cell-width border-right-0"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Import_Data]!==undefined ?item.systemFlowgate[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).systemFlowgateImport}</span></td>
                                            <td className="grid-cell-width"><span className="grid-cell" style={{fontFamily:'Verdana'}}>{item.systemFlowgate!==undefined && item.systemFlowgate[constants.Export_Data]!==undefined ? item.systemFlowgate[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).systemFlowgateExport}</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                                </>
                                ) : 
                                //For Forecast report
                                <> 
                                <div>
                                  <table className="custom-table-fixed" data-table={index}>
                                    <tbody>
                                        <tr className="forecast-row">
                                            <td className="grid-placehoder-25 placehoder-forecast"  style={{width:widthJson.placeholderWidth}}></td>
                                            <td className="grid-detail-row-title-width td-forecast" colSpan="2"  style={{width:widthJson.blank}}><span className="grid-detail-row-title" style={{fontSize:'12px'}}>{constants.en_messages.generic.common.hourEnding}: {item.hourEnding}</span></td>
                                            <td className="grid-cell-width border-right-0 td-forecast"  style={{width:widthJson.bcImport}}><span className="grid-cell">{item.bcIntertie!== undefined && item.bcIntertie[constants.Import_Data] !== undefined ? item.bcIntertie[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcIntertieImport}</span></td>
                                            <td className="grid-cell-width td-forecast"  style={{width:widthJson.bcExport}}><span className="grid-cell">{item.bcIntertie!== undefined && item.bcIntertie[constants.Export_Data]!== undefined ? item.bcIntertie[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcIntertieExport}</span></td>
                                            <td className="grid-cell-width border-right-0 td-forecast"  style={{width:widthJson.matlImport}}><span className="grid-cell">{item.matlInertie!== undefined && item.matlInertie[constants.Import_Data]!== undefined ? item.matlInertie[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).matlIntertieImport}</span></td>
                                            <td className="grid-cell-width td-forecast"  style={{width:widthJson.matlExport}}><span className="grid-cell">{item.matlInertie!== undefined && item.matlInertie[constants.Export_Data]!== undefined ? item.matlInertie[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).matlIntertieExport}</span></td>
                                            <td className="grid-cell-width border-right-0 td-forecast "  style={{width:widthJson.skImport}}><span className="grid-cell">{item.skInertie!== undefined && item.skInertie[constants.Import_Data]!== undefined ? item.skInertie[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).skIntertieImport}</span></td>
                                            <td className="grid-cell-width td-forecast"  style={{width:widthJson.skExport}}><span className="grid-cell">{item.skInertie!== undefined && item.skInertie[constants.Export_Data] !== undefined? item.skInertie[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).skIntertieExport}</span></td>
                                            <td className="grid-cell-width border-right-0 td-forecast"  style={{width:widthJson.bcMatlImport}}><span className="grid-cell">{item.bcmatlFlowgate!== undefined && item.bcmatlFlowgate[constants.Import_Data]!== undefined ? item.bcmatlFlowgate[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcmatlFlowgateImport}</span></td>
                                            <td className="grid-cell-width td-forecast"  style={{width:widthJson.bcMatlExport}}><span className="grid-cell" >{ item.bcmatlFlowgate!== undefined &&  item.bcmatlFlowgate[constants.Export_Data]!== undefined ? item.bcmatlFlowgate[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).bcmatlFlowgateExport}</span></td>
                                            <td className="grid-cell-width border-right-0 td-forecast"  style={{width:widthJson.systemImport}}><span className="grid-cell">{item.systemFlowgate!== undefined && item.systemFlowgate[constants.Import_Data]!== undefined ? item.systemFlowgate[constants.Import_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).systemFlowgateImport}</span></td>
                                            <td className="grid-cell-width td-forecast"  style={{width:widthJson.systemExport}}><span className="grid-cell" >{ item.systemFlowgate!== undefined && item.systemFlowgate[constants.Export_Data]!== undefined ? item.systemFlowgate[constants.Export_Data][constants.ATC]:''}</span><span className="atc-comment-number">{this.mergeComments(item).systemFlowgateExport}</span></td>
                                            <td className="grid-cell-comments-width-forecast td-forecast"  rowSpan="6">
                                                <span index={id+index} status="collapsed"  className={item.expanded?'grid-cell-comments-text cmnt-show':'grid-cell-comments-text hider'}>
                                                    {parse(this.mergeComments(item).comments)} 
                                                </span>
                                               {
                                            (this.mergeComments(item).comments!==undefined && this.mergeComments(item).comments!==null && this.mergeComments(item).comments.length>70) ?
                                             <span id={"icon"+id+index} className={item.expanded?'arrow up':'arrow down mb-2'} onClick={(e)=>this.expandComments(e,id,index)}></span> :<></>}
                                            </td>
                                        </tr>
                                    </tbody>
                                  </table>  
                                  </div>
                                </>
                           }
                        </div>
                    ))

            ReactDOM.render(child, parentdiv);
        }
        else
        {
            //const child =<div>No data</div>
          //  ReactDOM.render(child, parentdiv);

        }
        if(this.state.isForecastReport){
            try {
                var commentDiv = parentdiv.getElementsByClassName('grid-cell-comments-width-forecast');
                var totalCommentHeight = 0;
                commentDiv.forEach(element => {
                    if(element.children[0].clientHeight>0 && element.children[0].classList.contains('cmnt-show'))
                        totalCommentHeight += (element.children[0].clientHeight - 25);
                });
                rowinfo.detailsHeight = rowinfo.detailsHeight + totalCommentHeight;
            } catch (error) {
                console.log(error);
            }
        }
    }

    expandComments=(e,parentRowIndex,commentRowIndex)=>
    {
        // var commentsElement= document.querySelectorAll('[index="'+parentRowIndex+commentRowIndex+'"]')[0];
        //  if(commentsElement.getAttribute("status")==="collapsed")
        //     {
        //         commentsElement.style.maxHeight="none";
        //         this.commentSpanHeight = commentsElement.parentNode.clientHeight -25;
        //         this.commentParentRowIndex=parentRowIndex;
        //         this.gridRef.current.updateBoundData();
        //         this.gridRef.current.showDetails(parseInt(this.commentParentRowIndex));
        //         document.querySelectorAll('[index="'+parentRowIndex+commentRowIndex+'"]')[0].style.maxHeight="none";
        //         document.querySelectorAll('[index="'+parentRowIndex+commentRowIndex+'"]')[0].setAttribute("status","Expanded");
        //         var icon =  document.querySelectorAll('[id="'+'icon'+parentRowIndex+commentRowIndex+'"]')[0];
        //         icon.classList.add('up');
        //         icon.classList.remove('mb-2');
        //         icon.classList.remove('down');
        //     }
        //     else
        //     {
        //         this.commentSpanHeight = 0;
        //         this.commentParentRowIndex=parentRowIndex;
        //         this.gridRef.current.updateBoundData();
        //         this.gridRef.current.showDetails(parseInt(this.commentParentRowIndex));
        //     }
        try {
            this.gridRef.current.getView()[parentRowIndex][1][commentRowIndex].expanded=!this.gridRef.current.getView()[parentRowIndex][1][commentRowIndex].expanded;
            this.gridRef.current.updateBoundData();
            this.gridRef.current.beginUpdate();
            this.expandIndex.forEach(element => {
                this.gridRef.current.showDetails(parseInt(element));
            });
            this.gridRef.current.endUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    refreshGrid=()=>
    {
        this.gridRef.current.updateBoundData();
    }

    goToPage=(index)=>
    {
        this.gridRef.current.goToPage(parseInt(index));
    }

    componentDidMount = () => {
            this.setState({
            columngroups: this.props.columngroups,
            columns: this.props.columns,
            source: this.props.source,
            width: this.props.width,
            pageable: this.props.pageable,
            groupable: this.props.groupable,
            rowdetails: this.props.rowdetails,
        });
        this.onRowShowhide = this.props.onRowShowhide;
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount = () => {
        //window.addEventListener('scroll', this.handleScroll);
    }

    handleResizeEvent=()=>{
        this.handleResize();
    }

    handleResize=()=>{
        try {
            console.log("start handleResize "+ new Date());
            //this.gridRef.current.render();
            const tableArea = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-widget-content-aeso-grid');
            const header = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-grid-header-aeso-grid');
            if(tableArea[0].offsetWidth > 0){
                // console.log(this.gridRef);
                // var zoomLevel = this.getZoomLevel();
                
                
                // console.log(zoomLevel)
                // const jqxColumns = header[0].children[0].children;
                // const tables = tableArea[0].getElementsByTagName('TABLE');
                
                // console.log(jqxColumns[0].getBoundingClientRect().width);
                // console.log(jqxColumns[1].getBoundingClientRect().width);
                // console.log(jqxColumns[2].getBoundingClientRect().width);
                // console.log(tables);
                
                // for(var i=0;i<tables.length;i++)
                // {
                //     if(tables[i].hasAttribute("data-table")){
                        
                //         if(jqxColumns[0].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[0].style.width = (jqxColumns[0].getBoundingClientRect().width-zoomLevel) + 'px';//placeholder
                //         if(jqxColumns[1].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[1].style.width = jqxColumns[1].getBoundingClientRect().width + 'px';//blank
                //         if(jqxColumns[2].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[2].style.width = (jqxColumns[2].getBoundingClientRect().width-zoomLevel) + 'px';//bc import
                //         if(jqxColumns[3].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[3].style.width = jqxColumns[3].getBoundingClientRect().width + 'px';//bc export
                //         if(jqxColumns[4].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[4].style.width = (jqxColumns[4].getBoundingClientRect().width-zoomLevel) + 'px';//matl import
                //         if(jqxColumns[5].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[5].style.width = jqxColumns[5].getBoundingClientRect().width + 'px';//matl export
                //         if(jqxColumns[6].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[6].style.width = (jqxColumns[6].getBoundingClientRect().width-zoomLevel) + 'px';//sk import
                //         if(jqxColumns[7].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[7].style.width = jqxColumns[7].getBoundingClientRect().width + 'px';//sk export
                //         if(jqxColumns[8].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[8].style.width = (jqxColumns[8].getBoundingClientRect().width-zoomLevel) + 'px';//bc matl import
                //         if(jqxColumns[9].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[9].style.width = jqxColumns[9].getBoundingClientRect().width + 'px';//bc matl export
                //         if(jqxColumns[10].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[10].style.width = (jqxColumns[10].getBoundingClientRect().width-zoomLevel) + 'px';//system import
                //         if(jqxColumns[11].getBoundingClientRect().width > 0)
                //             tables[i].children[0].children[0].children[11].style.width = jqxColumns[11].getBoundingClientRect().width + 'px';//system export
                //         // tableTr[i].children[0].children[0].children[12].style.width = jqxColumns[12].getBoundingClientRect().width+ 'px';//comment
                //     }
                // }
    
                const fixedHeader=document.getElementById('header-fixed');
    
                if (tableArea[0].getBoundingClientRect().top < 0) {
                    fixedHeader.style.left = tableArea[0].getBoundingClientRect().left+'px';
                    fixedHeader.innerHTML=header[0].outerHTML;
                    fixedHeader.classList.remove('d-none');
                    fixedHeader.classList.add("d-inline");
                } 
                if (tableArea[0].getBoundingClientRect().top > 0) {
                    fixedHeader.innerHTML=''
                    fixedHeader.classList.remove('d-inline');
                    fixedHeader.classList.add('d-none');
                }
            }
            console.log("end handleResize "+ new Date());
        } catch (error) {
            console.log("Error in Auto Height");
        }
    }

    
    handleScroll= (event) => {
        try {
            const table = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-widget-content-aeso-grid');
            const header = ReactDOM.findDOMNode(this.gridRef.current).getElementsByClassName('jqx-grid-header-aeso-grid');
            const fixedHeader=document.getElementById('header-fixed');

            if (table[0].getBoundingClientRect().top < 0) {
                fixedHeader.style.left = table[0].getBoundingClientRect().left+'px';
                fixedHeader.innerHTML=header[0].outerHTML;
                fixedHeader.classList.remove('d-none');
                fixedHeader.classList.add("d-inline");
            } 
            if (table[0].getBoundingClientRect().top > 0) {
                fixedHeader.innerHTML=''
                fixedHeader.classList.remove('d-inline');
                fixedHeader.classList.add('d-none');
            }
        } catch (error) {
            console.log("Error in Auto Height");
        }
    }

    pageChanged = (e)=>
    {
        this.gridRef.current.updateBoundData();
        this.gridRef.current.goToPage(parseInt(e.args.pagenum));
        this.props.gridCallback();
        this.expandIndex.clear();
    }

    componentDidUpdate = () => {
        this.setState({
            columngroups: this.props.columngroups,
            columns: this.props.columns,
            source: this.props.source,
            groups: this.props.groups,
            width: this.props.width,
            pageable: this.props.pageable,
            groupable: this.props.groupable,
            initrowdetails: this.initrowdetails,
            rowdetails: this.props.rowdetails,
            isForecastReport:this.props.isForecastReport,
            rowDetailItemHeight:this.props.rowDetailItemHeight,
            height:this.props.height,
            page:this.props.pageNumber
        });
    }
    
    expandAllInProcess= false;
    showRowDetails=() =>{
        console.log("Start | showRowDetails" + new Date());
        //var rows = this.gridRef.current.getRows();
        //var length= rows.length;
        this.expandAllInProcess = true;

        var first = this.getFirstIndexofVisibleRows();
        var last = this.getLastIndexofVisibleRows();
        this.gridRef.current.beginUpdate();
        console.log("Start | showRowDetails | beginUpdate" + new Date());
        if(first>=0 && last>=0){
            for(var i=first;i<=last;i++)
            {
               console.log('index: '+ i);
               this.gridRef.current.showDetails(parseInt(i));
            }
        } else {
            return;
        }
       
        this.gridRef.current.endUpdate();
        console.log("Start | showRowDetails | endUpdate" + new Date());
        this.expandAllInProcess = false;
        this.handleResize();
        console.log("Start | handleResize | endUpdate" + new Date());
      }
    
    hideRowDetails=()=> {
        var length= this.gridRef.current.getRows().length;
        for(var i=0;i<length;i++)
        {
            this.gridRef.current.hideDetails(i);
        }
      }

    expandFirstRow=()=>{
        var length= this.gridRef.current.getRows().length;
        if(length>0)
        {
            this.gridRef.current.showDetails(0);
        }
    }

    bindingComplete=()=>{
       //this.handleResize();
    }

    columnResized=()=>{
        console.log("columnaaaa");
        //this.handleResize();
    }

    rowExpand=(e)=>{
        if(!this.expandAllInProcess){
            this.handleResize();
        }
        this.props.onRowShowhide(this.isAllExpanded());
        
        this.expandIndex.add(e.args.rowKey);
    }

    onRowCollapse=(e)=>{
        this.props.onRowShowhide(this.isAllExpanded());
        this.expandIndex.delete(e.args.rowKey);
    }

    getZoomLevel() {
        var screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;
        var zoomLevel = 0;
        if (true) {
            if (screenCssPixelRatio >= .46 && screenCssPixelRatio <= .54) {
                zoomLevel = -.4;
            }
            else if (screenCssPixelRatio <= .64) {
                zoomLevel = -.22;
            }
            else if (screenCssPixelRatio <= .76) {
                zoomLevel = -.18;
            }
            else if (screenCssPixelRatio <= .92) {
                zoomLevel = -.1;
            }
            else if (screenCssPixelRatio <= 1.09) {
                zoomLevel = .0;
            }
            else if (screenCssPixelRatio <= 1.32) {
                zoomLevel = .1;
            }
            else if (screenCssPixelRatio <= 1.58) {
                zoomLevel = .2;
            }
            else if (screenCssPixelRatio <= 1.90) {
                zoomLevel = .3;
            }
            else if (screenCssPixelRatio <= 2.28) {
                zoomLevel = .4;
            }
            else if (screenCssPixelRatio <= 2.70) {
                zoomLevel = .5;
            }
            if (zoomLevel === 0) {
                if(browser.name === 'chrome'){
                    zoomLevel = 1;
                }else{
                    zoomLevel = .5;
                }
            }
        }
        return zoomLevel;
    }

    render() {
        return (
            <div>
                 <JqxGrid ref={this.gridRef}
                   width={this.state.width} 
                   source={this.state.source}
                   columns={this.state.columns}
                   columnsmenu={false}
                   pageable={this.state.pageable}
                   columnGroups={this.state.columngroups}
                   onRowExpand={this.rowExpand}
                   onRowCollapse={this.onRowCollapse}
                   rowDetails={this.state.rowdetails}
                   initRowDetails={this.state.initrowdetails}
                   height={this.state.height}
                   theme="aeso-grid"
                   altRows={true}
                   onBindingComplete={this.bindingComplete}
                   onColumnResized={this.columnResized}
                   autoRowHeight ={true}
                   paginationDefaultPage={this.state.page}
                   onPageChanged={this.pageChanged}
                 />
            </div>
        );
    }
}

Grid.defaultProps = {
    width: '100%', // if we do not pass any label from parent, then it will display default value
    source: {},
    columns: [],
    columngroups: [],
    groups: [],
    groupsexpandedbydefault: false,
    pageable: false,
    groupable: false,
    theme: 'arctic',
    isForecastReport:false,
    rowdetails:false,
    height:300,
    page:1
}
export default Grid;