import React from 'react';
import JqxDateTimeInput  from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdatetimeinput';
import {jqx}  from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdropdownlist';
import JqxDropDownList from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdropdownlist';
import {en_messages} from '../../constants/Constants';

import { ReactComponent as CanadaGridLinesMap } from '../../assets/svg/aeso_itc_map_interchange_lines.svg';
import { ReactComponent as OutageErrorIcon } from '../../assets/svg/aeso_itc_outage_error.svg';
import SectionTitle from '../../components/common/title/SectionTitle';
import { getCalendarMinDate, convertOutageData, addClass, removeClass, formatDate, compareDate,convertDate } from '../../utilities/HelperMethods';
import OutagesReportService from '../../apis/OutagesReportService';
import ReactDOM from 'react-dom';
import moment from 'moment';
import timezone from 'moment-timezone';
import EventsLoader from '../../components/common/loader/EventLoader';
import {aesoBcBackground, aesoMatlBackground, aesoSkBackground, aesoBcMatlColor } from '../../assets/scss/bootstrap.scss';//aesoBcColor, aesoMatlColor, aesoSkColor,aesoBcMatlBackground,

const heDropDownData = [
    {
        "heLabel": 1,
        "heValue": 1
    },
    {
        "heLabel": 2,
        "heValue": 2
    },
    {
        "heLabel": 3,
        "heValue": 3
    },
    {
        "heLabel": 4,
        "heValue": 4
    },
    {
        "heLabel": 5,
        "heValue": 5
    },
    {
        "heLabel": 6,
        "heValue": 6
    },
    {
        "heLabel": 7,
        "heValue": 7
    },
    {
        "heLabel": 8,
        "heValue": 8
    },
    {
        "heLabel": 9,
        "heValue": 9
    },
    {
        "heLabel": 10,
        "heValue": 10
    },
    {
        "heLabel": 11,
        "heValue": 11
    },
    {
        "heLabel": 12,
        "heValue": 12
    },
    {
        "heLabel": 13,
        "heValue": 13
    },
    {
        "heLabel": 14,
        "heValue": 14
    },
    {
        "heLabel": 15,
        "heValue": 15
    },
    {
        "heLabel": "16",
        "heValue": "16"
    },
    {
        "heLabel": 17,
        "heValue": 17
    },
    {
        "heLabel": 18,
        "heValue": 18
    },
    {
        "heLabel": 19,
        "heValue": 19
    },
    {
        "heLabel": 20,
        "heValue": 20
    },
    {
        "heLabel": 21,
        "heValue": 21
    },
    {
        "heLabel": 22,
        "heValue": 22
    },
    {
        "heLabel": 23,
        "heValue": 23
    },
    {
        "heLabel": 24,
        "heValue": 24
    }
];

class PlannedOutage extends React.PureComponent {
    svgRef = React.createRef();
    // heDropDownList = React.createRef(); 
    tableRef = React.createRef();
    canadaRowRef = React.createRef();
    fromDate  = React.createRef();
    constructor(props) {
        super(props);
        this.source = heDropDownData;
        this.outagesReportService = new OutagesReportService();
        this.state = {
            visible: true,
            isLoading: true,
            data: [],
            source: new jqx.dataAdapter(this.source)
        }
    }


    async getData(fromDate,he) {
        console.log("get Data",fromDate,he);
        // AESOLoader.singletonRef.show();
        
        this.setState({
            data: [],
            isLoading: true,
        }, () => {
            this.updateOutageIcon(this.state.data);
        });
        this.outagesReportService.retrieveData(fromDate,he)
            .then(items => {
                // AESOLoader.singletonRef.hide();
                this.setState({
                    data: convertOutageData(items.data.return.Outages.Outage),
                    isLoading: false,
                }, () => {
                    this.updateOutageIcon(this.state.data);
                });
            })
            .catch(e => {
                // AESOLoader.singletonRef.hide();
                console.log(e);
                this.setState({
                    data: [],
                    isLoading: false
                });
            });
    }


    setRangeOfDate = () => {
       try {
        var endDate = new Date();
        endDate.setHours(endDate.getHours() + 47);
        var startDate = getCalendarMinDate();
        this.fromDate.current.setMinDate(startDate);
        this.fromDate.current.setMaxDate(endDate);
       } catch (error) {
           console.log("Error in range set", error)
       }
    }

    componentDidMount() {
        try {
            var fromDate = formatDate(new Date());
            var currentHour = ((new Date().getHours())+1);
            this.setRangeOfDate();
            
            this.getData(fromDate,currentHour);

            var ref = ReactDOM.findDOMNode(this.svgRef.current);
            var outageLineRef1 = ref.getElementById('line-ab-matl');
            var lineOutageAlert1 = ref.getElementById('line-outage-alert-1');
            var matlLineStart = ref.getElementById('matlStart');
            var matlLineEnd = ref.getElementById('matlEnd');
            matlLineStart.setAttribute('stroke', aesoMatlBackground);
            matlLineEnd.setAttribute('stroke', aesoMatlBackground);
            outageLineRef1.addEventListener('mouseover', this.hover, false)
            outageLineRef1.addEventListener('mouseout', this.hover, false)
            lineOutageAlert1.addEventListener('mouseover', this.hover, false)
            lineOutageAlert1.addEventListener('mouseout', this.hover, false)
            outageLineRef1.setAttribute('stroke', aesoMatlBackground);

            var outageLineRef2 = ref.getElementById('line-ab-sk');
            
            var skLineStart = ref.getElementById('skStart');
            var skLineEnd = ref.getElementById('skEnd');
            skLineStart.setAttribute('stroke', aesoSkBackground);
            skLineEnd.setAttribute('stroke', aesoSkBackground);
            outageLineRef2.setAttribute('stroke', aesoSkBackground);
            outageLineRef2.addEventListener('mouseover', this.hover, false);
            outageLineRef2.addEventListener('mouseout', this.hover, false);
            var lineOutageAlert3 = ref.getElementById('line-outage-alert-3');
            lineOutageAlert3.addEventListener('mouseover', this.hover, false);
            lineOutageAlert3.addEventListener('mouseout', this.hover, false);
            

            var outageLineRef3 = ref.getElementById('line-ab-bc');
            var bcLineStart = ref.getElementById('bcStart');
            var bcLineEnd = ref.getElementById('bcEnd');
            bcLineStart.setAttribute('stroke', aesoBcBackground);
            bcLineEnd.setAttribute('stroke', aesoBcBackground);
            outageLineRef3.setAttribute('stroke', aesoBcBackground);
            outageLineRef3.addEventListener('mouseover', this.hover, false);
            outageLineRef3.addEventListener('mouseout', this.hover, false);
            var lineOutageAlert2 = ref.getElementById('line-outage-alert-2');
            lineOutageAlert2.addEventListener('mouseover', this.hover, false);
            lineOutageAlert2.addEventListener('mouseout', this.hover, false);


            var outageIconRef4 = ref.getElementById('bc-matl-flowgate-line');
            outageIconRef4.setAttribute('stroke', aesoBcMatlColor);
            outageIconRef4.addEventListener('mouseover', this.hover, false);
            outageIconRef4.addEventListener('mouseout', this.hover, false);

            var lineOutageAlert4 = ref.getElementById('line-outage-alert-4');
            lineOutageAlert4.addEventListener('mouseover', this.hover, false);
            lineOutageAlert4.addEventListener('mouseout', this.hover, false);

            window.addEventListener('resize', this.handleResize);
        
            this.fitMapWithParentDiv();
        } catch (error) {
            console.log("Error in component did mount");
        }

        // this.disablePresentDayHours(currentHour);
    }

    refresh = () => {
        this.setRangeOfDate();
        this.onValueChanged();
        var fromDate = this.fromDate.current.val();
        // var hour = this.heDropDownList.current.val();
        this.getData(fromDate);
    }

    applySearch = () => {
        var fromDate = this.fromDate.current.val();
        // var hour = this.heDropDownList.current.val();
        this.getData(fromDate);
    }

    handleResize = (e) =>{
        try {
            console.log("start handleResize "+ new Date());
            this.fitMapWithParentDiv();
            console.log("end handleResize "+ new Date());
        } catch (error) {
            console.log("Error in Auto Height");
        }
    }

    highlightData = (line, active) => {
        var row = this.tableRef.current.getElementsByTagName('TR');
        row.forEach(element => {
            if (element.getAttribute('data-row') && element.getAttribute('data-row') >= 0) {
                var indexRow = [];
                switch (line) {
                    case 'matl':
                        indexRow = this.state.data.affectedMatlRow;
                        break;
                    case 'bc':
                        indexRow = this.state.data.affectedBCRow;
                        break;
                    case 'sk':
                        indexRow = this.state.data.affectedSKRow;
                        break;
                    case 'bcmatl':
                        indexRow = this.state.data.affectedBCMatlRow;
                        break;
                    default:
                        break;
                }

                if (active) {
                    indexRow.forEach(index => {
                        if (element.getAttribute('data-row') == index) {
                            element.classList.add('highlight');
                        }
                    });
                } else {
                    indexRow.forEach(index => {
                        if (element.getAttribute('data-row') == index) {
                            element.classList.remove('highlight');
                        }
                    });
                }
            }
        });
        console.log(row);
    }

    hover = (e) => {
        try {
            var id = e.target.getAttribute('id');
            console.log(id + " " + e.type);
            switch (id) {
                case 'line-ab-matl':
                case 'line-outage-alert-2':
                case 'line-outage-alert-2-Fill-1':    
                    if ('mouseover' == e.type) {
                        // e.target.setAttribute('stroke', aesoMatlColor)
                        e.target.setAttribute('stroke-width', 6)
                        this.highlightData('matl', true);
                    }
                    if ('mouseout' == e.type) {
                        // e.target.setAttribute('stroke', aesoMatlBackground)
                        e.target.setAttribute('stroke-width', 4)
                        this.highlightData('matl', false);
                    }
                    break;
                case 'line-ab-sk':
                case 'line-outage-alert-3':
                case 'line-outage-alert-3-Fill-1':
                    if ('mouseover' == e.type) {
                        // e.target.setAttribute('stroke', aesoSkColor)
                        e.target.setAttribute('stroke-width', 6)
                        this.highlightData('sk', true);
                    }
                    if ('mouseout' == e.type) {
                        // e.target.setAttribute('stroke', aesoSkBackground)
                        e.target.setAttribute('stroke-width', 4)
                        this.highlightData('sk', false);
                    }
                    break;
                case 'line-ab-bc':
                case 'line-outage-alert-1':
                case 'line-outage-alert-1-fill-1':
                    if ('mouseover' == e.type) {
                        // e.target.setAttribute('stroke', aesoBcColor)
                        e.target.setAttribute('stroke-width', 6)
                        this.highlightData('bc', true);
                    }
                    if ('mouseout' == e.type) {
                        // e.target.setAttribute('stroke', aesoBcBackground)
                        e.target.setAttribute('stroke-width', 4)
                        this.highlightData('bc', false);
                    }
                    break;
                case 'bc-matl-flowgate-line':
                case 'line-outage-alert-4':
                case 'line-outage-alert-4-Fill-1':
                    if ('mouseover' == e.type) {
                        // e.target.setAttribute('stroke', aesoBcMatlBackground)
                        e.target.setAttribute('stroke-width', 6)
                        this.highlightData('bcmatl', true);
                    }
                    if ('mouseout' == e.type) {
                        // e.target.setAttribute('stroke', aesoBcMatlColor)
                        e.target.setAttribute('stroke-width', 4)
                        this.highlightData('bcmatl', false);
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {

        }
    }

    updateOutageIcon = (data) => {
        try {
            var ref = ReactDOM.findDOMNode(this.svgRef.current);
            console.log("ref", ref);
            var outageIconRef1 = ref.getElementById('line-outage-alert-1');
            console.log("outageIconRef1", outageIconRef1);
            addClass(outageIconRef1,'fade');
            console.log("classList", outageIconRef1);
            var outageIconRef2 = ref.getElementById('line-outage-alert-2');
            addClass(outageIconRef2,'fade');
            var outageIconRef3 = ref.getElementById('line-outage-alert-3');
            addClass(outageIconRef3,'fade');
            var outageIconRef4 = ref.getElementById('line-outage-alert-4');
            addClass(outageIconRef4,'fade');
            data.affectedLines.forEach(element => {
                switch (element) {
                    case "MATL":
                        removeClass(outageIconRef2,'fade');
                        break;
                    case "BC":
                        removeClass(outageIconRef1,'fade');
                        break;
                    case "SK":
                        removeClass(outageIconRef3,'fade');
                        break;
                    case "BCMATL":
                        removeClass(outageIconRef4,'fade');
                        break;
                    default:
                        break;
                }
            });
        } catch (error) {
            console.log("Error in updateOutageIcon",error)
        }
    }

    // disablePresentDayHours = (currentHour) => {
    //     var hourStart = 1;
    //     var hourEnd = 24;
    //     while (hourStart <= hourEnd) {
    //         try {
    //             if(hourStart < currentHour){
    //                 this.heDropDownList.current.disableItem(hourStart);
    //             }else {
    //                 this.heDropDownList.current.enableItem(hourStart);
    //             }
    //         }
    //         catch (error) {
    //             console.log("error in dropdown " + hourStart, error);
    //         }
    //         hourStart = hourStart + 1;
    //     }
    //     this.heDropDownList.current.selectIndex(currentHour-1);
    // }

    // disableLastDayHours = (currentHour) => {
    //     var hourStart = 1;
    //     while (hourStart <= 24) {
    //         try {
    //             if(hourStart<=(currentHour-1)){
    //                 this.heDropDownList.current.enableItem(hourStart);
    //             }else{
    //                 this.heDropDownList.current.disableItem(hourStart);
    //             }
                
    //         }
    //         catch (error) {
    //             console.log("error in dropdown " + hourStart, error);
    //         }
    //         hourStart = hourStart + 1;
    //     }
    //     this.heDropDownList.current.selectIndex(0);
    // }

    // enableHours = () => {
    //     var hourStart = 1;
    //     while (hourStart <= 24) {
    //         try {
    //             this.heDropDownList.current.enableItem(hourStart);
    //         } catch (error) {
    //             console.log("error in dropdown " + hourStart, error);
    //         }
    //         hourStart = hourStart + 1;
    //     }
    //     this.heDropDownList.current.selectIndex(0);
    // }

    fitMapWithParentDiv() {
        var ref = ReactDOM.findDOMNode(this.svgRef.current);
        var boundObj = this.canadaRowRef.current.getBoundingClientRect();
        var width = boundObj.width;
        var height = boundObj.height;
        if(width > 0){
            ref.setAttribute("width", width);
            ref.setAttribute("height", width);
            // ref.setAttribute("viewBox", "0 0 "+width+" "+width);
        }
    }

    onValueChanged = () => {
        var fromDateMin = this.fromDate.current.getMinDate();
        var fromDateMax = this.fromDate.current.getMaxDate();
        
        var fromDate = convertDate(this.fromDate.current.val());
        var dateOffset = (24*60*60*1000);
        
        try {
            fromDate = new Date(fromDate.getYear(),fromDate.getMonth(),fromDate.getDate());
            fromDateMin = new Date(fromDateMin.getYear(),fromDateMin.getMonth(),fromDateMin.getDate());
            fromDateMin.setTime(fromDateMin.getTime() + dateOffset);

            fromDateMax = new Date(fromDateMax.getYear(),fromDateMax.getMonth(),fromDateMax.getDate());
            
            
            // var currentHour = ((new Date().getHours())+1);
            // if(compareDate(fromDateMin,fromDate) === -1 && compareDate(fromDateMax,fromDate) === 1){
            //     this.enableHours();
            // } else if(compareDate(fromDateMin,fromDate)===0){
            //     this.disablePresentDayHours(currentHour);
            // } else if(compareDate(fromDateMax,fromDate)===0){
            //     this.disableLastDayHours(currentHour);
            // }
        } catch (error) {
            console.log("Error in date selection",error);
        }
        
    }

    onSubmit = (event) =>{
        event.preventDefault();
        this.applySearch();
    }

    render() {
        return (
            <React.Fragment>
                <SectionTitle title={en_messages.generic.realtime.section4.title} />
                <div className="container map-section ml-0 mr-0 pl-0 pr-0 mt-0 mb-0">

                    <div className="row map-section-form mt-3 ml-0 pt-0 pb-0 mr-0 pl-2 pr-2 ">
                        <form onSubmit={(event) => this.onSubmit(event)}>
                        <div className="col-md-12 ml-0 pt-0 pb-0 mr-0 pl-0 pr-0 ">
                            <div className="row ">
                                <div className="column ml-5">
                                    <span className="float-left select-date">{en_messages.generic.common.input.mapMsg1}</span>
                                    <span className="mt-3 float-left custom-date-picker" style={{ clear: 'both' }}>
                                        <JqxDateTimeInput
                                            width={216}
                                            height={35}
                                            formatString="yyyy-MM-dd"
                                            theme="aeso-date-1"
                                            onValueChanged={this.onValueChanged}
                                            ref={this.fromDate}
                                        /></span>
                                </div>
                                {/*<div className="column ml-5">
                                    <span className="float-left select-date">{en_messages.generic.common.input.mapMsg2}</span>
                                    <span className="mt-3 float-left" style={{ clear: 'both' }}>
                                         <select className="dropdown-menu">
                                            <option class="dropdown-item">1</option>
                                            <option class="dropdown-item">2</option>
                                            <option class="dropdown-item">3</option>
                                            <option class="dropdown-item">4</option>
                                            <option class="dropdown-item">5</option>
                                            <option class="dropdown-item">6</option>
                                            <option class="dropdown-item">7</option>
                                            <option class="dropdown-item">8</option>
                                            <option class="dropdown-item">9</option>
                                            <option class="dropdown-item">10</option>
                                            <option class="dropdown-item">11</option>
                                            <option class="dropdown-item">12</option>
                                            <option class="dropdown-item">13</option>
                                            <option class="dropdown-item">14</option>
                                            <option class="dropdown-item">15</option>
                                            <option class="dropdown-item">16</option>
                                            <option class="dropdown-item">17</option>
                                            <option class="dropdown-item">18</option>
                                            <option class="dropdown-item">19</option>
                                            <option class="dropdown-item">20</option>
                                            <option class="dropdown-item">21</option>
                                            <option class="dropdown-item">22</option>
                                            <option class="dropdown-item">23</option>
                                            <option class="dropdown-item">24</option>
                                        </select> */}
                                         {/* <JqxDropDownList ref={this.heDropDownList} onSelect={this.listOnSelect}
                                                width={60} height={30} source={this.state.source}
                                                displayMember={'heLabel'} valueMember={'heValue'} /> 
                                    </span>
                                </div>*/}
                                <div className="column  align-self-end ml-auto">
                                    
                                    <button  className='ml-5 align-bottom custom-button'>{en_messages.generic.common.btn.msg1}</button>
                                </div>
                            </div>

                        </div>
                        </form>
                     </div>
                     <div className="row  mt-3 ml-0 pt-0 pb-0 mr-0 pl-2 pr-2 ">   
                        <div className="col-md-6 col-sm ml-0 pt-3 mr-0 pl-0 pr-2">
                            {/* <div className="ml-3 mr-3">
                                <span className="real-Time-info-var line-of-outage"></span>
                                <span className="real-Time-info-title">Date: </span><span className="real-Time-info-var-val mr-2"> 28/05/2020</span>
                                <span className="real-Time-info-title">HE: </span><span className="real-Time-info-var-val mr-2">3</span>
                            </div> */}
                            {this.state.isLoading?<EventsLoader></EventsLoader>:
                            (<div className="row mt-0 pt-0 pb-0 ml-0 mr-0 pl-0 pr-0 line-of-outage-table">
                                <table ref={this.tableRef} cellSpacing="0" cellPadding="0" id="realTimeOutageDataTable" width="100%">
                                    <thead>
                                        <tr className="line-of-outage-table-header">
                                            <th width="20%" className="">{en_messages.generic.realtime.section4.tableColName1}</th>
                                            <th width="20%" className="">{en_messages.generic.realtime.section4.tableColName2}</th>
                                            <th width="40%" className="">{en_messages.generic.realtime.section4.tableColName3}</th>
                                            <th width="20%" className="">{en_messages.generic.realtime.section4.tableColName4}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="line-of-outage-table-body">
                                        {
                                            console.log(this.state.data)
                                        }
                                        {

                                            this.state.data && this.state.data.length > 0 ? (
                                                this.state.data.map((eachRow, index) => (
                                                    <tr class="" data-row={index}>
                                                        <td className="">{eachRow.fromInLocalTime?eachRow.fromInLocalTime:"-"}</td>
                                                        <td className="">{eachRow.toInLocalTime?eachRow.toInLocalTime:"-"}</td>
                                                        <td className="">{eachRow.element}</td>
                                                        <td className="">{eachRow.affectedIntertieDisplayValue}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                    <tr>
                                                        <td colSpan="4">{en_messages.generic.realtime.section4.noData}</td>
                                                    </tr>
                                                )}



                                    </tbody>
                                </table>
                            </div>)}
                            <div className="d-flex mt-4 ml-2">
                                <span className="mr-2"><OutageErrorIcon></OutageErrorIcon></span>
                                <span class="legend-style">{en_messages.generic.realtime.section4.legend}</span>
                            </div>
                        </div>
                        <div ref={this.canadaRowRef} className="col-md-6 ml-0 mr-0 pl-0 pr-0 text-right  float-right">
                            <CanadaGridLinesMap ref={this.svgRef} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PlannedOutage;
