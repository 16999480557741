import React from 'react'
import ContentLoader from 'react-content-loader'

const EventsLoader = props => (
  <ContentLoader 
    speed={2}
    width={500}
    height={150}
    viewBox="0 0 500 150"
    backgroundColor="#f2f2f2"
    foregroundColor="#ebbcbc"
    visibility="false"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="500" height="10" /> 
    <rect x="0" y="45" rx="5" ry="5" width="500" height="10" /> 
    <rect x="0" y="75" rx="5" ry="5" width="500" height="10" /> 
    <rect x="0" y="105" rx="5" ry="5" width="500" height="10" />
  </ContentLoader>
)

export default EventsLoader