import React from 'react';
import '../../../assets/scss/components/Footer.scss';
import configuration from '../../../configuration';
import {en_messages} from '../../../constants/Constants';

class Footer extends React.Component {

    render() {
        return (
            <div className="col-md-12  ml-0 mr-0 pl-0 pr-0">
                <div className="row">
                    <div className="container aeso-bc-color" >
                        <div className="row atc-public-footer-1  mt-5 mb-5">
                            <div className="col-md-12">
                                <span>
                                    <p>{en_messages.generic.footer.msg1}</p>
                                    <p>{en_messages.generic.footer.msg2}<a target="_blank" rel="noopener noreferrer" href="http://ets.aeso.ca/outage_reports/Longterm_Critical_Outages.html">{en_messages.generic.footer.msg3}</a>{en_messages.generic.footer.msg4}<a target="_blank" rel="noopener noreferrer" href="http://ets.aeso.ca/outage_reports/qryOpPlanTransmissionTable_1.html">{en_messages.generic.footer.msg5}</a></p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row atc-public-reports-logo">
                    <div className="container" >
                        <div className="row atc-public-footer-2">
                            <div className="col-md-12 mt-5 mb-3">
                                <span>
                                    <p>{en_messages.generic.footer.msg9}</p>
                                    <p >{en_messages.generic.footer.msg10}<a href="mailto:info@aeso.ca">{en_messages.generic.footer.msg11}</a></p>
                                    <p><a target="_blank" rel="noopener noreferrer" href="https://www.aeso.ca">{en_messages.generic.footer.msg12}</a></p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

Footer.defaultProps = {
    label: "" // if we do not pass any label from parent, then it will display default value
}

export default Footer;