import * as React from 'react';
import JqxChart, { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxchart';


const { detect } = require('detect-browser');
const browser = detect();

class LineChart extends React.PureComponent {

    state = {
        padding: {},
        seriesGroups: [],
        source: {},
        valueAxis: {},
        xAxis: {},
        chartData: [],
        isUpdate:false
    }

    constructor(props) {
        super(props);
        this.lineChart = React.createRef();
        this.chartRef = React.createRef();
    }

    componentDidMount = () => {
        // setTimeout(() => {
        //     // this.showHideMATLGraphLines(false, false, false, false);
        //     // this.showHideSKGraphLines(false, false, false, false);
        //     // this.showHideBCMATLGraphLines(false, false, false, false);
        //     // this.showHideSystemGraphLines(false, false, false, false);
        // }, 600)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.source !== this.props.source || prevProps.seriesGroups !==this.props.seriesGroups) {
          //console.log("Child called- "+new Date())
          this.setState({
            padding: this.props.padding,
            seriesGroups: this.props.seriesGroups,
            source: this.props.source.sourceObj,
            valueAxis: this.props.valueAxis,
            xAxis: this.props.xAxis,
            chartData: this.props.source.chartData,
            isUpdate:this.props.isUpdate
        }, ()=>
        {
            //console.log("child updated- "+new Date())
        });
        }
    }

   chartEvent = (event) => {
        if (event.args !== undefined && event.args.elementIndex !== undefined) {
            var item = this.state.chartData[event.args.elementIndex];
            this.props.lineCallback(item);
        }
    }

    hideShowGraphLines = (chkATC, chkGO, chkTTC, chkBC, chkMATL, chkSK,chkBCMATL,chkSystem) => {
        this.showHideBCGraphLines(chkATC, chkGO, chkTTC, chkBC);
        this.showHideMATLGraphLines(chkATC, chkGO, chkTTC, chkMATL);
        this.showHideSKGraphLines(chkATC, chkGO, chkTTC, chkSK);
        this.showHideBCMATLGraphLines(chkATC, chkGO, chkTTC, chkBCMATL);
        this.showHideSystemGraphLines(chkATC, chkGO, chkTTC, chkSystem);
    }

    showHideBCGraphLines = (chkATC, chkGO, chkTTC, chkBC) => {
        if (chkBC === true) {
            // Check ATC
            if (chkATC)
                this.lineChart.current.showSerie(0, 0);
            else // Hide BC ATC
                this.lineChart.current.hideSerie(0, 0);

            // Check GO
            if (chkGO)
                this.lineChart.current.showSerie(0, 1);
            else // Hide BC ATC
                this.lineChart.current.hideSerie(0, 1);

            // Check GO
            if (chkTTC)
                this.lineChart.current.showSerie(0, 2);
            else // Hide BC ATC
                this.lineChart.current.hideSerie(0, 2);
        }
        else {
            this.lineChart.current.hideSerie(0, 0);
            this.lineChart.current.hideSerie(0, 1);
            this.lineChart.current.hideSerie(0, 2);
        }
    }

    showHideMATLGraphLines = (chkATC, chkGO, chkTTC, chkMATL) => {
        if (chkMATL) {
            // Check ATC
            if (chkATC)
                this.lineChart.current.showSerie(1, 0);
            else // Hide BC ATC
                this.lineChart.current.hideSerie(1, 0);

            // Check GO
            if (chkGO)
                this.lineChart.current.showSerie(1, 1);
            else // Hide GO
                this.lineChart.current.hideSerie(1, 1);

            // Check TTC
            if (chkTTC)
                this.lineChart.current.showSerie(1, 2);
            else // Hide TTC
                this.lineChart.current.hideSerie(1, 2);
        }
        else {
            this.lineChart.current.hideSerie(1, 0);
            this.lineChart.current.hideSerie(1, 1);
            this.lineChart.current.hideSerie(1, 2);
        }
    }

    showHideSKGraphLines = (chkATC, chkGO, chkTTC, chkSK) => {
        if (chkSK) {
            // Check ATC
            if (chkATC)
                this.lineChart.current.showSerie(2, 0);
            else // Hide BC ATC
                this.lineChart.current.hideSerie(2, 0);

            // Check GO
            if (chkGO)
                this.lineChart.current.showSerie(2, 1);
            else // Hide GO
                this.lineChart.current.hideSerie(2, 1);

            // Check TTC
            if (chkTTC)
                this.lineChart.current.showSerie(2, 2);
            else // Hide TTC
                this.lineChart.current.hideSerie(2, 2);
        }
        else {
            this.lineChart.current.hideSerie(2, 0);
            this.lineChart.current.hideSerie(2, 1);
            this.lineChart.current.hideSerie(2, 2);
        }
    }

    showHideBCMATLGraphLines = (chkATC, chkGO, chkTTC, chkBCMATL) => {
        if (chkBCMATL) {
            // Check ATC
            if (chkATC)
                this.lineChart.current.showSerie(3, 0);
            else // Hide BC ATC
                this.lineChart.current.hideSerie(3, 0);

            // Check GO
            if (chkGO)
                this.lineChart.current.showSerie(3, 1);
            else // Hide GO
                this.lineChart.current.hideSerie(3, 1);

            // Check TTC
            if (chkTTC)
                this.lineChart.current.showSerie(3, 2);
            else // Hide TTC
                this.lineChart.current.hideSerie(3, 2);
        }
        else {
            this.lineChart.current.hideSerie(3, 0);
            this.lineChart.current.hideSerie(3, 1);
            this.lineChart.current.hideSerie(3, 2);
        }
    }

    showHideSystemGraphLines = (chkATC, chkGO, chkTTC, chkSystem) => {
        if (chkSystem) {
            // Check ATC
            if (chkATC)
                this.lineChart.current.showSerie(4, 0);
            else // Hide BC ATC
                this.lineChart.current.hideSerie(4, 0);

            // Check GO
            if (chkGO)
                this.lineChart.current.showSerie(4, 1);
            else // Hide GO
                this.lineChart.current.hideSerie(4, 1);

            // Check TTC
            if (chkTTC)
                this.lineChart.current.showSerie(4, 2);
            else // Hide TTC
                this.lineChart.current.hideSerie(4, 2);
        }
        else {
            this.lineChart.current.hideSerie(4, 0);
            this.lineChart.current.hideSerie(4, 1);
            this.lineChart.current.hideSerie(4, 2);
        }
    }

    showHideGrossOfferLines=(isShow,chkBC,chkMATL,chkSK ,chkBCMATL,chkSystem)=>
    {
        if(isShow)
        {
            if (chkBC)
            this.lineChart.current.showSerie(0, 1);

            if (chkMATL)
            this.lineChart.current.showSerie(1, 1);

            if (chkSK)
            this.lineChart.current.showSerie(2, 1);

            if (chkBCMATL)
            this.lineChart.current.showSerie(3, 1);

            if (chkSystem)
            this.lineChart.current.showSerie(4, 1);
        }
        else
        {
            this.lineChart.current.hideSerie(0, 1);
            this.lineChart.current.hideSerie(1, 1);
            this.lineChart.current.hideSerie(2, 1);
            this.lineChart.current.hideSerie(3, 1);
            this.lineChart.current.hideSerie(4, 1);

        }

    }

    showHideATCLines=(isShow,chkBC,chkMATL,chkSK ,chkBCMATL,chkSystem)=>
    {
        if(isShow)
        {
            if (chkBC)
            this.lineChart.current.showSerie(0, 0);

            if (chkMATL)
            this.lineChart.current.showSerie(1, 0);

            if (chkSK)
            this.lineChart.current.showSerie(2, 0);

            if (chkBCMATL)
            this.lineChart.current.showSerie(3, 0);

            if (chkSystem)
            this.lineChart.current.showSerie(4, 0);
        }
        else
        {
            this.lineChart.current.hideSerie(0, 0);
            this.lineChart.current.hideSerie(1, 0);
            this.lineChart.current.hideSerie(2, 0);
            this.lineChart.current.hideSerie(3, 0);
            this.lineChart.current.hideSerie(4, 0);
        }

    }

    showHideTTCLines=(isShow,chkBC,chkMATL,chkSK ,chkBCMATL,chkSystem)=>
    {
        if(isShow)
        {
            if (chkBC)
            this.lineChart.current.showSerie(0, 2);

            if (chkMATL)
            this.lineChart.current.showSerie(1, 2);

            if (chkSK)
            this.lineChart.current.showSerie(2, 2);

            if (chkBCMATL)
            this.lineChart.current.showSerie(3, 2);

            if (chkSystem)
            this.lineChart.current.showSerie(4, 2);
        }
        else
        {
            this.lineChart.current.hideSerie(0, 2);
            this.lineChart.current.hideSerie(1, 2);
            this.lineChart.current.hideSerie(2, 2);
            this.lineChart.current.hideSerie(3, 2);
            this.lineChart.current.hideSerie(4, 2);
        }

    }

    render() {
        var cusWidth = { width: '90%', height: '500px' };
        jqx.credits = "75CE8878-FCD1-4EC7-9249-BA0F153A5DE8";
        return (
            <div>
                <JqxChart
                    ref={this.lineChart} showToolTips={false}
                    enableCrosshairs={true}
                    crosshairsColor={'#949494'}
                    crosshairsLineWidth={2}
                    enableAnimations={true}
                    columnSeriesOverlap={false}
                    crosshairsDashStyle={browser.name === 'chrome' ? '0,0' : '.1,.1'}
                    style={cusWidth}
                    title={this.state.title}
                    description={this.state.description}
                    showLegend={false}
                    padding={this.state.padding}
                    titlePadding={this.state.titlePadding}
                    source={this.state.source}
                    xAxis={this.state.xAxis}
                    valueAxis={this.state.valueAxis}
                    seriesGroups={this.state.seriesGroups}
                    colorScheme={'scheme04'}
                    onClick={this.chartEvent}
                    showBorderLine={false}
                />
            </div>
        );
    }
}

LineChart.defaultProps = {
    label: "" // if we do not pass any label from parent, then it will display default value
}

export default LineChart;