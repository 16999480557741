import * as constants from './Constants';

export const From_Date_Required = constants.en_messages.err.fromDateRequired;//"From date is required"
export const From_Date_Before_To_Date = constants.en_messages.err.fromDateBeforeToDate;//"From date must be before the To date"
export const From_Date_Today_Or_Before_Date = constants.en_messages.err.fromDateTodayOrBeforeDate;//"From date must be today or before today"
export const To_Date_Required = constants.en_messages.err.toDateRequired;//"To date is required"
export const To_Date_Today_Or_Before_Date = constants.en_messages.err.toDateTodayOrBeforeDate;//"To date must be today or before today"
export const From_Date_Today_Or_After_Date = constants.en_messages.err.fromDateTodayOrAfterDate;//"From date must be today or after today"
export const To_Date_Today_Or_After_Date = constants.en_messages.err.toDateTodayOrAfterDate;//"To date must be today or after today"
export const Date_Range_Too_Large=constants.en_messages.err.dateRangeTooLarge;//"Date range ({0}) is too large for report, maximum range allowed for HTML is {1} days. Please break up your request into smaller ranges"
export const Forecast_Date_Range_Too_Large=constants.en_messages.err.forecastDateRangeTooLarge;//"Only {0} months of data can be retrieved at a time, please enter a smaller date range"
export const Forecast_Max_Date= constants.en_messages.err.forecastMaxDate;//"There is no data after {0}"

