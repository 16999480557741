import Configuration from '../configuration';
import http from "./http-client";

class OutagesReportService {

  dummyData=
  {
    "data":
    {
      "timestamp": "2020-11-03 08:19:17.654+0000",
      "message": "Successfully retrieved Outages.",
      "responseCode": "200",
      "return": {
        "Outages": {
          "Outage": [
            {
              "element": "1201L outage",
              "affectedIntertieOrFlowgate": [
                {
                  "displayValue": "MATL",
                  "affectedLines": [
                    "MATL"
                  ]
                },
                {
                  "displayValue": "BC",
                  "affectedLines": [
                    "BC"
                  ]
                },
                {
                  "displayValue": "BC/MATL",
                  "affectedLines": [
                    "BC",
                    "MATL",
                    "BC_MATL"
                  ]
                }
              ],
              "toInLocalTime": "2020-11-02 23:30",
              "fromInLocalTime": "2020-11-02 22:34"
            }
          ]
        }
      }
    }
}

retrieveData(fromDate,he) {
    if(Configuration.IS_DUMMY_DATA)
    {
      var data = Promise.resolve(this.dummyData);
      return  data.then(function(value) {
           return value;
      });
    } else
    {
      return http.get("outage/",{"params":{
        "he":he,
        "startDate":fromDate
      }});
    }
  }
}

export default OutagesReportService;