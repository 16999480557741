import React from 'react';
import '../../../assets/scss/components/Header.scss';

class Header extends React.Component {

    render() {
        return (
            <div className="container ml-0 mr-0 pl-0 pr-0" >
                
                <div className="row atc-public-reports-logo">
                    <div className="d-flex flex-wrap align-items-center ml-0 mr-0 pl-0 pr-0 col-md-12 mt-3 mb-3">

                        <div className="row" style={{width:'100%'}}> 
                                <div className="col-md-12 pl-0 pr-0">
                                    <img alt="..." src={require("../../../assets/img/aeso_itc_logo.png")} />
                                </div>
                                <div className="col-md-12 mt-3 ml-0 mr-0 pl-0 pr-0" style={{minWidth:'250px'}}>
                                    <span className="atc-public-reports-title-title">{this.props.label}</span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Header.defaultProps = {
    label: "" // if we do not pass any label from parent, then it will display default value
}

export default Header;