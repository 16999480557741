import * as React from 'react';
import '../../../assets/scss/components/Card.scss';
import {en_messages} from '../../../constants/Constants';

class Card extends React.PureComponent {
    state = {
        import: 0,
        export: 0,
        title: '',
        cls: ''
    }

    componentDidMount = () => {
        this.setState({
            import: this.props.import,
            export: this.props.export,
            title: this.props.title,
            cls: this.props.cls
        });
    }

    componentDidUpdate() {
        this.setState({
            import: this.props.import,
            export: this.props.export,
            title: this.props.title,
            cls: this.props.cls
        });
    }

    render() {
        return (
            <div className={`box ${this.state.cls}`}>
                <div className="pannel-left "><span className={`atc-val`}>{this.state.import}</span><br />
                    <span className="label">{en_messages.generic.common.import}</span>
                </div>
                <div className="pannel-right"><span className={`atc-val`}>{this.state.export}</span><br />
                    <span className="label">{en_messages.generic.common.export}</span>
                </div>
                <div className="title title-color">{this.state.title}</div>
            </div>
        );
    }
}


Card.defaultProps = {
    import: 0,
    export: 0,
    title: 'Title',
    cls: ''

}

export default Card;