import React from 'react';
import '../../../assets/scss/components/Header.scss';
import {en_messages} from '../../../constants/Constants';
class NotificationBar extends React.Component {

    render() {
        return (
            <div className="container ml-0 mr-0 pl-0 pr-0">
            <div className="row  ml-0 mr-0 pl-0 pr-0">
                <div className="d-flex flex-wrap align-items-center ml-0 mr-0 pl-0 pr-0 col-md-12 mt-2 mb-2">
                    <div className="row justify-content-center align-self-center" style={{width:'100%'}}>
                        <div className="col-md-12 pl-0 pr-0 justify-content-center align-self-center">
                            <span>
                                {this.props.renderMessage(this.props.label)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

NotificationBar.defaultProps = {
    label: en_messages.generic.notificationBar,
    renderMessage:(labelObj) =>{
        return (
            <p>
                {labelObj.msg1} 
                <a target="_blank" rel="noopener noreferrer" href="https://www.surveymonkey.com/r/DNN9P6W">{labelObj.msg2}</a> 
                {labelObj.msg3}
                <a href="mailto:info@aeso.ca">{labelObj.msg4}</a>
                {labelObj.msg5}
            </p>
        )
    }
}

export default NotificationBar;