import * as React from 'react';
import Header from '../../components/common/header/Header';
import NotificationBar from '../../components/common/notification/NotificationBar';
import Footer from '../../components/common/footer/Footer';
import Loader from '../../components/common/loader/Loader';
import RealTimeReport from '../report/RealTimeReport';
import HistoricReport from '../report/HistoricReport';
import ForecastReport from '../report/ForecastReport';
import BrokenPage from '../fallback/BrokenPage';
import JqxTabs, { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtabs';
import '../../assets/scss/view/MainLayout.scss';
import configuration from '../../configuration';
import {en_messages} from '../../constants/Constants';

class MainLayout extends React.Component {
    
    
    constructor(props,context) {
        super(props,context);
        this.tabs = React.createRef();
        this.realTimeReportRef = React.createRef();
        this.historicReportRef = React.createRef();
        this.forecastReportRef = React.createRef();

        this.state = {
            source: {},
            selectedTab: this.props.selectedTab,
            grid: this.props.grid
        }
    }

    componentDidMount() {
        try{
            this.tabs.current.select(this.state.selectedTab);
        } catch(error){
            console.log("Error in select Tab", error);
        }
    }

    onSelected = (e) => {
        switch (e.args.item) {
            case 0:
                this.realTimeReportRef.current.onShow(e)
                //this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/realtime/");
                break;
            case 1:
                this.historicReportRef.current.onShow(e)
                //this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/historic/");
                break;
            case 2:
                this.forecastReportRef.current.onShow(e)
                //this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/forecast/");
                break;
            default:
                break;
        }
    }

    onTabclick = (e) => {
        switch (e.args.item) {
            case 0:
                this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/realtime/");
                break;
            case 1:
                this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/historic/");
                break;
            case 2:
                this.props.history.push(configuration.API_CONTEXT_ROOT +"/atc/forecast/");
                break;
            default:
                break;
        }
    }

    renderNotificationMessage= (labelObj) =>{
        return (
            <p>
                {labelObj.msg1} 
                <a target="_blank" rel="noopener noreferrer" href="https://www.surveymonkey.com/r/DNN9P6W">{labelObj.msg2}</a> 
                {labelObj.msg3}
                <a href="mailto:info@aeso.ca">{labelObj.msg4}</a>
                {labelObj.msg5}
            </p>
        )
    }

    render() {
        jqx.credits = "75CE8878-FCD1-4EC7-9249-BA0F153A5DE8";
        return (
            <div className="container aeso-body ml-0 mr-0 pl-0 pr-0">
                <div id="header-fixed"></div>
                <div className="row ml-0 mr-0 pl-0 pr-0">
                    <Loader/>    
                    <React.Fragment>
                        <div className="col-md-12 atc-public-reports-logo ml-0 mr-0 pl-0 pr-0">
                            <div className="row justify-content-center align-items-center">
                                <Header label={en_messages.generic.itcTitle} />
                            </div>
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                        <div className="col-md-12 notification-bar ml-0 mr-0 pl-0 pr-0">
                            <div className="row justify-content-center align-items-center">
                                <NotificationBar label={en_messages.generic.notificationBar} renderMessage={this.renderNotificationMessage} />
                            </div>
                        </div>
                    </React.Fragment>
                    <React.Fragment>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="container pl-0 pr-0">
                                    <JqxTabs
                                        ref={this.tabs}
                                        enabledHover={true}
                                        theme="light"
                                        width={'100%'}
                                        position={"top"}
                                        onTabclick={this.onTabclick}
                                        
                                        onSelected={this.onSelected}
                                        collapsible={false}>
                                        <ul>
                                            <li>{en_messages.generic.tabs.title1}</li>
                                            <li>{en_messages.generic.tabs.title2}</li>
                                            <li>{en_messages.generic.tabs.title3}</li>
                                            <li style={{ display: 'none' }}>{en_messages.generic.tabs.title4}</li>
                                        </ul>
                                        <div className="col-md-12 ml-0 mr-0 pl-0 pr-0 aeso-bc-color">
                                            <RealTimeReport {...this.props} tabRef={this.tabs} ref={this.realTimeReportRef} grid={this.state.grid}></RealTimeReport>
                                        </div>
                                        <div>
                                            <HistoricReport ref={this.historicReportRef} />
                                        </div>
                                        <div>
                                            <ForecastReport ref={this.forecastReportRef} />
                                        </div>
                                        <div>
                                            <BrokenPage ref={this.forecastReportRef} />
                                        </div>
                                    </JqxTabs>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>



                    <React.Fragment>
                        <Footer/>
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

export default  MainLayout;