import React from 'react';
import parse from 'html-react-parser';
import * as expMsg from '../../constants/ExceptionMessages';
import ForecastReportService from '../../apis/ForecastReportService';
import configuration from '../../configuration';
import {handleItcApiError, groupBy, convertApiObjectFormat, compareDate, getGridDefaultSource, getGridColumnGroups, getGridColumns, getGridDataFeilds,convertDateToValidationFormat,checkMonth,validateHourRange,convertToCSVDataForecastReport,downloadCSV,createCSVRows ,getDatesDifference,getCalendarMinDate } from '../../utilities/HelperMethods';
import Grid from '../../components/common/grid/Grid';
import JqxDateTimeInput, { jqx }from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdatetimeinput';
import * as constants from '../../constants/Constants';
import moment from 'moment';
import timezone from 'moment-timezone'; 
import JqxMaskedInput from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxmaskedinput';
import AESOLoader from '../../components/common/loader/Loader';

class ForecastReport extends React.Component {
    constructor(props) {
        super(props);
        this.forecastReportService = new ForecastReportService();
        this.fromDate=React.createRef();
        this.toDate=React.createRef();
        this.hourEndingRange=React.createRef();
        this.collpaseButton=React.createRef();
        this.expandButton=React.createRef();
        this.downloadButton = React.createRef();

        this.state = {
            columngroups: getGridColumnGroups(),
            columns: getGridColumns(),
            source: {},
            groups: ['Date', 'Hour'],
            errorMesage:'',
            hourRange:'0124',
            hourRangeDifference:1,
            dataLoaded:false,
            csvData : []      
        }
    }

    componentDidMount() {
        const data =  getGridDefaultSource();
        this.setState({ source: data });
        this.setOrUpdateBtnState(data);
        window.addEventListener('resize', this.handleResizeEvent);
    }

    handleResizeEvent=()=>{
        if(this.refs.childgridForecast!==undefined){
            this.setState({ source: this.state.source });
            this.refs.childgridForecast.handleResize();
            this.refs.childgridForecast.expandFirstRow();
        }
    }

    retrieveData=()=>
    {
        var hourRangeVar = "";
        if(this.hourEndingRange.current.val() === "  -  "){
            hourRangeVar = "01-24";
        }else{
            hourRangeVar = this.hourEndingRange.current.val();
        }

        var fromDate=this.fromDate.current.val();
        var toDate=this.toDate.current.val();
        var daysDifference = getDatesDifference(fromDate,toDate);
        var errorMessage = this.validateDate(fromDate,toDate);
         errorMessage+=validateHourRange(hourRangeVar);
        this.setState({errorMesage:errorMessage,
                       hourRange:hourRangeVar !== undefined?this.replaceString('-','',hourRangeVar):"",
                       source: getGridDefaultSource(),
                       dataLoaded: false,
                       csvData:[]
        },() => {
            if(errorMessage==='')
            {
                this.getGridItems(fromDate,toDate,daysDifference+1);
            }
            else{
                  this.resetButtons();
            }
        })
    }

    replaceString(oldS, newS, fullS) {
        return fullS.split(oldS).join(newS)
    }

    validateDate=(fromDate,toDate)=>
    {
        var errorMessage="<ul class='validation-message-section'>";
        var isError=false;
        var today=new Date();
        var todayDate=today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2)+'-'+("0" + today.getDate()).slice(-2);
        
        //From date is mandatory
        if(fromDate==='')
        {
            isError=true;
            errorMessage+= "<li>"+expMsg.From_Date_Required+'</li>';
        }
        //check from date is smaller than to date
        if(compareDate(fromDate,toDate)===1)
        {
            isError=true;
            errorMessage+= "<li>"+expMsg.From_Date_Before_To_Date+'</li>';
        }

        //check From date must be today or after
        if(compareDate(fromDate,todayDate)===-1)
        {
            isError=true;
            errorMessage+= "<li>"+expMsg.From_Date_Today_Or_After_Date+'</li>';
        }

        //To date is mandatory
        if(toDate==='')
        {
            isError=true;
            errorMessage+= "<li>"+expMsg.To_Date_Required+'</li>';
        }
        //check To date must be today or after
        if(compareDate(toDate,todayDate)===-1)
        {
            isError=true;
            errorMessage+= "<li>"+expMsg.To_Date_Today_Or_After_Date+'</li>';
        }

         //check To date max value
        //  var maxDate=new Date().setMonth( new Date().getMonth() + configuration.FORECAST_REPORT_MAX_MONTHS);
        //  if(compareDate(toDate,maxDate)===1)
        //  {
        //      isError=true;
        //      errorMessage+= "<li>"+expMsg.Forecast_Max_Date.replace("{0}",convertDateToValidationFormat(maxDate))+'</li>';
        //  }

        //  // check From Date and To date range difference
        // var difference = checkMonth(fromDate,toDate,configuration.FORECAST_REPORT_MAX_MONTHS);
        // if(!difference)
        // {
        //     isError = true;
        //     errorMessage += "<li>" + expMsg.Forecast_Date_Range_Too_Large.replace("{0}",configuration.FORECAST_REPORT_MAX_MONTHS ) + '</li>';
        // }
 
        errorMessage+="</ul>"
        return isError ? errorMessage:'';
    }

    getGridItems(fromDate,toDate,pageSize) {
        AESOLoader.singletonRef.show();
        this.forecastReportService.retrieveData(fromDate,toDate,pageSize)
        .then(items => {
            var responseData= convertApiObjectFormat(items);
            var data = {}
            if(responseData.length>0)
            {
                var converteddata = this.filterResponseData(responseData);
                var groups = groupBy(converteddata,'date');
                var result = Object.entries(groups);
                if(result.length===0)
                {
                    result.add({'0':"No Records"})
                }
                data = {
                    dataFields: getGridDataFeilds(),
                    datatype: 'json',
                    localdata: result
                }
            }
            else{
                data = {
                    datatype: 'json',
                    localdata: []
                }
            }
            this.setState({ source: data,
                            hourRangeDifference:3,
                            dataLoaded:true,
                            csvData:converteddata
            });
             //Expand first row bydefault
            this.refs.childgridForecast.goToPage(0);
            this.refs.childgridForecast.expandFirstRow();
                this.refs.childgridForecast.handleResize();
            // this.expandButton.current.style.display = "none";
            // this.collpaseButton.current.style.display = "";
            AESOLoader.singletonRef.hide();
                })
          .catch(e => {
                this.setState({errorMesage: handleItcApiError(e)});
                this.resetButtons();
                AESOLoader.singletonRef.hide();
            });
    }
    

    resetButtons()
    {
        this.downloadButton.current.classList.add("disable");
        this.expandButton.current.classList.add("disable");
        this.collpaseButton.current.classList.add("disable");
        this.expandButton.current.style.display = "none";
        this.collpaseButton.current.style.display = "";
    }

    setOrUpdateBtnState(data) {
        if (data !== null && data !== undefined && data.localdata !== undefined && data.localdata !== null && data.localdata.length > 0) {
            this.downloadButton.current.classList.remove("disable");
            this.expandButton.current.classList.remove("disable");
            this.collpaseButton.current.classList.remove("disable");
        }
    }

    onShow = (e) => {
        console.log("Called F");
    }

    filterResponseData=(data)=>
    {
      var hourRage = this.hourEndingRange.current.val();
      if(hourRage==='' || hourRage===undefined)
      { 
        return data;
      }
      else
      {
            var hours = hourRage.split("-");
            var startHour = hours[0].trim();
            var endHour = hours[1].trim();
            var filterData = data.filter(function (el) {
            return parseInt(el.hourEnding) >= startHour &&
            parseInt(el.hourEnding) <=  endHour ;
            });
            return filterData;
      }
    }

    handleHourRangeChange=(e)=>
    {
        const re = /(^$)|(^[0-9-\b]+$)/;
        if (e.target.value.search(re) === -1)
                { }
            else
                {  this.setState({hourRange: e.target.value}) }
    }

   
    collapseRows=(e)=>{
        if(this.state.dataLoaded)
        {
       this.refs.childgridForecast.hideRowDetails();
       this.collpaseButton.current.style.display="none";
       this.expandButton.current.style.display="";
        }
    }

    onRowShowhide=(expandAll)=>{
        if(expandAll){
            this.expandButton.current.style.display="none";
            this.collpaseButton.current.style.display="";
        }else{
            this.collpaseButton.current.style.display="none";
            this.expandButton.current.style.display="";
        }
    }

    expandRows=(e)=>{
        this.refs.childgridForecast.showRowDetails();
        this.expandButton.current.style.display="none";
        this.collpaseButton.current.style.display="";
    }

     downloadCSV=()=>{
         var data= this.state.csvData;
         if(data!==undefined && data!==null && data.length>0)
         {
            var csvData=convertToCSVDataForecastReport(data) ;
            var reportTime=moment().tz("Canada/Mountain").format("YYYY-MM-DD HH:mm:ss");
            var title="Forward Looking Intertie Capability Report As Of: "+reportTime+" MDT";
            var csvRows=createCSVRows(csvData,title);
            var csvString = csvRows.join('\n');
            downloadCSV(csvString,constants.ForecastReportFileName);
         }
    }

      gridCallback = () => {
        this.collpaseButton.current.style.display = "none";
            this.expandButton.current.style.display = "";
    }

    onSubmit = (event) =>{
        event.preventDefault();
        this.retrieveData();
    }

    render() {
        this.setOrUpdateBtnState(this.state.source);
        return (
            <React.Fragment>
                <div className="container pl-0 pr-0 ml-0 mr-0 slide-in">
                    <form onSubmit={(event) => this.onSubmit(event)}>
                    <div className="row ml-0 mr-0">
                            <div className="col-md-6 ml-0 mr-0 mt-5 mb-2 pl-0 pr-0">
                                <div className="row">
                                    <h6 className="col-md-12 pl-0 ml-0 label-color">
                                    {constants.en_messages.generic.common.input.msg1}
                                    </h6>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6 pl-0 ml-0">
                                        <span className="sub-title-text mt-2">{constants.en_messages.generic.common.input.from}{constants.en_messages.generic.common.input.requiredChar}</span>
                                        <span className="mt-2 float-left w-100">
                                        <JqxDateTimeInput
                                             ref={this.fromDate}
                                             width={'100%'}
                                             height={35}
                                             formatString="yyyy-MM-dd"
                                             theme="aeso-date"
                                             min={getCalendarMinDate()}
                                         /></span>
                                    </div>
                                    <div className="col-md-6 pl-0 ml-0">
                                        <span className="sub-title-text">{constants.en_messages.generic.common.input.to}{constants.en_messages.generic.common.input.requiredChar}</span>
                                        <span className="mt-2 float-left w-100">
                                        <JqxDateTimeInput
                                             ref={this.toDate}
                                             width={'100%'}
                                             height={35}
                                             formatString="yyyy-MM-dd"
                                             theme="aeso-date"
                                             min={getCalendarMinDate()}
                                         /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 ml-0 mr-0 mt-5 mb-2 pl-0 pr-0">
                                <div className="row">
                                    <h6 className="col-md-12 pl-0 ml-0 label-color">
                                    {constants.en_messages.generic.common.input.msg2}
                                    </h6>
                                </div>
                                <div className="row  mt-2">
                                    <div className="col-md-3 pl-0 ml-0">
                                    <span className="sub-title-text">&nbsp;</span>
                                        <span className="mt-2 float-left w-100">
                                        <JqxMaskedInput className="custom-hour-picker" ref={this.hourEndingRange}  style={{ marginTop: '3px' }} onChange={this.change}
                                                width={'100%'} height={32} promptChar={' '} mask={'##-##'}  value={this.state.hourRange}/>
                                        </span>
                                    </div>
                                    <div className="col-md-6 pl-0 ml-0 mt-2">
                                      <button className='mt-3 ml-3 custom-button' onClick={(e)=>this.retrieveData()}>Apply</button>
                                    </div>
                                </div>
                            </div>
                    </div>
                    </form>
                    <div className="row ml-0 mr-0 ">
                          <div className="col-md-6 ml-0 mr-0 pl-0 pr-0 errorMessage-text">
                          {parse(this.state.errorMesage)}
                          </div>
                          <div className="col-md-6 ml-0 mr-0 pl-0 pr-0 sub-description">
                          {constants.en_messages.generic.common.input.msg3}
                          </div>
                    </div>
                    <div className="row ml-0 mr-0 mt-3" style={{borderTop:'0.5px solid rgba(102,102,102,0.6)'}}>
                     <div className="col-md-7 ml-0 mr-0 mt-4 pl-0 pr-0 float-left sub-description">
                        {constants.en_messages.generic.forecast.msg1}
                     </div>
                     <div className="col-md-5 ml-0 mr-0 mt-3 pl-0 pr-0 float-right">
                        <button ref={this.collpaseButton} className='float-right ml-4 seconday-button disable' onClick={(e)=>this.collapseRows(e)}>
                           <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg3}</span>
                        </button>
                        <button ref={this.expandButton} style={{display:'none'}} className='float-right ml-4 seconday-button disable' onClick={(e)=>this.expandRows(e)}>
                         <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg4}</span>
                        </button>
                        <button title="Apply and Download displayed data." ref={this.downloadButton} className='seconday-button float-right disable' onClick={()=>{this.downloadCSV()}}>
                            <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg2}</span></button>
                     </div>
                    </div>
                   
                </div>
                <div className="mt-4">
                     <Grid  
                     ref="childgridForecast"
                     theme="aeso-grid" 
                     columngroups={this.state.columngroups}
                     columns={this.state.columns}
                     source={new jqx.dataAdapter(this.state.source)}
                     cellsrenderer={this.cellsrenderer}
                     groups={this.state.groups}
                     pageable={true}
                     width={'99.46%'}
                     rowdetails={true}
                     rowDetailItemHeight={26}
                     isForecastReport={true}
                     onRowShowhide={this.onRowShowhide}
                     height={null}
                     gridCallback={this.gridCallback}
                />
                </div>

            </React.Fragment>
        );
    }
}

export default ForecastReport;