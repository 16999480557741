import Configuration from '../configuration';
import {get} from "./http-client";
import {convertDateToApiFormat} from '../utilities/HelperMethods';


class HistoricalReportService {

  dummyData=
  {
    "data":
    {
      "timestamp": "2020-11-03 08:23:28.311+0000",
      "message": "Report successfully retrieved.",
      "responseCode": "200",
      "return": {
        "BcIntertie": {
          "Allocations": [
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "1",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "2",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "3",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "4",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "5",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "6",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "7",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "8",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "9",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "10",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "11",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "12",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "13",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "14",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "15",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "16",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "17",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "18",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "19",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "20",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "Bala Testing Version 1 for HE 08.\nBala Testing Version 2 for HE 08 for BC export",
                "atc": 948,
                "trmTotal": 52,
                "ttc": 1000,
                "trmSystem": 52,
                "trmAllocation": 0,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 950,
                    "trmTotal": 50,
                    "ttc": 1000,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  },
                  {
                    "reason": "Bala Testing Version 1 for HE 08.",
                    "atc": 949,
                    "trmTotal": 51,
                    "ttc": 1000,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 20:29:11 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "21",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 950,
                    "trmTotal": 50,
                    "ttc": 1000,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  },
                  {
                    "reason": "",
                    "atc": 950,
                    "trmTotal": 50,
                    "ttc": 1000,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 20:29:11 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "22",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 20:29:11 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "23",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "1201L outage",
                "atc": 0,
                "trmTotal": 65,
                "ttc": 65,
                "trmSystem": 65,
                "trmAllocation": 0,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 750,
                    "trmTotal": 50,
                    "ttc": 800,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "1201L outage",
                "atc": 40,
                "trmTotal": 65,
                "ttc": 105,
                "trmSystem": 65,
                "trmAllocation": 0,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 950,
                    "trmTotal": 50,
                    "ttc": 1000,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "24",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "1",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "2",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "3",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "4",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "5",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "6",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "7",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "8",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "9",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "10",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "11",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "12",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "13",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "14",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "15",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "16",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "17",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "18",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "19",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "20",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "21",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "22",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "23",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "24",
              "import": {
                "transferType": "BC_IMPORT",
                "reason": "",
                "atc": 750,
                "trmTotal": 50,
                "ttc": 800,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              },
              "export": {
                "transferType": "BC_EXPORT",
                "reason": "",
                "atc": 950,
                "trmTotal": 50,
                "ttc": 1000,
                "trmSystem": 50,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              }
            }
          ]
        },
        "MatlIntertie": {
          "Allocations": [
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "1",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "2",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "3",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "4",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "5",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "6",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "7",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "8",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "9",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "10",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "11",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "12",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "13",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "14",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "15",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "16",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "17",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "18",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "19",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "20",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "21",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "22",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "23",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "1201L outage",
                "atc": 0,
                "trmTotal": 0,
                "ttc": 0,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 295,
                    "trmTotal": 15,
                    "ttc": 310,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "1201L outage",
                "atc": 0,
                "trmTotal": 0,
                "ttc": 0,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 300,
                    "trmTotal": 15,
                    "ttc": 315,
                    "trmAllocation": 0,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "24",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "1",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "2",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "3",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "4",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "5",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "6",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "7",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "8",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "9",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "10",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "11",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "12",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "13",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "14",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "15",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "16",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "17",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "18",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "19",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "20",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "21",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "22",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "23",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "24",
              "import": {
                "transferType": "MATL_IMPORT",
                "reason": "",
                "atc": 295,
                "trmTotal": 15,
                "ttc": 310,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              },
              "export": {
                "transferType": "MATL_EXPORT",
                "reason": "",
                "atc": 300,
                "trmTotal": 15,
                "ttc": 315,
                "trmSystem": 15,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              }
            }
          ]
        },
        "SkIntertie": {
          "Allocations": [
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "1",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "2",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "3",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "4",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "5",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "6",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "7",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "8",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "9",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "10",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "11",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "12",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "13",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "14",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "15",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "16",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "17",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "18",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "19",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "20",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "21",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "22",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "23",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-02",
              "he": "24",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "1",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "2",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "3",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "4",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "5",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "6",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "7",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "8",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "9",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "10",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "11",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "12",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "13",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "14",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "15",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "16",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "17",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "18",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "19",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "20",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "21",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "22",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "23",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": false,
              "date": "2020-11-03",
              "he": "24",
              "import": {
                "transferType": "SK_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              },
              "export": {
                "transferType": "SK_EXPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 0,
                "ttc": 153,
                "trmSystem": 0,
                "trmAllocation": 0,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              }
            }
          ]
        },
        "BcMatlFlowgate": {
          "links": [],
          "Allocations": [
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "1",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 843,
                "trmTotal": 267,
                "ttc": 1110,
                "trmSystem": 267,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-01 23:07:50 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "2",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 817,
                "trmTotal": 293,
                "ttc": 1110,
                "trmSystem": 293,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 00:07:50 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "3",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 784,
                "trmTotal": 326,
                "ttc": 1110,
                "trmSystem": 326,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 01:07:50 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "4",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 810,
                "trmTotal": 300,
                "ttc": 1110,
                "trmSystem": 300,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 02:09:50 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "5",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 801,
                "trmTotal": 309,
                "ttc": 1110,
                "trmSystem": 309,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 03:07:50 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "6",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 801,
                "trmTotal": 309,
                "ttc": 1110,
                "trmSystem": 309,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 04:05:40 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "7",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 804,
                "trmTotal": 306,
                "ttc": 1110,
                "trmSystem": 306,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 05:07:50 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "8",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 803,
                "trmTotal": 307,
                "ttc": 1110,
                "trmSystem": 307,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 06:05:40 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "9",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 833,
                "trmTotal": 277,
                "ttc": 1110,
                "trmSystem": 277,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 07:05:40 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "10",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 801,
                "trmTotal": 309,
                "ttc": 1110,
                "trmSystem": 309,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 08:05:40 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "11",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 794,
                "trmTotal": 316,
                "ttc": 1110,
                "trmSystem": 316,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 09:09:50 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "12",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 822,
                "trmTotal": 288,
                "ttc": 1110,
                "trmSystem": 288,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 10:07:50 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "13",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 821,
                "trmTotal": 289,
                "ttc": 1110,
                "trmSystem": 289,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 11:09:50 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "14",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 823,
                "trmTotal": 287,
                "ttc": 1110,
                "trmSystem": 287,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 12:07:50 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "15",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 824,
                "trmTotal": 286,
                "ttc": 1110,
                "trmSystem": 286,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 13:09:50 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "16",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 823,
                "trmTotal": 287,
                "ttc": 1110,
                "trmSystem": 287,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 14:07:50 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "17",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 822,
                "trmTotal": 288,
                "ttc": 1110,
                "trmSystem": 288,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 15:05:40 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "18",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 833,
                "trmTotal": 277,
                "ttc": 1110,
                "trmSystem": 277,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 16:07:50 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "19",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 834,
                "trmTotal": 276,
                "ttc": 1110,
                "trmSystem": 276,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 17:05:40 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "20",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "Bala Testing Version 1 for HE 08.\nBala Testing Version 2 for HE 08 for BCMATL Import.",
                "atc": 811,
                "trmTotal": 299,
                "ttc": 1110,
                "trmSystem": 299,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "ISO rules Section 303.1 Limit",
                    "atc": 801,
                    "trmTotal": 309,
                    "ttc": 1110,
                    "grossOffer": 0
                  },
                  {
                    "reason": "Bala Testing Version 1 for HE 08.",
                    "atc": 810,
                    "trmTotal": 300,
                    "ttc": 1110,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 20:29:11 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "21",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 800,
                "trmTotal": 310,
                "ttc": 1110,
                "trmSystem": 310,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "ISO rules Section 303.1 Limit",
                    "atc": 800,
                    "trmTotal": 310,
                    "ttc": 1110,
                    "grossOffer": 0
                  },
                  {
                    "reason": "ISO rules Section 303.1 Limit",
                    "atc": 775,
                    "trmTotal": 335,
                    "ttc": 1110,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 20:29:11 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "22",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 788,
                "trmTotal": 322,
                "ttc": 1110,
                "trmSystem": 322,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 20:29:11 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "23",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "1201L outage",
                "atc": 0,
                "trmTotal": 65,
                "ttc": 65,
                "trmSystem": 65,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "ISO rules Section 303.1 Limit",
                    "atc": 797,
                    "trmTotal": 313,
                    "ttc": 1110,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "1201L outage",
                "atc": 40,
                "trmTotal": 65,
                "ttc": 105,
                "trmSystem": 65,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 935,
                    "trmTotal": 65,
                    "ttc": 1000,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "24",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 782,
                "trmTotal": 328,
                "ttc": 1110,
                "trmSystem": 328,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 22:07:50 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "1",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 782,
                "trmTotal": 328,
                "ttc": 1110,
                "trmSystem": 328,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-02 23:07:50 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "2",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 782,
                "trmTotal": 328,
                "ttc": 1110,
                "trmSystem": 328,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 00:05:40 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "3",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 778,
                "trmTotal": 332,
                "ttc": 1110,
                "trmSystem": 332,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 01:05:40 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "4",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 779,
                "trmTotal": 331,
                "ttc": 1110,
                "trmSystem": 331,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 02:05:40 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "5",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 780,
                "trmTotal": 330,
                "ttc": 1110,
                "trmSystem": 330,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 03:07:50 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "6",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 784,
                "trmTotal": 326,
                "ttc": 1110,
                "trmSystem": 326,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 04:07:50 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "7",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 760,
                "trmTotal": 350,
                "ttc": 1110,
                "trmSystem": 350,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 05:05:40 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "8",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 772,
                "trmTotal": 338,
                "ttc": 1110,
                "trmSystem": 338,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 06:05:40 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "9",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 813,
                "trmTotal": 297,
                "ttc": 1110,
                "trmSystem": 297,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 07:07:51 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "10",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 815,
                "trmTotal": 295,
                "ttc": 1110,
                "trmSystem": 295,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 08:05:40 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "11",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 816,
                "trmTotal": 294,
                "ttc": 1110,
                "trmSystem": 294,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 09:07:50 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "12",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 815,
                "trmTotal": 295,
                "ttc": 1110,
                "trmSystem": 295,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 10:09:50 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "13",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 809,
                "trmTotal": 301,
                "ttc": 1110,
                "trmSystem": 301,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 11:07:50 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "14",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 808,
                "trmTotal": 302,
                "ttc": 1110,
                "trmSystem": 302,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 12:07:50 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "15",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 833,
                "trmTotal": 277,
                "ttc": 1110,
                "trmSystem": 277,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:07:50 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "16",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 834,
                "trmTotal": 276,
                "ttc": 1110,
                "trmSystem": 276,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:23:40 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "17",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 846,
                "trmTotal": 264,
                "ttc": 1110,
                "trmSystem": 264,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:29:40 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "18",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 846,
                "trmTotal": 264,
                "ttc": 1110,
                "trmSystem": 264,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:23:40 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "19",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 867,
                "trmTotal": 243,
                "ttc": 1110,
                "trmSystem": 243,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:23:40 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "20",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 876,
                "trmTotal": 234,
                "ttc": 1110,
                "trmSystem": 234,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:23:40 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "21",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 876,
                "trmTotal": 234,
                "ttc": 1110,
                "trmSystem": 234,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:23:40 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "22",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 876,
                "trmTotal": 234,
                "ttc": 1110,
                "trmSystem": 234,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:23:40 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "23",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 867,
                "trmTotal": 243,
                "ttc": 1110,
                "trmSystem": 243,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:23:40 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "24",
              "import": {
                "transferType": "BC_MATL_IMPORT",
                "reason": "ISO rules Section 303.1 Limit",
                "atc": 876,
                "trmTotal": 234,
                "ttc": 1110,
                "trmSystem": 234,
                "grossOffer": 0,
                "updatedLocalTime": "2020-11-03 13:29:40 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              },
              "export": {
                "transferType": "BC_MATL_EXPORT",
                "reason": "",
                "atc": 935,
                "trmTotal": 65,
                "ttc": 1000,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              }
            }
          ]
        },
        "SystemlFlowgate": {
          "links": [],
          "Allocations": [
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "1",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "2",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "3",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "4",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "5",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "6",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "7",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "8",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "9",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "10",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "11",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "12",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "13",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "14",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "15",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "16",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "17",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "18",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "19",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "20",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "21",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "22",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "23",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 153,
                "trmTotal": 65,
                "ttc": 218,
                "trmSystem": 65,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 1198,
                    "trmTotal": 65,
                    "ttc": 1263,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 193,
                "trmTotal": 65,
                "ttc": 258,
                "trmSystem": 65,
                "grossOffer": 0,
                "version": [
                  {
                    "reason": "",
                    "atc": 1088,
                    "trmTotal": 65,
                    "ttc": 1153,
                    "grossOffer": 0
                  }
                ],
                "updatedLocalTime": "2020-11-02 22:35:24 IST",
                "effectiveLocalTime": "2020-11-02 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-02",
              "he": "24",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-02 23:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "1",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 00:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "2",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 01:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "3",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 02:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "4",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 03:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "5",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 04:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "6",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 05:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "7",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 06:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "8",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 07:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "9",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 08:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "10",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 09:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "11",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 10:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "12",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 11:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "13",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 12:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "14",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 13:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "15",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 14:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "16",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 15:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "17",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 16:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "18",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 17:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "19",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 18:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "20",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 19:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "21",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:20 IST",
                "effectiveLocalTime": "2020-11-03 20:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "22",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 21:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "23",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 22:30:00 IST"
              }
            },
            {
              "reasons": [],
              "flowgate": true,
              "date": "2020-11-03",
              "he": "24",
              "import": {
                "transferType": "SYSTEM_IMPORT",
                "reason": "",
                "atc": 1198,
                "trmTotal": 65,
                "ttc": 1263,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              },
              "export": {
                "transferType": "SYSTEM_EXPORT",
                "reason": "",
                "atc": 1088,
                "trmTotal": 65,
                "ttc": 1153,
                "trmSystem": 65,
                "grossOffer": 0,
                "updatedLocalTime": "2020-08-12 23:37:21 IST",
                "effectiveLocalTime": "2020-11-03 23:30:00 IST"
              }
            }
          ]
        }
      }
    }
}

retrieveData(fromDate,toDate,pageSize) {
    if(Configuration.IS_DUMMY_DATA)
    {
      var data = Promise.resolve(this.dummyData);
      return  data.then(function(value) {
           return value;
      });
    }
    else
    {
        var startDate=convertDateToApiFormat(fromDate);
        var endDate=convertDateToApiFormat(toDate);
        return get("interchange?startDate="+startDate+"&endDate="+endDate+"&pageNo=1&pageSize="+pageSize);
    }
  }
}

export default HistoricalReportService;