import * as React from 'react';
import '../../../assets/scss/components/SectionTitle.scss';


class SectionTitle extends React.PureComponent {
state={
    title:''
}

    componentDidMount= () =>
    {
        this.setState({
            title: this.props.title,
            styleClass: this.props.styleClass
        });
    }

    componentDidUpdate()
    {
        this.setState({
            title: this.props.title,
            styleClass: this.props.styleClass
        });
    }

    render(){
        return (
            <div className={this.state.styleClass}>
                <span className='atc-section-title'>{this.state.title}</span>
            </div>
        );
    }

}

SectionTitle.defaultProps = {
   title:'',
   styleClass:"atc-section"
}

export default SectionTitle;