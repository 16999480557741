import React from 'react';
import MainLayout from '../main/MainLayout';
import '../../assets/scss/app.scss';
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import configuration from '../../configuration';

export const history = createBrowserHistory();

class App extends React.Component {

    render() {
        console.log(configuration);
        return (
            <Router history={history}>
                <Switch>
                    <Route path={configuration.API_CONTEXT_ROOT + "/html/realtime/grid"} render={props => <MainLayout {...props} grid={true} selectedTab="0" />} exact />
                    <Route path={configuration.API_CONTEXT_ROOT +"/html/realtime/graph"} render={props => <MainLayout {...props} grid={false} selectedTab="0" />} exact />
                    <Route path={configuration.API_CONTEXT_ROOT +"/html/historic/"} render={props => <MainLayout {...props} selectedTab="1" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT +"/html/forecast/"} render={props => <MainLayout {...props} selectedTab="2" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT +"/html/realtime/"} render={props => <MainLayout {...props} grid={false} selectedTab="0" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT + "/html"} render={props => <MainLayout {...props} grid={false} selectedTab="0" />} exact />
                    <Route path={configuration.API_CONTEXT_ROOT +"/atc/historic/"} render={props => <MainLayout {...props} selectedTab="1" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT +"/atc/forecast/"} render={props => <MainLayout {...props} selectedTab="2" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT +"/atc/realtime/grid/"} render={props => <MainLayout {...props} grid={true} selectedTab="0" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT +"/atc/realtime/graph/"} render={props => <MainLayout {...props} grid={false} selectedTab="0" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT +"/atc/realtime/"} render={props => <MainLayout {...props} grid={false} selectedTab="0" />} exact/>
                    <Route path={configuration.API_CONTEXT_ROOT +"/atc"} render={props => <MainLayout {...props} grid={false} selectedTab="0" />} exact />
                    <Route render={props => <MainLayout {...props} selectedTab="3" />}  />
                </Switch>
            </Router>
        );
    }
}

export default App;