import React from 'react';
import parse from 'html-react-parser';
import * as expMsg from '../../constants/ExceptionMessages';
import HistoricalReportService from '../../apis/HistoricalReportService';
import configuration from '../../configuration';
import { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxlistbox';
import { groupBy,getDatesDifference, convertApiObjectFormat, compareDate, getGridDefaultSource, getGridColumnGroups, getGridColumns, getGridDataFeilds,validateHourRange,convertToCSVData,downloadCSV,createCSVRows,handleItcApiError  } from '../../utilities/HelperMethods';
import Grid from '../../components/common/grid/Grid';
import JqxDateTimeInput from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdatetimeinput';
import * as constants from '../../constants/Constants';
import moment from 'moment';
import timezone from 'moment-timezone';
import JqxMaskedInput from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxmaskedinput';
import Configuration from '../../configuration';
import AESOLoader from '../../components/common/loader/Loader';

class HistoricReport extends React.Component {
    constructor(props) {
        super(props);
        this.historicalReportService = new HistoricalReportService();
        this.fromDate = React.createRef();
        this.toDate = React.createRef();
        this.grid = React.createRef();
        this.hourEndingRange = React.createRef();
        this.collpaseButton = React.createRef();
        this.expandButton = React.createRef();
        this.downloadButton = React.createRef();

        this.state = {
            columngroups: getGridColumnGroups(),
            columns: getGridColumns(),
            source: {},
            groups: ['Date', 'Hour'],
            errorMesage: '',
            hourRange: '0124',
            hourRangeDifference: 1,
            dataLoaded: false,
            csvData : []        
        }


    }

    componentDidMount() {
        const data = getGridDefaultSource();
        this.setState({ source: data, });
        this.setOrUpdateBtnState(data);
        window.addEventListener('resize', this.handleResizeEvent);
	}

    handleKeyPress=(e)=>{
        alert('You pressed key!');
        if(e.keyCode === 13) {
            alert('You pressed enter!');
        }
    }

    handleResizeEvent=()=>{
        this.setState({ source: this.state.source });
        this.refs.childgrid.handleResize();
        this.refs.childgrid.expandFirstRow();
    }

    onSubmit = (event) =>{
        event.preventDefault();
        this.retrieveData();
    }

    retrieveData = () => {
        var hourRangeVar = "";
        if(this.hourEndingRange.current.val() === "  -  "){
            hourRangeVar = "01-24";
        }else{
            hourRangeVar = this.hourEndingRange.current.val();
        }
        var fromDate = this.fromDate.current.val();
        var toDate = this.toDate.current.val();
        var daysDifference = getDatesDifference(fromDate,toDate);
        var errorMessage = this.validateDate(fromDate, toDate,daysDifference+1);
        errorMessage+=validateHourRange(hourRangeVar);
        this.setState({
            errorMesage: errorMessage,
            hourRange: hourRangeVar !== undefined?this.replaceString('-','',hourRangeVar):"",
            source: getGridDefaultSource(),
            dataLoaded: false,
            csvData:[]
        }, () => {
            if (errorMessage === '') {
                this.getGridItems(fromDate, toDate,daysDifference+1);
            } else{
                this.resetButtons();
            }
        })
    }

    resetButtons()
    {
        this.downloadButton.current.classList.add("disable");
        this.expandButton.current.classList.add("disable");
        this.collpaseButton.current.classList.add("disable");
        // this.expandButton.current.style.display = "none";
        // this.collpaseButton.current.style.display = "";
    }

    replaceString(oldS, newS, fullS) {
        return fullS.split(oldS).join(newS)
    }

    validateDate = (fromDate, toDate,daysDifference) => {
        var errorMessage = "<ul class='validation-message-section'>";
        var isError = false;
        var today = new Date();
        var todayDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

        //From date is mandatory
        if (fromDate === '') {
            isError = true;
            errorMessage += "<li>" + expMsg.From_Date_Required + '</li>';
        }
        //check from date is smaller than to date
        if (compareDate(fromDate, toDate) === 1) {
            isError = true;
            errorMessage += "<li>" + expMsg.From_Date_Before_To_Date + '</li>';
        }

        //check From date must be today or before
        if (compareDate(fromDate, todayDate) === 1) {
            isError = true;
            errorMessage += "<li>" + expMsg.From_Date_Today_Or_Before_Date + '</li>';
        }

        //To date is mandatory
        if (toDate === '') {
            isError = true;
            errorMessage += "<li>" + expMsg.To_Date_Required + '</li>';
        }
        //check To date must be today or before
        if (compareDate(toDate, todayDate) === 1) {
            isError = true;
            errorMessage += "<li>" + expMsg.To_Date_Today_Or_Before_Date + '</li>';
        }

        // check From Date and To date range difference
        if(daysDifference>configuration.HISTORIC_REPORT_MAX_DAYS)
        {
            isError = true;
            errorMessage += "<li>" + expMsg.Date_Range_Too_Large.replace("{0}",daysDifference ).replace("{1}",configuration.HISTORIC_REPORT_MAX_DAYS ) + '</li>';
        }
        errorMessage += "</ul>"
        return isError ? errorMessage : '';
    }

    setOrUpdateBtnState(data) {
        if (data !== null && data !== undefined && data.localdata !== undefined && data.localdata !== null && data.localdata.length > 0) {
            this.downloadButton.current.classList.remove("disable");
            this.expandButton.current.classList.remove("disable");
            this.collpaseButton.current.classList.remove("disable");
        }
    }

    getGridItems(fromDate, toDate,pageSize) {
        AESOLoader.singletonRef.show();
        this.historicalReportService.retrieveData(fromDate, toDate,pageSize)
            .then(items => {
                var responseData = convertApiObjectFormat(items);
                var data = {}
                if (responseData.length > 0) {
                    var converteddata = this.filterResponseData(responseData);
                    var groups = groupBy(converteddata, 'date');
                    var result = Object.entries(groups);
                    if (result.length === 0) {
                        result.add({ '0': "No Records" })
                    }
                    data = {
                        dataFields: getGridDataFeilds(),
                        datatype: 'json',
                        localdata: result
                    }

                } else {
                    data = {
                        datatype: 'json',
                        localdata: []
                    }
                }
                this.setState({
                    source: data,
                    hourRangeDifference: 3,
                    dataLoaded: true,
                    csvData:converteddata
                });
                //Expand first row bydefault
                this.refs.childgrid.goToPage(0);
                this.refs.childgrid.expandFirstRow();
                this.refs.childgrid.handleResize();
                //this.expandButton.current.style.display = "none";
                //this.collpaseButton.current.style.display = "";
                AESOLoader.singletonRef.hide();
            })
            .catch(e => {
                this.setState({errorMesage: handleItcApiError(e)});
                this.resetButtons();
                AESOLoader.singletonRef.hide();
            });
    }

    filterResponseData = (data) => {
        var hourRage = this.hourEndingRange.current.val();
        if (hourRage === '' || hourRage === undefined) {
            return data;
        }
        else {
            var hours = hourRage.split("-");
            var startHour = hours[0].trim();
            var endHour = hours[1].trim();
            var filterData = data.filter(function (el) {
                return parseInt(el.hourEnding.replace('*','')) >= startHour &&
                    parseInt(el.hourEnding.replace('*','')) <= endHour;
            });

          // check if to date is today date - then get only data till current hour
         
          if(this.IsToDateToday())
          {
            return this.filterCurrentHourData(filterData)
          }
          else
          {
            return filterData;
          }
        }
    }

    IsToDateToday()
    {
         var currentDate= moment().tz("Canada/Mountain").format("YYYY-MM-DD");
         var toDate= this.toDate.current.val();
         if(compareDate(currentDate,toDate)===0)
         {
             return true;
         }
         else{
          return false;
         }
    }

    filterCurrentHourData=(data)=>
    {
        var currentDate= this.toDate.current.val();
        var currentHourEnding = this.getCurrentHourEnding();
        var filteredData=[];
        data.map((item, index) => {
             if(item.date===currentDate && item.hourEnding>currentHourEnding)
             {
                //do not include
             }
             else
             {
                 filteredData.push(item);
             }
        });
        return filteredData;
    }

    getCurrentHourEnding()
    {
        var today = new Date();
        //var todayDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);
        var hour = today.getHours();
        return hour;
    }

    // handleHourRangeChange = (e) => {
    //     const re = /(^$)|(^[0-9-\b]+$)/;
    //     if (e.target.value.search(re) === -1) { }
    //     else { this.setState({ hourRange: e.target.value }) }
    // }


    collapseRows = (e) => {
        if (this.state.dataLoaded) {
            this.refs.childgrid.hideRowDetails();
            this.collpaseButton.current.style.display = "none";
            this.expandButton.current.style.display = "";
        }
    }

    onRowShowhide=(expandAll)=>{
        if(expandAll){
            this.expandButton.current.style.display="none";
            this.collpaseButton.current.style.display="";
        }else{
            this.collpaseButton.current.style.display="none";
            this.expandButton.current.style.display="";
        }
    }

    expandRows = (e) => {
        this.refs.childgrid.showRowDetails();
        this.expandButton.current.style.display = "none";
        this.collpaseButton.current.style.display = "";
        this.refs.childgrid.isAllExpanded();
    }

    downloadCSV=()=>{
         var data= this.state.csvData;
         if(data!==undefined && data!==null && data.length>0)
         {
            var csvData=convertToCSVData(data) ;
            var reportTime=moment().tz("Canada/Mountain").format("YYYY-MM-DD HH:mm:ss");
            var title="Historical Intertie Capability Report As Of: "+reportTime+" MDT";
            var csvRows=createCSVRows(csvData,title);
            var csvString = csvRows.join('\n');
            downloadCSV(csvString,constants.HistoricalReportFileName);
         }
    }

    onShow = (e) => {
        console.log("Called H");
    }

    gridCallback = () => {
        this.collpaseButton.current.style.display = "none";
            this.expandButton.current.style.display = "";
    }

    render() {
        this.setOrUpdateBtnState(this.state.source);
        return (
            <React.Fragment>
                <div className="container pl-0 pr-0 ml-0 mr-0 slide-in">
                    <form onSubmit={(event) => this.onSubmit(event)}>
                    <div className="row ml-0 mr-0">
                        <div className="col-md-6 ml-0 mr-0 mt-5 mb-2 pl-0 pr-0">
                            <div className="row">
                                <h6 className="col-md-12 pl-0 ml-0 label-color">{constants.en_messages.generic.common.input.msg1}</h6>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6 pl-0 ml-0">
                                    <span className="sub-title-text mt-2">{constants.en_messages.generic.common.input.from}{constants.en_messages.generic.common.input.requiredChar}</span>
                                    <span className="mt-2 float-left w-100">
                                        <JqxDateTimeInput
                                            ref={this.fromDate}
                                            width={'100%'}
                                            height={35}
                                            formatString="yyyy-MM-dd"
                                            theme="aeso-date"
                                            max={new Date()}
                                            min = {new Date(Configuration.HISTORIC_REPORT_MIN_DATE)}
                                        /></span>
                                </div>
                                <div className="col-md-6 pl-0 ml-0">
                                    <span className="sub-title-text">{constants.en_messages.generic.common.input.to}{constants.en_messages.generic.common.input.requiredChar}</span>
                                    <span className="mt-2 float-left w-100">
                                        <JqxDateTimeInput
                                            ref={this.toDate}
                                            width={'100%'}
                                            height={35}
                                            formatString="yyyy-MM-dd"
                                            theme="aeso-date"
                                            max={new Date()}
                                            min = {new Date(Configuration.HISTORIC_REPORT_MIN_DATE)}
                                        /></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ml-0 mr-0 mt-5 mb-2 pl-0 pr-0">
                            <div className="row">
                                <h6 className="col-md-12 pl-0 ml-0 label-color">{constants.en_messages.generic.common.input.msg2}</h6>
                            </div>
                            <div className="row  mt-2">
                                <div className="col-md-3 pl-0 ml-0">
                                    <span className="sub-title-text">&nbsp;</span>
                                    <span className="mt-2 float-left w-100">
                                        <JqxMaskedInput className="custom-hour-picker" ref={this.hourEndingRange}  style={{ marginTop: '3px' }} onChange={this.change}
                                                width={'100%'} height={32} promptChar={' '} mask={'##-##'}  value={this.state.hourRange}/>
                                        {/* <input className="custom-hour-picker"
                                            type="text"   /> */}
                                    </span>
                                </div>
                                <div className="col-md-6 pl-0 ml-0 mt-2">
                                    <button className='mt-3 ml-3 custom-button' onClick={(e) => this.retrieveData()}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                    <div className="row ml-0 mr-0 ">
                        <div className="col-md-6 ml-0 mr-0 pl-0 pr-0 errorMessage-text">
                            {parse(this.state.errorMesage)}
                        </div>
                        <div className="col-md-6 ml-0 mr-0 pl-0 pr-0 sub-description">
                            {constants.en_messages.generic.common.input.msg3}
                          </div>
                    </div>
                    <div className="row ml-0 mr-0 mt-3" style={{ borderTop: '0.5px solid rgba(102,102,102,0.6)' }}>
                        <div className="col-md-7 ml-0 mr-0 mt-4 pl-0 pr-0 float-left sub-description">
                            {constants.en_messages.generic.historic.view1}
                     </div>
                        <div className="col-md-5 ml-0 mr-0 mt-3 pl-0 pr-0 float-right">
                            <button ref={this.collpaseButton} className='float-right ml-4 seconday-button disable' onClick={(e) => this.collapseRows(e)}>
                                <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg3}</span>
                            </button>
                            <button ref={this.expandButton} style={{ display: 'none' }} className='float-right ml-4 seconday-button disable' onClick={(e) => this.expandRows(e)}>
                                <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg4}</span>
                            </button>
                            <button title="Apply and Download displayed data." ref={this.downloadButton} className='seconday-button float-right disable' onClick={()=>{this.downloadCSV()}}>
                                <span className="secondary-button-text">{constants.en_messages.generic.common.btn.msg2}</span>
                            </button>
                        </div>
                    </div>

                </div>
                <div className="mt-4">
                    <Grid
                        ref="childgrid"
                        theme="aeso-grid"
                        columngroups={this.state.columngroups}
                        columns={this.state.columns}
                        source={new jqx.dataAdapter(this.state.source)}
                        cellsrenderer={this.cellsrenderer}
                        groups={this.state.groups}
                        pageable={true}
                        width={'99.46%'}
                        rowdetails={true}
                        rowDetailItemHeight={175}
                        onRowShowhide={this.onRowShowhide}
                        height={null}
                        pageNumber={1}
                        gridCallback={this.gridCallback}
                    />

                </div>

            </React.Fragment>
        );
    }
}

export default HistoricReport;